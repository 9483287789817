import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, showNotification } from 'react-admin';
import SessionManagerFactory from '../../misc/session';
import InputIcon from '@material-ui/icons/Input';

const SessionManager = SessionManagerFactory();

class SwitchAccountButton extends Component {
  handleClick = event => {
    event.preventDefault();
    const { record, showNotification } = this.props;
    SessionManager.setAccount(record.id, record.name);
    showNotification(`Switched to account ${record.name}`);
  };

  render() {
    const { record, showNotification, ...rest } = this.props;
    if (record && record.id !== SessionManager.getAccount().id) {
      return (
        <Button label="Switch" onClick={this.handleClick} {...rest}>
          <InputIcon />
        </Button>
      );
    }
    return null;
  }
}

SwitchAccountButton.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(
  undefined,
  {
    showNotification,
  },
)(SwitchAccountButton);
