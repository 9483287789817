import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, refreshView, showNotification } from 'react-admin';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';
import { webApi } from '../../misc/api';

class ReloadReportingButtonComponent extends Component {
  handleClick = event => {
    event.preventDefault();
    const { record, showNotification, refreshView } = this.props;
    webApi
      .service()
      .request({
        url: `/service/reporting/${record.account}/pipeline/${record.id}/reload`,
        method: Commons.HttpMethod.POST,
      })
      .then(() => {
        refreshView();
      });
    showNotification(`Setting  ${record.name}`);
  };

  render() {
    const { record, showNotification, ...rest } = this.props;
    const currentStatus = record.status;
    if (currentStatus !== 'ENABLED') {
      return <Button label="Reload Data is not available" disabled />;
    }
    return <Button label="Reload Data" onClick={this.handleClick} {...rest} />;
  }
}

ReloadReportingButtonComponent.propTypes = {
  refreshView: PropTypes.func,
  showNotification: PropTypes.func,
};

export default connect(
  undefined,
  {
    refreshView,
    showNotification,
  },
)(ReloadReportingButtonComponent);
