import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  Link,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Resource,
  RichTextField,
  NumberInput,
  ShowView,
  TabbedForm,
  ShowController,
  SimpleForm,
  FormTab,
  ChipField,
  SingleFieldList,
  Tab,
  ReferenceArrayInput,
  SelectInput,
  Filter,
  SelectArrayInput,
  FormDataConsumer,
  ReferenceArrayField,
  ReferenceInput,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Build';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { LongPagePagination } from '../../components/LongPagePagination';
import DeleteButtonWithConfirmation from '../../components/buttons/DeleteWithConfirmation';
import { CustomDownloadButton } from '../../components/buttons';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'bobjectFields';
const resourceLabel = 'Bobject Field';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const CreateValueButton = ({ record }) => {
  const params = {
    bobjectField: record.id,
  };
  if (record.parentBobjectField) {
    params.parentBobjectField = record.parentBobjectField;
  }
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectPicklistFieldValues/create',
        state: { record: params },
      }}
    >
      <AddIcon /> Add Value
    </Button>
  );
};
const CreateConditionButton = ({ record }) => {
  const params = {
    bobjectField: record.id,
    bobjectType: record.bobjectType,
  };
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectConditionalFields/create',
        state: { record: params },
      }}
    >
      <AddIcon /> Create condition
    </Button>
  );
};

const CreateValueConditionButton = ({ record }) => {
  const params = {
    bobjectField: record.id,
    bobjectType: record.bobjectType,
    bobjectGlobalPicklist: record.bobjectGlobalPicklist,
  };
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectConditionalFieldValues/create',
        state: { record: params },
      }}
    >
      <AddIcon /> Create condition
    </Button>
  );
};

const ListFilters = props => (
  <Filter {...props}>
    <ReferenceInput label="Object Type" source="bobjectType" reference="bobjectTypes" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Field Type" source="fieldType" reference="fieldTypes" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Group"
      source="bobjectFieldGroup"
      reference="bobjectFieldGroups"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Global Picklist"
      source="bobjectGlobalPicklist"
      reference="bobjectGlobalPicklists"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput source="logicRole" />
    <BooleanInput source="enabled" />
    <BooleanField source="duplicateValidation" />
    <BooleanField source="templateVariable" />
    <BooleanInput source="required" />
    <BooleanInput source="layoutReadOnly" />
  </Filter>
);

const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List
    title={resourceTitle}
    empty={<EmptyPage />}
    {...props}
    filters={<ListFilters />}
    pagination={<LongPagePagination />}
    perPage={25}
  >
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField label="Object" source="bobjectType" link={false} reference="bobjectTypes">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Field Type" source="fieldType" link={false} reference="fieldTypes">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Group"
        source="bobjectFieldGroup"
        link={false}
        reference="bobjectFieldGroups"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Global Picklist"
        source="bobjectGlobalPicklist"
        link={false}
        reference="bobjectGlobalPicklists"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Controlling field"
        source="parentBobjectField"
        allowEmpty
        link={false}
        reference="bobjectFields"
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="upstreamMirror" />
      <BooleanField source="typeField" />
      <BooleanField source="enabled" />
      <BooleanField source="duplicateValidation" />
      <BooleanField source="segmentation" />
      <BooleanField source="prioritisation" />
      <BooleanField source="characterisation" />
      <BooleanField source="required" />
      <BooleanField source="layoutReadOnly" />
      <BooleanField source="inboundField" />
      <BooleanField source="qualifyingQuestion" />
      <NumberField source="ordering" />
      <TextField source="logicRole" />
      <TextField source="defaultValue" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <ShowController {...props}>
    {controllerProps => {
      const isPicklist =
        controllerProps.record && controllerProps.record.fieldType === '8ad73c4db01b0886';
      const isGlobalPicklist =
        controllerProps.record && controllerProps.record.fieldType === '729e9be738deee98';
      const isText =
        controllerProps.record && controllerProps.record.fieldType === 'd9ab78650c2d0531';
      const isReference =
        controllerProps.record && controllerProps.record.fieldType === '38e941159889a150';
      const isQualifyingQuestion =
        controllerProps.record && controllerProps.record.qualifyingQuestion;
      return (
        <ShowView
          {...props}
          {...controllerProps}
          title={resourceTitle}
          actions={<PostShowActions resourceLabel={resourceLabel} />}
        >
          <TabbedShowLayout>
            <Tab label="summary">
              <ReferenceField
                label="Object"
                source="bobjectType"
                link={false}
                reference="bobjectTypes"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="name" />
              {props.permissions != null && props.permissions.isGlobalAdmin() && (
                <TextField source="reportingName" />
              )}
              <ReferenceField
                label="FieldType"
                source="fieldType"
                link={false}
                reference="fieldTypes"
              >
                <TextField source="name" />
              </ReferenceField>
              {isReference && (
                <ReferenceField
                  label="Referenced Bobject Type"
                  source="referencedBobjectType"
                  link={false}
                  reference="bobjectTypes"
                >
                  <TextField source="name" />
                </ReferenceField>
              )}
              {isGlobalPicklist && (
                <ReferenceField
                  label="Global Picklist"
                  source="bobjectGlobalPicklist"
                  reference="bobjectGlobalPicklists"
                  link="show"
                >
                  <TextField source="name" />
                </ReferenceField>
              )}
              {controllerProps.record && controllerProps.record.parentBobjectField && (
                <ReferenceField
                  label="Controlling field"
                  source="parentBobjectField"
                  allowEmpty
                  link="show"
                  reference="bobjectFields"
                >
                  <TextField source="name" />
                </ReferenceField>
              )}
              <RichTextField source="description" />
              <BooleanField source="enabled" />
              <BooleanField source="segmentation" />
              <BooleanField source="prioritisation" />
              <BooleanField source="characterisation" />
              <BooleanField source="qualifyingQuestion" />
              <BooleanField source="required" />
              <BooleanField source="duplicateValidation" />
              <BooleanField source="templateVariable" />
              <BooleanField source="typeField" />
              <BooleanField source="layoutReadOnly" />
              <BooleanField source="upstreamMirror" />
              <TextField source="logicRole" />
              <TextField source="searchType" />
              {isPicklist || isGlobalPicklist ? (
                <ReferenceField
                  label="Default Picklist Value"
                  source="defaultBobjectPicklistFieldValue"
                  reference="bobjectPicklistFieldValues"
                  allowEmpty
                  link="show"
                >
                  <TextField source="value" />
                </ReferenceField>
              ) : (
                <TextField source="defaultValue" />
              )}
              {isQualifyingQuestion && (
                <ReferenceArrayField source="targetMarkets" reference="targetMarkets">
                  <SingleFieldList linkType="show">
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              )}
              {isQualifyingQuestion && (
                <ReferenceArrayField
                  source="idealCustomerProfiles"
                  reference="idealCustomerProfiles"
                >
                  <SingleFieldList linkType="show">
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              )}
              {isQualifyingQuestion && (
                <ReferenceArrayField source="useCases" reference="useCases">
                  <SingleFieldList linkType="show">
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              )}
              <BooleanField source="reportingEnabled" />
              <TextField source="creationDatetime" />
              {props.permissions != null && props.permissions.isGlobalAdmin() && (
                <TextField source="id" />
              )}
            </Tab>
            {isPicklist && (
              <Tab label="values" path="values">
                <CreateValueButton />
                {controllerProps.record && (
                  <CustomDownloadButton
                    maxResults={500}
                    filter={{ 'bobjectField.id': controllerProps.record.id }}
                    overrideResource="bobjectPicklistFieldValues"
                    sort={{ field: 'ordering', order: 'DESC' }}
                  />
                )}
                <ReferenceManyField
                  perPage={500}
                  reference="bobjectPicklistFieldValues"
                  target="bobjectField.id"
                  sort={{ field: 'ordering', order: 'DESC' }}
                  addLabel={false}
                >
                  <CustomizableDatagrid rowClick="show">
                    <TextField source="value" />
                    <BooleanField source="enabled" />
                    <NumberField source="score" />
                    <NumberField source="ordering" />
                    <TextField source="logicRole" />
                    <TextField source="textColor" />
                    <TextField source="backgroundColor" />
                    <TextField source="outlineColor" />
                  </CustomizableDatagrid>
                </ReferenceManyField>
              </Tab>
            )}
            <Tab label="Field Conditions" path="conditions">
              <Typography>
                These conditions limit the visibility of the field in forms. Whenever one of these
                picklist values are selected in a form, then this field shall be displayed. If there
                are no conditions, then the field is always displayed.
              </Typography>
              <CreateConditionButton />
              <ReferenceManyField
                perPage={500}
                reference="bobjectConditionalFields"
                target="bobjectField.id"
                addLabel={false}
              >
                <Datagrid rowClick={false}>
                  <ReferenceField
                    label="Required type"
                    source="requiredType"
                    link={false}
                    reference="bobjectPicklistFieldValues"
                  >
                    <TextField source="valueWithField" />
                  </ReferenceField>
                  <DeleteButtonWithConfirmation redirect={false} />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
            {(isPicklist || isGlobalPicklist) && (
              <Tab label="Field Value Conditions" path="valueConditions">
                <Typography>
                  <h1>IS CONDITIONED BY</h1>
                  These conditions limit the visibility of the values of this field in forms.
                </Typography>
                <CreateValueConditionButton />
                <ReferenceManyField
                  perPage={500}
                  reference="bobjectConditionalFieldValues"
                  target="bobjectField.id"
                  addLabel={false}
                >
                  <Datagrid rowClick={false}>
                    <ReferenceField
                      label="Field Value"
                      source="bobjectPicklistFieldValue"
                      link={false}
                      reference="bobjectPicklistFieldValues"
                    >
                      <TextField source="value" />
                    </ReferenceField>
                    <ReferenceField
                      label="Required Field"
                      source="requiredBobjectField"
                      linkType={false}
                      reference="bobjectFields"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                      label="Required Field Value"
                      source="requiredBobjectPicklistFieldValue"
                      linkType={false}
                      reference="bobjectPicklistFieldValues"
                    >
                      <TextField source="value" />
                    </ReferenceField>
                    <DeleteButtonWithConfirmation redirect={false} />
                  </Datagrid>
                </ReferenceManyField>
                <Typography style={{ marginTop: '32px' }}>
                  <h1>FIELDS CONDITIONED</h1>
                  This list shows all fields that are conditioned by{' '}
                  <b>{controllerProps.record.name}</b>.
                </Typography>
                <ReferenceManyField
                  perPage={500}
                  reference="bobjectConditionalFieldValues"
                  target="requiredBobjectField.id"
                  addLabel={false}
                >
                  <Datagrid rowClick={false}>
                    <ReferenceField
                      label="Field"
                      source="bobjectField"
                      linkType={false}
                      reference="bobjectFields"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                      label="Field Value"
                      source="bobjectPicklistFieldValue"
                      linkType={false}
                      reference="bobjectPicklistFieldValues"
                    >
                      <TextField source="value" />
                    </ReferenceField>
                    <ReferenceField
                      label="Required Field"
                      source="requiredBobjectField"
                      linkType={false}
                      reference="bobjectFields"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                      label="Required Field Value"
                      source="requiredBobjectPicklistFieldValue"
                      linkType={false}
                      reference="bobjectPicklistFieldValues"
                    >
                      <TextField source="value" />
                    </ReferenceField>
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
            )}
            <Tab label="Style">
              <NumberField source="ordering" />
              <ReferenceField
                label="Field Group"
                source="bobjectFieldGroup"
                linkType={false}
                reference="bobjectFieldGroups"
                allowEmpty
              >
                <TextField source="name" />
              </ReferenceField>
              {isPicklist && <TextField source="layoutFormPicklistType" allowEmpty />}
              <BooleanField source="tableLayoutDefault" />
              <BooleanField source="inboundField" />
              <BooleanInput source="infoCardShow" />
              <NumberField source="infoCardOrder" />
              <TextField source="layoutFormColumns" allowEmpty />
              <TextField source="layoutFormWidth" allowEmpty />
              <TextField source="layoutIcon" allowEmpty />
              {isText && <BooleanField source="layoutMultiline" />}
              {isText && <NumberField source="layoutMultilineLines" />}
              {isText && <NumberField source="layoutMultilineMaxLines" />}
              <TextField source="layoutDateFormatType" allowEmpty />
              <TextField source="layoutDateFormatAbsolute" allowEmpty />
              <TextField source="layoutNumberFormat" allowEmpty />
              <TextField source="layoutNumberPrefix" allowEmpty />
              <TextField source="layoutNumberSuffix" allowEmpty />
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      );
    }}
  </ShowController>
);

const createValidate = values => {
  const errors = {};
  if (!values.bobjectType) {
    errors.bobjectType = ['It is required to provide an object type'];
  }
  if (!values.name) {
    errors.name = ['Name can not be empty'];
  }
  if (!values.fieldType) {
    errors.fieldType = ['It is required to provide a field type'];
  }
  return errors;
};

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show" validate={createValidate}>
      <ReferenceInput
        required
        label="Object Type"
        source="bobjectType"
        reference="bobjectTypes"
        allowEmpty={false}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" required />
      <ReferenceInput
        label="Field Type"
        source="fieldType"
        reference="fieldTypes"
        required
        allowEmpty={false}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <ReferenceInput
        label="Controlling field"
        source="parentBobjectField"
        reference="bobjectFields"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="enabled" />
      <BooleanInput source="qualifyingQuestion" />
      <BooleanInput source="segmentation" />
      <BooleanInput source="prioritisation" />
      <BooleanInput source="characterisation" />
      <BooleanInput source="required" />
      <BooleanInput source="duplicateValidation" />
      <BooleanInput source="templateVariable" />
      <BooleanInput source="typeField" />
      <BooleanInput source="tableLayoutDefault" />
      <ReferenceInput
        label="Global Picklist"
        source="bobjectGlobalPicklist"
        reference="bobjectGlobalPicklists"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput
        perPage={500}
        defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
        label="Target Markets"
        source="targetMarkets"
        reference="targetMarkets"
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        perPage={500}
        defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
        label="Ideal Customer Profiles"
        source="idealCustomerProfiles"
        reference="idealCustomerProfiles"
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        perPage={500}
        defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
        label="Use Cases"
        source="useCases"
        reference="useCases"
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedForm redirect="show">
      <FormTab label="summary">
        <TextInput source="name" required />
        <ReferenceField
          label="Controlling field"
          source="parentBobjectField"
          allowEmpty
          linkType={false}
          reference="bobjectFields"
        >
          <TextField source="name" />
        </ReferenceField>
        <FormDataConsumer>
          {({ formData }) =>
            formData.fieldType === '8ad73c4db01b0886' ? (
              <ReferenceInput
                label="Default Picklist Value"
                source="defaultBobjectPicklistFieldValue"
                reference="bobjectPicklistFieldValues"
                filter={{ bobjectField: formData.id }}
                allowEmpty
              >
                <SelectInput optionText="value" />
              </ReferenceInput>
            ) : (
              <TextInput source="defaultValue" />
            )
          }
        </FormDataConsumer>
        <RichTextInput source="description" toolbar={toolbarConfig} />
        <BooleanInput source="enabled" />
        <BooleanInput source="infoCardShow" />
        <NumberInput source="infoCardOrder" />
        <BooleanInput source="qualifyingQuestion" />
        <BooleanInput source="segmentation" />
        <BooleanInput source="prioritisation" />
        <BooleanField source="duplicateValidation" />
        <BooleanInput source="templateVariable" />
        <BooleanInput source="characterisation" />
        <BooleanInput source="required" />
        <BooleanInput source="typeField" />
        <BooleanInput source="layoutReadOnly" />
        <BooleanInput source="upstreamMirror" />
        <TextInput source="logicRole" />
        <SelectInput
          source="searchType"
          choices={[
            { id: null, name: '' },
            { id: 'EXACT_SEARCH', name: 'Exact Words' },
            { id: 'FUZZY_WORD', name: 'Fuzzy Words' },
            { id: 'AUTOCOMPLETE_SEARCH', name: 'Autocomplete Words' },
          ]}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.fieldType === '38e941159889a150' && (
              <ReferenceInput
                label="Referenced Object Type"
                source="referencedBobjectType"
                reference="bobjectTypes"
                allowEmpty={false}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.fieldType === '729e9be738deee98' && (
              <ReferenceInput
                label="Global Picklist"
                source="bobjectGlobalPicklist"
                reference="bobjectGlobalPicklists"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <ReferenceArrayInput
          perPage={500}
          defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
          label="Target Markets"
          source="targetMarkets"
          reference="targetMarkets"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          perPage={500}
          defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
          label="Ideal Customer Profiles"
          source="idealCustomerProfiles"
          reference="idealCustomerProfiles"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          perPage={500}
          defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
          label="Use Cases"
          source="useCases"
          reference="useCases"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <BooleanInput source="reportingEnabled" />
      </FormTab>
      <FormTab label="style">
        <NumberInput source="ordering" defaultValue={0} step={1} />
        <ReferenceInput
          label="Field Group"
          source="bobjectFieldGroup"
          reference="bobjectFieldGroups"
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          source="layoutFormPicklistType"
          choices={[
            { id: null, name: '' },
            { id: 'DROPDOWN', name: 'Dropdown' },
            { id: 'CHIP', name: 'Chip' },
          ]}
        />
        <BooleanInput source="tableLayoutDefault" />
        <BooleanInput source="inboundField" />
        <TextInput source="layoutFormColumns" allowEmpty />
        <TextInput source="layoutFormWidth" allowEmpty />
        <TextInput source="layoutIcon" allowEmpty />
        <TextInput source="layoutNumberPrefix" allowEmpty />
        <TextInput source="layoutNumberSuffix" allowEmpty />
        <TextInput source="layoutNumberFormat" allowEmpty />
        <BooleanInput source="layoutMultiline" />
        <NumberInput source="layoutMultilineLines" />
        <NumberInput source="layoutMultilineMaxLines" />

        <SelectInput
          source="layoutDateFormatType"
          choices={[
            { id: null, name: '' },
            { id: 'ABSOLUTE', name: 'Absolute' },
            { id: 'RELATIVE', name: 'Relative' },
          ]}
        />
        <TextInput source="layoutDateFormatAbsolute" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Customisation', hiddenInMenu: true }}
    icon={Icon}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
