import React from 'react';
import {
  Edit,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceField,
  Create,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Build';

const resourceName = 'integrations';
const resourceLabel = 'Integration';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const redirect = (basePath, id, data) => `/accounts/${data.account}/show/integration`;
const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="integrationName" />
      <ReferenceField source="account" reference="accounts" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="token" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="integrationName" required />
      <TextInput source="token" required />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleForm redirect={redirect}>
      <TextField source="integrationName" required />
      <TextInput source="token" required />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, hiddenInMenu: true }}
    icon={Icon}
    list={undefined}
    edit={edit}
    show={show}
    create={create}
  />
);

export default resource;
