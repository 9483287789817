import React from 'react';
import {
  SimpleShowLayout,
  Create,
  Edit,
  ReferenceField,
  Show,
  Resource,
  SimpleForm,
  SelectInput,
  Filter,
  ReferenceInput,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Code';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'restHooks';
const resourceLabel = 'REST Hook';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const ListFilters = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Object Type"
      source="bobjectType"
      reference="bobjectTypes"
      allowEmpty={false}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Event" source="event" />
  </Filter>
);
const list = props => (
  <List title={resourceTitle} {...props} empty={<EmptyPage />} filters={<ListFilters />}>
    <CustomizableDatagrid rowClick="show">
      <ReferenceField label="Object" source="bobjectType" link={false} reference="bobjectTypes">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="event" />
      <TextField source="url" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="event" />
      <ReferenceField label="Object" source="bobjectType" link={false} reference="bobjectTypes">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="url" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <SelectInput
        source="event"
        choices={[
          { id: 'CREATE', name: 'CREATE' },
          { id: 'UPDATE', name: 'UPDATE' },
          { id: 'DELETE', name: 'DELETE' },
        ]}
      />
      <ReferenceInput
        label="Object Type"
        source="bobjectType"
        reference="bobjectTypes"
        allowEmpty={false}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="url" required />
      <ReferenceInput label="User" source="user" reference="users" allowEmpty={false}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect="show">
      <SelectInput
        source="event"
        choices={[
          { id: 'CREATE', name: 'CREATE' },
          { id: 'UPDATE', name: 'UPDATE' },
          { id: 'DELETE', name: 'DELETE' },
        ]}
      />
      <ReferenceInput
        label="Object Type"
        source="bobjectType"
        reference="bobjectTypes"
        allowEmpty={false}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="url" required />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Customisation',
      isOnlyVisibleFor: ['GLOBAL_DEV', 'ACCOUNT_DEV'],
    }}
    icon={Icon}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
