import React, { useState, useEffect } from 'react';
import { getOne } from '../providers/DataProvider';

const PicklistValueTitle = ({ isEdit = false, record, style }) => {
  const [data, setData] = useState({ label: '', picklistName: '' });
  useEffect(() => {
    let resource = null;
    let id = null;
    let label = null;
    if (record.bobjectGlobalPicklist !== null && record.bobjectGlobalPicklist !== undefined) {
      resource = 'bobjectGlobalPicklists';
      id = record.bobjectGlobalPicklist;
      label = 'GlobalPicklist: ';
    } else {
      resource = 'bobjectFields';
      id = record.bobjectField;
      label = 'Field: ';
    }
    getOne(resource, id).then(response => {
      if (resource === 'bobjectFields') {
        getOne('bobjectTypes', response.bobjectType).then(r => {
          label = `${r.name} > `;
          setData({ picklistName: response.name, label });
        });
      } else {
        setData({ picklistName: response.name, label });
      }
    });
  }, []);
  let content = '';
  if (isEdit) {
    content += 'Edit ';
  }
  content += `${data.label}${data.picklistName} > `;
  content += record.value;

  return <p style={style}>{content}</p>;
};

export default PicklistValueTitle;
