import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, refreshView, showNotification } from 'react-admin';
import { webApi } from '../../misc/api';
import { ButtonWithConfirmation } from './ButtonWithConfirmation';
import { InstallDashboardsButton } from './InstallDashboardsButton';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';

class UninstallDashboardsButton extends Component {
  handleClick = record => {
    const { showNotification, refreshView } = this.props;
    webApi
      .service()
      .request({
        method: Commons.HttpMethod.POST,
        url: `/service/accounts/${record.id}/deleteDashboards`,
      })
      .then(() => {
        refreshView();
      });
    showNotification(`Uninstalling dashboards for  ${record.name}`);
  };

  render() {
    return (
      <ButtonWithConfirmation
        buttonText="Uninstall Dashboards"
        onAction={this.handleClick}
        {...this.props}
      />
    );
  }
}

const CloneDashboardsButton = props => {
  const { record } = props;
  const currentStatus = record.supersetAssetsStatus;
  if (currentStatus === 'INSTALLING') {
    return <Button label="Dashboards are being installed" disabled />;
  }
  if (currentStatus === 'UNINSTALLING') {
    return <Button label="Dashboards are being removed" disabled />;
  }
  if (currentStatus !== 'INSTALLED') {
    return <InstallDashboardsButton {...props} />;
  }
  return <UninstallDashboardsButton {...props} />;
};

CloneDashboardsButton.propTypes = {
  record: PropTypes.object,
  refreshView: PropTypes.func,
  showNotification: PropTypes.func,
};

export default connect(
  undefined,
  {
    refreshView,
    showNotification,
  },
)(CloneDashboardsButton);
