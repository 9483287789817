import LoggerFactory from '../misc/log';
import SessionManagerFactory from '../misc/session';
import { jwtApi } from '../misc/api';

const logger = LoggerFactory.create({
  name: 'AuthProvider',
  nameStyle: 'color: green;font-weight:bold;',
});
const SessionManager = SessionManagerFactory();

const authProvider = {
  login: params => {
    const opId = LoggerFactory.newTrace('op');
    logger.info('Requested auth operation LOGIN', { traceIds: [opId] });
    const reqId = LoggerFactory.newTrace('req');
    logger.info('Requested auth jwt token', { traceIds: [opId, reqId] });
    const loginRequest = useHash =>
      jwtApi.service().login(
        {
          email: params.username,
          password: params.password,
          claimerSystem: 'ADMIN_APP',
        },
        useHash,
      );
    return loginRequest(false).then(async data => {
      await SessionManager.setRootToken(data.token);
      const roleManager = SessionManager.getRoleManager();
      const success = roleManager.isGlobalAdmin();
      if (success) {
        return Promise.resolve();
      }
      return Promise.reject();
    });
  },

  logout: () => {
    const opId = LoggerFactory.newTrace('op');
    logger.info('Requested auth operation LOGOUT', { traceIds: [opId] });
    SessionManager.cleanSession();
    return Promise.resolve('/login');
  },

  checkError: () => {
    const opId = LoggerFactory.newTrace('op');
    logger.info('Requested auth operation CHECK ERROR', { traceIds: [opId] });
    return Promise.resolve('WORKS');
  },

  checkAuth: () => {
    const opId = LoggerFactory.newTrace('op');
    logger.info('Requested auth operation CHECK AUTH', { traceIds: [opId] });
    return SessionManager.hasToken() ? Promise.resolve() : Promise.reject();
  },

  getPermissions: () => {
    const opId = LoggerFactory.newTrace('op');
    logger.info('Requested auth operatio GET PERMISSIONS', { traceIds: [opId] });
    if (SessionManager.hasToken()) {
      return Promise.resolve(SessionManager.getRoleManager());
    }
    return Promise.reject();
  },
};

export default authProvider;
