import React from 'react';
import {
  AutocompleteArrayInput,
  Create,
  Datagrid,
  Edit,
  ReferenceInput,
  ReferenceManyField,
  Filter,
  ReferenceArrayInput,
  ReferenceField,
  Resource,
  Show,
  SelectInput,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  NumberInput,
  Link,
  NumberField,
  TextField,
  TextInput,
  FunctionField,
  List,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Web';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import SelectedFilterValues from '../../components/SelectedFilterValues';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'bobjectViews';
const resourceLabel = 'View';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);
const CreateColumnButton = ({ record }) => {
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectViewColumns/create',
        state: { record: { bobjectView: record.id } },
      }}
    >
      <AddIcon /> Add Column
    </Button>
  );
};

const TypeInput = props => (
  <SelectInput
    source="type"
    choices={[
      { id: 'MQL', name: 'MQL' },
      { id: 'SAL', name: 'SAL' },
      { id: 'MEETINGS', name: 'Meetings' },
      { id: 'LEAD_WITHOUT_QC', name: 'Leads Without QC' },
      { id: 'TASK', name: 'Task' },
      { id: 'ACTIVITY', name: 'Activity' },
      { id: 'COMPANY', name: 'Company' },
      { id: 'LEAD', name: 'Lead' },
    ]}
    {...props}
  />
);
const ListFilters = props => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <TextInput source="type" alwaysOn />
    <TypeInput />
  </Filter>
);
const CreateFilterButton = ({ record }) => {
  const params = {
    bobjectView: record.id,
  };
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectViewFilters/create/',
        state: { record: params },
      }}
    >
      <AddIcon /> Add Filter
    </Button>
  );
};
const list = props => (
  <List
    title={resourceTitle}
    filters={<ListFilters />}
    empty={<EmptyPage />}
    perPage={25}
    {...props}
  >
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <NumberField source="ordering" />
      <TextField source="type" />
      <TextField source="visibility" />
      <ReferenceField source="user" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <NumberInput source="ordering" />
      <SelectInput
        source="visibility"
        choices={[{ id: 'PUBLIC', name: 'Public' }, { id: 'PRIVATE', name: 'Private' }]}
        allowEmpty={false}
      />
      <TypeInput allowEmpty={false} />
      <ReferenceInput source="user" reference="users" allowEmpty={false}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} permissions={props.permissions} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <NumberField source="ordering" />
        <TextField source="type" />
        <TextField source="visibility" />
        <ReferenceField source="user" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceArrayField label="Tags" source="tags" reference="tags">
          <SingleFieldList linkType="show">
            <ChipField source="value" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="creationDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
      <Tab label="Filters" path="filters">
        <CreateFilterButton />
        <ReferenceManyField
          perPage={500}
          reference="bobjectViewFilters"
          target="bobjectView.id"
          sort={{ field: 'id', order: 'DESC' }}
          addLabel={false}
          link="show"
        >
          <Datagrid rowClick="show">
            <ReferenceField source="bobjectField" reference="bobjectFields" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              label={'Picklist Values'}
              render={record => <SelectedFilterValues record={record} />}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Columns" path="columns">
        <CreateColumnButton />
        <ReferenceManyField
          perPage={500}
          reference="bobjectViewColumns"
          target="bobjectView.id"
          sort={{ field: 'ordering', order: 'ASC' }}
          addLabel={false}
        >
          <Datagrid rowClick="show">
            <ReferenceField source="bobjectField" reference="bobjectFields" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <NumberField source="ordering" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <NumberInput source="ordering" />
      <ReferenceArrayInput
        perPage={500}
        defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
        label="Tags"
        source="tags"
        reference="tags"
      >
        <AutocompleteArrayInput
          translateChoice={false}
          source="tags"
          optionValue="id"
          optionText="value"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Business Assets' }}
    list={list}
    icon={Icon}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
