import React from 'react';
import {
  AutocompleteArrayInput,
  Create,
  Edit,
  ReferenceArrayField,
  ReferenceArrayInput,
  SimpleShowLayout,
  Resource,
  RichTextField,
  ChipField,
  Show,
  SimpleForm,
  Filter,
  NumberInput,
  NumberField,
  SingleFieldList,
  TextField,
  TextInput,
  BooleanInput,
  BooleanField,
  List,
} from 'react-admin';
import { ColorField, ColorInput } from '../../components/colorInput';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import RichTextInput from 'ra-input-rich-text';
import Icon from '@material-ui/icons/Face';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'idealCustomerProfiles';
const resourceLabel = 'ICP';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} filters={<ListFilters />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <TextField source="shortname" />
      <BooleanField source="enabled" />
      <ReferenceArrayField
        label="Segmentation"
        source="bobjectPicklistFieldValues"
        reference="bobjectPicklistFieldValues"
      >
        <SingleFieldList linkType="show">
          <ChipField source="valueWithField" />
        </SingleFieldList>
      </ReferenceArrayField>

      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      <NumberField source="ordering" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <TextInput source="shortname" required />
      <ColorInput source="color" />
      <BooleanInput source="enabled" default />
      <NumberInput source="ordering" defaultValue={0} step={1} />
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <ReferenceArrayInput
        perPage={500}
        defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
        label="Segmentation"
        source="bobjectPicklistFieldValues"
        filter={{
          'bobjectField.segmentation': true,
          'bobjectField.bobjectType.name': 'Lead',
        }}
        reference="bobjectPicklistFieldValues"
      >
        <AutocompleteArrayInput
          translateChoice={false}
          source="bobjectPicklistFieldValues"
          optionValue="id"
          optionText="valueWithField"
          filter={{
            'bobjectField.segmentation': true,
            'bobjectField.bobjectType.name': 'Lead',
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="name" required />
      <TextField source="shortname" />
      <ColorField source="color" />
      <BooleanField source="enabled" />
      <NumberField source="ordering" />
      <RichTextField source="description" />
      <ReferenceArrayField
        label="Segmentation"
        source="bobjectPicklistFieldValues"
        reference="bobjectPicklistFieldValues"
      >
        <SingleFieldList linkType="show">
          <ChipField source="valueWithField" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="creationDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <TextInput source="shortname" required />
      <ColorInput source="color" />
      <BooleanInput source="enabled" />
      <NumberInput source="ordering" defaultValue={0} step={1} />
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <ReferenceArrayInput
        perPage={500}
        defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
        label="Segmentation"
        filter={{
          'bobjectField.segmentation': true,
          'bobjectField.bobjectType.name': 'Lead',
        }}
        source="bobjectPicklistFieldValues"
        reference="bobjectPicklistFieldValues"
      >
        <AutocompleteArrayInput
          translateChoice={false}
          source="bobjectPicklistFieldValues"
          optionValue="id"
          optionText="valueWithField"
          filter={{
            'bobjectField.segmentation': true,
            'bobjectField.bobjectType.name': 'Lead',
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Business Assets' }}
    list={list}
    icon={Icon}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
