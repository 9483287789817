import {
  Jwt,
  Rest,
  Web,
  Scheduler,
  Commons,
  Interceptors,
} from '@bloobirds-it/bloobirds-platform-js-api-library';

const customRestHeaders = [
  new Interceptors.CustomHeaderInterceptor({
    filter: () => true,
    headerName: () => 'x-include-references-to-many',
    headerValue: () => 'true',
  }),
  new Interceptors.CustomHeaderInterceptor({
    filter: () => true,
    headerName: () => 'x-include-extra-fields',
    headerValue: () => 'true',
  }),
];

console.log(process.env.API_ENV);
export const env = Commons.resolveEnvironmentFromHostname();
export const schedulingApi = Scheduler.Api.ofEnv(env);
export const webApi = Web.Api.ofEnv(env);
export const jwtApi = Jwt.Api.ofEnv(env);
export const restApi = new Rest.Api({
  host: Rest.config(env),
  debug: false,
  interceptors: [...Interceptors.defaultInterceptors, ...customRestHeaders],
});
