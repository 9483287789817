import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { refreshView } from 'react-admin';
import { webApi } from '../../misc/api';
import { ButtonWithConfirmation } from './ButtonWithConfirmation';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';

class SignAsButtonComponent extends Component {
  handleClick = record => {
    webApi
      .service()
      .request({
        method: Commons.HttpMethod.POST,
        url: `/service/users/${record.id}/signAs`,
      })
      .then(response => {
        window.open(response.link, '_blank');
      });
  };

  render() {
    return (
      <ButtonWithConfirmation buttonText="Sign as" onAction={this.handleClick} {...this.props} />
    );
  }
}

SignAsButtonComponent.propTypes = {
  refreshView: PropTypes.func,
};

export default connect(
  undefined,
  {
    refreshView,
  },
)(SignAsButtonComponent);
