const LEVEL_ERROR = 0;

const LEVEL_WARN = 1;

const LEVEL_INFO = 2;

const LEVEL_DEBUG = 3;

const LEVEL_CONFIG = {};
LEVEL_CONFIG[LEVEL_ERROR] = {
  label: 'ERROR',
  style: 'color: red',
};
LEVEL_CONFIG[LEVEL_WARN] = {
  label: 'WARN ',
  style: 'color: orange',
};
LEVEL_CONFIG[LEVEL_INFO] = {
  label: 'INFO ',
  style: 'color: blue',
};
LEVEL_CONFIG[LEVEL_DEBUG] = {
  label: 'DEBUG',
  style: 'color: black',
};

const logMessage = (loggerSettings, level, message, options) => {
  const o = loggerSettings.output();
  const colorEnabled = o === console.log;
  let m = '';
  const colorArray = [];
  m += `[${colorEnabled ? '%c' : ''}${LEVEL_CONFIG[level].label}${colorEnabled ? '%c' : ''}]`;
  if (colorEnabled) {
    colorArray.push(LEVEL_CONFIG[level].style);
    colorArray.push(loggerSettings.style());
  }
  m += `[${colorEnabled ? '%c' : ''}${loggerSettings.name()}${colorEnabled ? '%c' : ''}]`;
  if (colorEnabled) {
    colorArray.push(loggerSettings.nameStyle());
    colorArray.push(loggerSettings.style());
  }
  if (options.traceIds !== undefined && options.traceIds.length > 0) {
    m += '[';
    m += options.traceIds.reduce((i, t) => `${i + t.name}:${t.value};`, '');
    m += ']';
  }
  m += ` ${message}`;
  if (o === console.log) {
    o(m, ...colorArray);
  } else {
    o(m);
  }
};

const loggerFactory = ({
  name = 'default',
  output = console.log,
  nameStyle = 'color:black;',
  style = 'color:black;',
}) => {
  const loggerSettings = {
    name: () => name,
    output: () => output,
    nameStyle: () => nameStyle,
    style: () => style,
  };
  return {
    loggerSettings,
    log: (level, message, options = {}) => logMessage(loggerSettings, level, message, options),
    error: (message, options = {}) => logMessage(loggerSettings, LEVEL_ERROR, message, options),
    warn: (message, options = {}) => logMessage(loggerSettings, LEVEL_WARN, message, options),
    info: (message, options = {}) => logMessage(loggerSettings, LEVEL_INFO, message, options),
    debug: (message, options = {}) => logMessage(loggerSettings, LEVEL_DEBUG, message, options),
  };
};
function makeid() {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}
const newTrace = name => {
  return { name, value: makeid() };
};

const LoggerFactory = {
  create: loggerFactory,
  newTrace,
};

export default LoggerFactory;
