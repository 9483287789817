import React from 'react';
import {
  Edit,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceField,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Build';

const resourceName = 'integrationSalesforces';
const resourceLabel = 'Salesforce Integration';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const redirect = (basePath, id, data) => `/accounts/${data.account}/show/integration_salesforce`;

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="instanceHost" />
      <TextField source="clientId" label="Consumer Key" />
      <TextField source="salesforceUser" />
      <TextField source="model" />
      <ReferenceField source="account" reference="accounts" link="show">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="instanceHost" />
      <TextInput source="clientId" label="Consumer Key" />
      <TextInput source="salesforceUser" />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, hiddenInMenu: true }}
    icon={Icon}
    list={undefined}
    edit={edit}
    show={show}
  />
);

export default resource;
