import React, { useEffect, useState } from 'react';
import {
  Create,
  Edit,
  Resource,
  Show,
  SimpleForm,
  TabbedShowLayout,
  Tab,
  Filter,
  ReferenceField,
  TextField,
  NumberField,
  ReferenceInput,
  FunctionField,
  AutocompleteInput,
  BooleanInput,
  BooleanField,
  DateInput,
  TextInput,
  TabbedForm,
  NumberInput,
  FormTab,
  ReferenceManyField,
  Link,
  SelectInput,
  List,
  Datagrid,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Title from '../../components/Title';
import AddIcon from '@material-ui/icons/Add';
import PostShowActions from '../../components/PostShowActions';
import { DownloadModelButton, SubhomeRollbackButton } from '../../components/buttons';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import CloneDashboardsButton from '../../components/buttons/CloneDashboardsButton';
import { getList } from '../../providers/DataProvider';
import { Empty } from '../../components/EmptyPage';
import { webApi } from '../../misc/api';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';
import SessionManagerFactory from '../../misc/session';

const resourceLabel = 'Account';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const SessionManager = SessionManagerFactory();

const initSalesforceInboundTrigger = () => {
  webApi
    .service()
    .request({
      url: `/service/mappings/salesforce/${SessionManager.getAccount().id}/create/inbound`,
      method: Commons.HttpMethod.POST,
    })
    .then(res => {
      if (res.code === 200) {
        return res.json();
      }
      return res;
    })
    .then(() => {
      // eslint-disable-next-line no-restricted-globals
      setTimeout(() => location.reload(), 1000);
    });
};

const GenerateSalesforceInboundMapping = () => {
  const [visible, isVisible] = useState(false);
  useEffect(() => {
    getList(
      'integrationSalesforces',
      { field: 'ordering', order: 'DESC' },
      { page: 1, pageSize: 25 },
      {},
    ).then(response => {
      const integration = response.total > 0;
      getList(
        'accountIntegrationTriggers',
        { field: 'ordering', order: 'DESC' },
        { page: 1, pageSize: 25 },
        {},
      ).then(response => {
        isVisible(
          integration &&
            response.data.find(ait => ait.name === 'INBOUND__SALESFORCE') === undefined,
        );
      });
    });
  }, []);
  return (
    <div>
      {visible && (
        <Button component={Link} color="primary" onClick={initSalesforceInboundTrigger}>
          <AddIcon /> Install Salesforce Inbound.
        </Button>
      )}
    </div>
  );
};

const CreateHubspotIntegrationButton = props => {
  const [visible, isVisible] = useState(true);
  useEffect(() => {
    getList(
      'integrationHubspots',
      { field: 'ordering', order: 'DESC' },
      { page: 1, pageSize: 25 },
      {},
    ).then(response => {
      isVisible(response.total === 0);
    }, []);
  });
  return (
    <div>
      {visible && (
        <Button
          component={Link}
          color="primary"
          to={{
            pathname: '/integrationHubspots/create',
            state: { record: { account: props.record.id } },
          }}
        >
          <AddIcon /> Add a new Integration
        </Button>
      )}
      {!visible && <div />}
    </div>
  );
};

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <SelectInput
      source="type"
      choices={[
        { id: 'CUSTOMER', name: 'Customer' },
        { id: 'TEMPLATE', name: 'Template' },
        { id: 'SALES_DEMO', name: 'Sales Demo' },
        { id: 'PARTNER_DEMO', name: 'Partner Demo' },
        { id: 'QA', name: 'QA' },
        { id: 'DEVELOPMENT', name: 'Development' },
      ]}
      allowEmpty
      alwaysOn
    />
    <BooleanInput label="Churned" source="churned" />
  </Filter>
);

const list = props => {
  const EmptyPage = Empty(resourceLabel);
  return (
    <List
      title={resourceTitle}
      filters={<ListFilters />}
      empty={<EmptyPage />}
      perPage={25}
      {...props}
    >
      <CustomizableDatagrid rowClick="show">
        <TextField source="name" />
        <BooleanField source="churned" />
        <TextField source="churnedDate" />
        <TextField source="type" />
        <ReferenceField link={false} label="Country" source="country" reference="countries">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link={false} label="Time Zone" source="timeZone" reference="timeZones">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="currentActiveUsers" />
        <NumberField source="maxActiveUsers" />
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        <TextField source="supersetAssetsStatus" />
        <TextField source="reportingStatus" />
        <TextField source="logTracingEmail" />
        <TextField source="id" />
      </CustomizableDatagrid>
    </List>
  );
};
const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions hideDelete resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="type" />
        )}
        <FunctionField
          label="Users"
          render={record =>
            `${record.currentActiveUsers} active of ${record.maxActiveUsers} allowed`
          }
        />
        <ReferenceField link={false} label="Country" source="country" reference="countries">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="churned" />
        <TextField source="churnedDate" />
        <ReferenceField link={false} label="Time Zone" source="timeZone" reference="timeZones">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="creationDatetime" />
        <TextField source="logTracingEmail" />
        <DownloadModelButton />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <Tab label="settings" path="settings">
          <BooleanField source="gmailConnectButtonEnabled" />
          <BooleanField source="microsoftConnectButtonEnabled" />
          <TextField source="gmailConnectButtonType" />
          <TextField source="twilioFlowType" />
          <TextField source="calendarLinksType" />
          <TextField source="mailtoLinksType" />
          <BooleanField source="openCalendarPopupAfterMeeting" />
          <BooleanField source="featureSalesEnabled" />
          <BooleanField source="leadEmailMatchingEnabled" />
          <BooleanField source="ignoreEmptyUpdates" />
          <BooleanField source="ignoreEmptyZapierUpdates" />
          <TextField source="bobjectDatabase" />
          <ReferenceField
            label="Opportunity default cadence"
            source="opportunityDefaultCadence"
            lin="show"
            reference="cadences"
          >
            <TextField source="name" />
          </ReferenceField>
        </Tab>
      )}
      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <Tab label="data" path="data">
          <CloneDashboardsButton />
        </Tab>
      )}
      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <Tab label="actions">
          <DownloadModelButton />
          <SubhomeRollbackButton />
        </Tab>
      )}
      <Tab label="twilio">
        <TextField source="twilioApplicationSid" />
        <TextField source="twilioAccountSid" />
        <TextField source="twilioAuthToken" />
        <BooleanField source="enableCallRecording" />
      </Tab>
      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <Tab label="salesforce integration" path="integration_salesforce">
          <ReferenceManyField
            perPage={500}
            reference="integrationSalesforces"
            target="account.id"
            sort={{ field: 'ordering', order: 'DESC' }}
            addLabel={false}
          >
            <Datagrid rowClick="show">
              <TextField source="model" />
              <TextField source="instanceHost" />
              <TextField source="clientId" label="Consumer Key" />
              <TextField source="salesforceUser" />
            </Datagrid>
          </ReferenceManyField>
          <GenerateSalesforceInboundMapping />
        </Tab>
      )}
      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <Tab label="hubspot integration" path="integration_hubspot">
          <CreateHubspotIntegrationButton />
          <ReferenceManyField
            perPage={500}
            reference="integrationHubspots"
            target="account.id"
            sort={{ field: 'ordering', order: 'DESC' }}
            addLabel={false}
          >
            <Datagrid rowClick="show">
              <TextField source="accessToken" />
              <TextField source="refreshToken" />
              <TextField source="legacyToken" />
              <TextField source="portalId" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      )}
    </TabbedShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="accountName" required />
      <ReferenceInput
        label="Country"
        perPage={500}
        sort={{ field: 'name', order: 'ASC' }}
        source="country"
        reference="countries"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Time Zone"
        perPage={500}
        sort={{ field: 'utmDiff', order: 'ASC' }}
        source="timeZone"
        reference="timeZones"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="logTracingEmail" required />
      <TextInput source="userName" required />
      <TextInput source="userEmail" required type="email" />
      <TextInput source="userPassword" required type="password" />

      <TextInput source="reportingDbHostname" />
      <TextInput source="reportingDbPort" />
      <TextInput source="reportingDbUser" />
      <TextInput source="reportingDbDatabase" />
      <TextInput source="reportingDbPassword" />
      <TextInput source="reportingDbDirectHostname" />
      <TextInput source="reportingDbDirectPort" />
      <NumberInput source="reportingMaxStringCharacters" defaultValue={255} />

      <ReferenceInput
        label="Copy metadata from"
        perPage={500}
        source="copyFromAccountId"
        reference="accounts"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="copyBusinessAssets" />
      <BooleanInput source="copyData" />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit title={resourceTitle} actions={<PostShowActions hideDelete />} {...props}>
    <TabbedForm redirect="show">
      <FormTab label="summary">
        {props.permissions != null &&
          props.permissions.isGlobalAdmin() && [
            <TextInput source="name" required />,
            <NumberInput source="maxActiveUsers" />,
            <BooleanInput source="churned" />,
            <DateInput source="churnedDate" />,
            <SelectInput
              source="type"
              choices={[
                { id: 'CUSTOMER', name: 'Customer' },
                { id: 'TEMPLATE', name: 'Template' },
                { id: 'SALES_DEMO', name: 'Sales Demo' },
                { id: 'PARTNER_DEMO', name: 'Partner Demo' },
                { id: 'QA', name: 'QA' },
                { id: 'DEVELOPMENT', name: 'Development' },
              ]}
              allowEmpty={false}
            />,
            <TextInput source="reportingHomeUrl" />,
          ]}
        <ReferenceInput
          label="Country"
          perPage={500}
          sort={{ field: 'name', order: 'ASC' }}
          source="country"
          reference="countries"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Time Zone"
          perPage={500}
          sort={{ field: 'utmDiff', order: 'ASC' }}
          source="timeZone"
          reference="timeZones"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="logTracingEmail" />
      </FormTab>

      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <FormTab label="data" path="data">
          <TextInput source="nativeReportingHostname" />
          <TextInput source="nativeReportingPort" />
          <TextInput source="nativeReportingUser" />
          <TextInput source="nativeReportingDatabase" />
          <TextInput source="nativeReportingPassword" />
          <TextInput source="nativeReportingDirectHostname" />
          <TextInput source="nativeReportingDirectPort" />
        </FormTab>
      )}
      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <FormTab label="settings">
          <BooleanInput source="gmailConnectButtonEnabled" />
          <BooleanInput source="microsoftConnectButtonEnabled" />
          <SelectInput
            source="gmailConnectButtonType"
            choices={[{ id: 'LEGACY', name: 'Legacy' }, { id: 'NYLAS', name: 'Nylas' }]}
            allowEmpty={false}
          />
          <SelectInput
            source="twilioFlowType"
            choices={[{ id: 'LEGACY', name: 'Legacy' }, { id: 'SEP2020', name: 'Sep 2020' }]}
            allowEmpty={false}
          />
          <SelectInput
            source="calendarLinksType"
            choices={[
              { id: 'GOOGLE_CALENDAR', name: 'Google Calendar' },
              { id: 'OUTLOOK', name: 'Outlook' },
            ]}
            allowEmpty={false}
          />
          <SelectInput
            source="mailtoLinksType"
            choices={[
              { id: 'MAILTO', name: 'Mail to' },
              { id: 'GMAIL', name: 'Gmail' },
              { id: 'OUTLOOK', name: 'Outlook' },
            ]}
            allowEmpty={false}
          />
          <BooleanInput source="ignoreEmptyUpdates" />
          <BooleanInput source="ignoreEmptyZapierUpdates" />
          <SelectInput
            source="bobjectDatabase"
            choices={[{ id: 'REDISELA', name: 'REDISELA' }, { id: 'MONGODB', name: 'MONGODB' }]}
            allowEmpty={false}
          />
          <BooleanInput source="openCalendarPopupAfterMeeting" />
          <BooleanInput source="featureSalesEnabled" />
          <BooleanInput source="leadEmailMatchingEnabled" />
          <ReferenceInput
            label="Opportunity default cadence"
            source="opportunityDefaultCadence"
            reference="cadences"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormTab>
      )}
      <FormTab label="twilio">
        <TextInput source="twilioApplicationSid" />
        <TextInput source="twilioAccountSid" />
        <TextInput source="twilioAuthToken" />
        <BooleanInput source="enableCallRecording" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default permissions => (
  <Resource
    name="accounts"
    options={{ label: 'Account', menuGroup: 'Administrative' }}
    list={permissions.isGlobalAdmin() ? list : undefined}
    create={permissions.isGlobalAdmin() ? create : undefined}
    edit={permissions.isGlobalAdmin() || permissions.isAccountAdmin() ? edit : undefined}
    show={show}
  />
);
