import React from 'react';
import {
  SimpleShowLayout,
  Create,
  Edit,
  NumberInput,
  NumberField,
  Resource,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/GroupWork';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'bobjectFieldGroups';
const resourceLabel = 'Field Group';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <NumberField source="ordering" />

      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="icon" />
      <NumberField source="ordering" />
    </SimpleShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <TextInput source="icon" />
      <NumberInput source="ordering" defaultValue={0} step={1} />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect="show">
      <TextInput source="name" />
      <TextInput source="icon" />
      <NumberInput source="ordering" defaultValue={0} step={1} />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Customisation' }}
    list={list}
    create={create}
    icon={Icon}
    edit={edit}
    show={show}
  />
);

export default resource;
