import Title from '../../components/Title';
import {
  Create,
  Edit,
  Filter,
  Resource,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
  CreateButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import PeopleIcon from '@material-ui/icons/People';
import PostShowActions from '../../components/PostShowActions';
import React from 'react';
import { Empty } from '../../components/EmptyPage';
// eslint-disable-next-line max-len
import { RefreshSalesforceUsersButton } from '../../components/buttons/RefreshSalesforceUsersButton';
import { makeStyles } from '@material-ui/core/styles';

const resourceName = 'salesforceUsers';
const resourceLabel = 'Salesforce User';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginTop: '20px',
  },
});
const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Salesforce Username" source="salesforceUserName" alwaysOn />
  </Filter>
);

const ListActions = ({ basePath }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CreateButton basePath={basePath} />
      <RefreshSalesforceUsersButton />
    </div>
  );
};

const list = props => {
  return (
    <List
      title={resourceTitle}
      empty={<EmptyPage salesforceUsers />}
      filters={<ListFilters />}
      actions={<ListActions />}
      {...props}
    >
      <CustomizableDatagrid rowClick="show">
        <TextField source="salesforceUserId" label="Salesforce ID" />
        <TextField source="salesforceUser" label="Salesforce User" />
        <TextField source="bloobirdsUserId" label="Bloobirds ID" />
        <TextField source="salesforceUserName" label="Salesforce Username" />
        <TextField source="salesforceUserEmail" label="Salesforce Email" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </CustomizableDatagrid>
    </List>
  );
};

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="salesforceUserId" label="Salesforce ID" />
      <TextInput source="salesforceUser" label="Salesforce User" />
      <ReferenceInput source="bloobirdsUserId" reference="users" allowEmpty>
        <SelectInput source="name" allowEmpty />
      </ReferenceInput>
      <TextInput source="salesforceUserName" label="Salesforce Username" />
      <TextInput source="salesforceUserEmail" label="Salesforce Email" />
    </SimpleForm>
  </Create>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="salesforceUserId" label="Salesforce ID" />
        <TextField source="salesforceUser" label="Salesforce User" />
        <ReferenceField source="bloobirdsUserId" link="show" reference="users">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="salesforceUserName" label="Salesforce Username" />
        <TextField source="salesforceUserEmail" label="Salesforce Email" />
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        <TextField source="createdBy" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="salesforceUserId" label="Salesforce ID" />
      <TextInput source="salesforceUser" label="Salesforce User" />
      <ReferenceInput source="bloobirdsUserId" reference="users" allowEmpty>
        <SelectInput source="name" allowEmpty />
      </ReferenceInput>
      <TextInput source="salesforceUserName" label="Salesforce Username" />
      <TextInput source="salesforceUserEmail" label="Salesforce Email" />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Salesforce' }}
    list={list}
    icon={PeopleIcon}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
