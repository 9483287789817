import React from 'react';
import {
  ChipField,
  Create,
  EditController,
  EmailField,
  ReferenceArrayField,
  ReferenceArrayInput,
  NumberInput,
  NumberField,
  Resource,
  BooleanField,
  BooleanInput,
  SelectArrayInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  EditView,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Filter,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import UserIcon from '@material-ui/icons/People';
import ChangePassword from '../../components/ChangePassword';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import SessionManagerFactory from '../../misc/session/index';
import { ColorField, ColorInput } from '../../components/colorInput';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { SignAsButton } from '../../components/buttons';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'users';
const resourceLabel = 'User';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List
    title={resourceTitle}
    filters={<ListFilters />}
    empty={<EmptyPage />}
    perPage={25}
    {...props}
  >
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <TextField source="type" />
      )}
      <BooleanField source="active" />
      <TextField source="email" />
      <ReferenceArrayField
        label="User Permission"
        source="userPermissions"
        reference="userPermissions"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="User Role" source="userRoles" reference="userRoles">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField
        label="Employee Role"
        allowEmpty
        source="employeeRole"
        reference="employeeRoles"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
      <SignAsButton />
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="type" />
      <BooleanField source="active" />
      <TextField source="invitationStatus" />
      <EmailField source="email" type="email" />
      <TextField source="shortname" />
      <ColorField source="color" />
      <BooleanField source="emailTrackingNotificationsEnabled" />
      <NumberField source="ordering" />
      <ReferenceArrayField
        label="User Permission"
        source="userPermissions"
        reference="userPermissions"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="User Roles" source="userRoles" reference="userRoles">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField source="assignable" />
      <ReferenceField
        label="Employee Role"
        allowEmpty
        source="employeeRole"
        reference="employeeRoles"
      >
        <TextField source="name" />
      </ReferenceField>
      {props.permissions != null && props.permissions.isGlobalAdmin() && (
        <ReferenceArrayField label="Tasks" source="taskTypes" reference="taskTypes">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      )}
      <NumberField source="companiesToAddPerTask" />
      {/* <ReferenceArrayField source="phoneNumbers" reference="phoneNumbers">
        <SingleFieldList linkType="show">
          <ChipField source="phoneNumber" />
        </SingleFieldList>
      </ReferenceArrayField> */}
      <TextField source="creationDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const redirect = () => '/users';

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" required />
      <TextInput source="email" type="email" required />
      <TextInput source="password" type="password" required />
      <BooleanInput source="assignable" />
      {props.permissions && props.permissions.isGlobalAdmin() && (
        <SelectInput
          source="type"
          choices={[
            { id: 'LICENSE_USER', name: 'License User' },
            { id: 'FREE_USER', name: 'Free User' },
            { id: 'SUPPORT_USER', name: 'Support User' },
          ]}
          allowEmpty={false}
          required
        />
      )}
      <ReferenceArrayInput
        perPage={100}
        label="User Permission"
        source="userPermissions"
        reference="userPermissions"
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        perPage={100}
        label="User Role"
        source="userRoles"
        reference="userRoles"
        filter={
          props.permissions != null && props.permissions.isGlobalAdmin()
            ? undefined
            : { name: 'Account' }
        }
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceInput
        label="Employee Role"
        allowEmpty
        source="employeeRole"
        reference="employeeRoles"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const styles = {
  card: {
    boxShadow: 'none',
  },
};
const validateUserEdit = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  if (!values.email) {
    errors.email = ['The email is required'];
  }
  return errors;
};
const MyEditView = withStyles(styles)(({ classes, ...props }) => {
  return (
    <EditView
      {...props}
      actions={<PostShowActions resourceLabel={'Users'} permissions={props.permissions} />}
      classes={classes}
      title={resourceTitle}
    >
      <SimpleForm redirect="show" validate={validateUserEdit}>
        <TextInput source="name" required />
        <BooleanInput source="active" />
        <SelectInput
          source="type"
          choices={[
            { id: 'LICENSE_USER', name: 'License User' },
            { id: 'FREE_USER', name: 'Free User' },
            { id: 'SUPPORT_USER', name: 'Support User' },
          ]}
          allowEmpty={false}
        />
        <SelectInput
          source="invitationStatus"
          choices={[
            { id: 'INVITATION_SENT', name: 'Invitation Sent' },
            { id: 'COMPLETED', name: 'Completed' },
          ]}
          allowEmpty={false}
        />
        <TextInput source="email" type="email" required />
        <TextInput source="shortname" required />
        <ColorInput source="color" />
        <BooleanInput source="emailTrackingNotificationsEnabled" />
        <NumberInput source="ordering" />
        <ReferenceArrayInput
          perPage={100}
          label="User Permission"
          source="userPermissions"
          reference="userPermissions"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          perPage={100}
          label="User Role"
          source="userRoles"
          reference="userRoles"
          filter={
            props.permissions != null && props.permissions.isGlobalAdmin()
              ? undefined
              : { name: 'Account' }
          }
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput
          label="Employee Role"
          allowEmpty
          source="employeeRole"
          reference="employeeRoles"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {props.permissions && props.permissions.isGlobalAdmin() && (
          <ReferenceArrayInput perPage={100} label="Tasks" source="taskTypes" reference="taskTypes">
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        )}
        <BooleanInput source="assignable" />
        <NumberInput source="companiesToAddPerTask" />
        {/* <ReferenceArrayInput
          label="Phone Numbers"
          source="phoneNumbers"
          reference="phoneNumbers"
          perPage={100}
        >
          <SelectArrayInput optionText="phoneNumber" />
        </ReferenceArrayInput> */}
      </SimpleForm>
    </EditView>
  );
});

const edit = props => {
  const sm = SessionManagerFactory();
  const userId = sm.getUser().id;
  return (
    <EditController {...props}>
      {controllerProps => (
        <Paper>
          <MyEditView {...controllerProps} {...props} />
          {controllerProps.record !== undefined && controllerProps.record.id === userId && (
            <ChangePassword />
          )}
        </Paper>
      )}
    </EditController>
  );
};

const resource = () => (
  <Resource
    icon={UserIcon}
    options={{ label: resourceLabel, menuGroup: 'Sales Team' }}
    name={resourceName}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);
export default resource;
