import React from 'react';
import {
  Create,
  Edit,
  Resource,
  RichTextField,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  NumberInput,
  NumberField,
  Filter,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
  List,
} from 'react-admin';
import { ColorField, ColorInput } from '../../components/colorInput';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import RichTextInput from 'ra-input-rich-text';
import Icon from '@material-ui/icons/TouchApp';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'useCases';
const resourceLabel = 'Scenario';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} filters={<ListFilters />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <TextField source="shortname" />
      <BooleanField source="enabled" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      <NumberField source="ordering" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <BooleanInput source="enabled" default />
      <TextInput source="shortname" required />
      <ColorInput source="color" />
      <NumberInput source="ordering" defaultValue={0} step={1} />
      <RichTextInput source="description" toolbar={toolbarConfig} />
    </SimpleForm>
  </Create>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" required />
        <TextField source="shortname" />
        <BooleanField source="enabled" />
        <ColorField source="color" />
        <NumberField source="ordering" />
        <RichTextField source="description" />
        <TextField source="creationDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <TextInput source="shortname" required />
      <BooleanInput source="enabled" />
      <ColorInput source="color" />
      <NumberInput source="ordering" defaultValue={0} step={1} />
      <RichTextInput source="description" toolbar={toolbarConfig} />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Business Assets' }}
    list={list}
    icon={Icon}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
