import Title from '../../components/Title';
import {
  Create,
  Edit,
  Filter,
  ReferenceField,
  ReferenceInput,
  Resource,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import CompareArrows from '@material-ui/icons/CompareArrows';
import PostShowActions from '../../components/PostShowActions';
import React from 'react';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'hubspotSubscriptions';
const resourceLabel = 'Hubspot Subscription';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Hubspot Event" source="hubspotEventName" alwaysOn />
    <ReferenceInput label="BobjectType" source="bobjectType" reference="bobjectTypes" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      label="Type"
      source="type"
      alwaysOn
      choices={[
        { id: 'WORKFLOW', name: 'WORKFLOW' },
        { id: 'CUSTOM', name: 'CUSTOM' },
        { id: 'MANAGED', name: 'MANAGED' },
      ]}
    />
    <ReferenceInput
      label="Trigger Mapping"
      source="triggerMapping"
      reference="triggerMappings"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} filters={<ListFilters />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" label="Name" />
      <TextField source="hubspotEventName" label="Hubspot Event" />
      <ReferenceField source="bobjectType" reference="bobjectTypes">
        <TextField source="name" label="BobjectType" />
      </ReferenceField>
      <ReferenceField source="triggerMapping" reference="triggerMappings">
        <TextField source="name" label="Trigger Mapping" />
      </ReferenceField>
      <TextField source="type" label="Type" />
      <BooleanField source="active" label="Active" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const options = [
  { id: 'contact.creation', name: 'Contact CREATE' },
  { id: 'contact.deletion', name: 'Contact DELETE' },
  { id: 'contact.privacyDeletion', name: 'Contact Privacy DELETE' },
  { id: 'contact.propertyChange', name: 'Contact UPDATE' },
  { id: 'company.creation', name: 'Company CREATE' },
  { id: 'company.deletion', name: 'Company DELETE' },
  { id: 'company.propertyChange', name: 'Company UPDATE' },
  { id: 'deal.creation', name: 'Deal CREATE' },
  { id: 'deal.deletion', name: 'Deal DELETE' },
  { id: 'deal.propertyChange', name: 'Deal UPDATE' },
  { id: 'custom.engagement_call.update', name: 'Call UPDATE' },
  { id: 'custom.engagement_note.update', name: 'Note UPDATE' },
  { id: 'custom.engagement_email.update', name: 'Email UPDATE' },
  { id: 'custom.engagement_meeting.update', name: 'Meeting UPDATE' },
  { id: 'custom.contactList.update', name: 'Contact Lists' },
  { id: 'custom.form.submissions', name: 'Form Submissions' },
  { id: 'custom.contact.workflow', name: 'Contact Workflow' },
  { id: 'custom.form.submissions.workflow', name: 'Form Submissions Workflow' },
  {
    id: 'custom.form.submissions.workflow.multiple.account',
    name: 'Form Submissions Workflow Multiple Accounts',
  },
];

const create = props => {
  return (
    <Create title={resourceTitle} {...props}>
      <SimpleForm redirect="show">
        <TextInput label="Name" source="name" />
        <SelectInput label="Hubspot Event" source="hubspotEventName" choices={options} />
        <ReferenceInput source="bobjectType" reference="bobjectTypes">
          <SelectInput source="name" label="BobjectType" />
        </ReferenceInput>
        <ReferenceInput source="triggerMapping" reference="triggerMappings">
          <SelectInput source="name" label="Trigger Mapping" />
        </ReferenceInput>
        <SelectInput
          label="Type"
          source="type"
          alwaysOn
          choices={[
            { id: 'WORKFLOW', name: 'WORKFLOW' },
            { id: 'CUSTOM', name: 'CUSTOM' },
            { id: 'MANAGED', name: 'MANAGED' },
          ]}
        />
        <BooleanInput source="active" />
      </SimpleForm>
    </Create>
  );
};
const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" label="Name" />
        <TextField source="hubspotEventName" label="Hubspot Event" />
        <ReferenceField source="bobjectType" reference="bobjectTypes">
          <TextField source="name" label="BobjectType" />
        </ReferenceField>
        <ReferenceField source="triggerMapping" reference="triggerMappings">
          <TextField source="name" label="Trigger Mapping" />
        </ReferenceField>
        <TextField source="type" label="Type" />
        <BooleanField source="active" label="Active" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => {
  return (
    <Edit title={resourceTitle} {...props}>
      <SimpleForm redirect="show">
        <TextInput label="Name" source="name" />
        <SelectInput label="Hubspot Event" source="hubspotEventName" choices={options} />
        <ReferenceInput source="bobjectType" reference="bobjectTypes">
          <SelectInput source="name" label="BobjectType" />
        </ReferenceInput>
        <ReferenceInput source="triggerMapping" reference="triggerMappings">
          <SelectInput source="name" label="Trigger Mapping" />
        </ReferenceInput>
        <SelectInput
          label="Type"
          source="type"
          alwaysOn
          choices={[
            { id: 'WORKFLOW', name: 'WORKFLOW' },
            { id: 'CUSTOM', name: 'CUSTOM' },
            { id: 'MANAGED', name: 'MANAGED' },
          ]}
        />
        <BooleanInput source="active" />
      </SimpleForm>
    </Edit>
  );
};

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Hubspot',
      isOnlyVisibleFor: ['GLOBAL_DEV', 'GLOBAL_ADMIN'],
    }}
    list={list}
    icon={CompareArrows}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
