import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, showNotification } from 'react-admin';
import SessionManagerFactory from '../../misc/session';
import InputIcon from '@material-ui/icons/Input';
import { jwtApi } from '../../misc/api';

const SessionManager = SessionManagerFactory();

class SwitchUserButton extends Component {
  handleClick = event => {
    event.preventDefault();
    const { record } = this.props;
    jwtApi
      .service()
      .login({ email: record.email, token: SessionManager.getRootToken() })
      .then(r => {
        SessionManager.setToken(r.token);
        window.location.reload();
      });
  };

  render() {
    const { record, showNotification, ...rest } = this.props;
    if (
      record &&
      record.id !== SessionManager.getUser().id &&
      SessionManager.getRoleManager().isAccountAdmin()
    ) {
      return (
        <Button label="Switch" onClick={this.handleClick} {...rest}>
          <InputIcon />
        </Button>
      );
    }
    return null;
  }
}

SwitchUserButton.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(
  undefined,
  {
    showNotification,
  },
)(SwitchUserButton);
