import React from 'react';
import { Button } from 'react-admin';
import Edit from '@material-ui/icons/Edit';

const EditRedirect = props => {
  if (props.redirect !== undefined) {
    return (
      <Button style={{ marginTop: '1em' }} onClick={props.redirect()} label="Edit">
        <Edit />
      </Button>
    );
  }
  return '';
};

export default EditRedirect;
