import React, { Fragment, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-admin';
import { TextField } from '@material-ui/core';
import { webApi } from '../../misc/api';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';

const styles = () => ({
  dialogFooter: {
    justifyContent: 'space-between',
  },
});

const regex = RegExp('^[0-9]+(,[0-9]+)*$');

const Index = ({ record, refreshView }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [text, setText] = useState('');
  const [allowsInstall, setAllowsInstall] = useState(false);
  useEffect(() => {
    setAllowsInstall(regex.test(text));
  }, [text]);
  const [status, setStatus] = useState('ready');
  useEffect(() => {
    if (status === 'fired') {
      webApi
        .service()
        .request({
          url: `/service/accounts/${record.id}/cloneDashboards`,
          method: Commons.HttpMethod.POST,
          body: { dashboardIds: text.split(',').map(x => parseInt(x, 10)) },
        })
        .then(() => {
          refreshView();
          setStatus('ready');
        });
      setStatus('requested');
      setShowDialog(false);
    }
  }, [status, text]);

  return (
    <Fragment>
      <Button onClick={() => setShowDialog(true)} key="button" label="Install Dashboards" />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label="Are you sure?"
      >
        <DialogTitle>Install Dashboards</DialogTitle>
        <DialogContent>
          <div>List the dashboards ids to clone separated by colon.</div>
          <TextField
            id="dashboardsToClone"
            type="text"
            placeholder="100,120,155"
            value={text}
            onChange={t => setText(t.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {allowsInstall && status === 'ready' && (
            <Button onClick={() => setStatus('fired')} key="button" label="Install" />
          )}
          <Button onClick={() => setShowDialog(false)} label="Cancel" />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export const InstallDashboardsButton = withStyles(styles)(Index);
