import React from 'react';
import { Show, Tab, TabbedShowLayout, TextField, Resource } from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Code';
import CodeTextField from '../../components/CodeTextField';

const resourceName = 'bobjectTypeModels';
const resourceLabel = 'Bobject Type Model';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        <TextField source="creationDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
      <Tab label="Config">
        <CodeTextField source="jsonConfig" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      isOnlyVisibleFor: ['GLOBAL_DEV', 'ACCOUNT_DEV', 'GLOBAL_ADMIN'],
    }}
    icon={Icon}
    show={show}
  />
);

export default resource;
