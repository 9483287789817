import React, { Children, cloneElement } from 'react';
import { MenuItemLink } from 'react-admin';
import SessionManagerFactory from '../misc/session';
import RestoreUserButton from './buttons/RestoreUserButton';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { translate } from 'ra-core';

const SessionManager = SessionManagerFactory();

class UserMenu extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    logout: PropTypes.node,
    translate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: 'ra.auth.user_menu',
  };

  state = {
    anchorEl: null,
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { children, label, logout, translate } = this.props;
    if (!logout && !children) return null;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Tooltip title={label && translate(label, { _: label })}>
          <IconButton
            arial-label={label && translate(label, { _: label })}
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
          {Children.map(children, menuItem =>
            cloneElement(menuItem, { onClick: this.handleClose }),
          )}
          {SessionManager.isSwitchingToken() && <RestoreUserButton key="menuItemRestore" />}
          {logout}
        </Menu>
      </div>
    );
  }
}

const TUserMenu = translate(UserMenu);

const CustomUserMenu = props => {
  const user = SessionManager.getUser();
  const account = SessionManager.getAccount();
  if (user && account) {
    return (
      <TUserMenu {...props}>
        <MenuItemLink key="menuItemUser" primaryText={user.name} to={`/users/${user.id}/show`} />
        <MenuItemLink
          key="menuItemAccount"
          primaryText={account.name}
          to={`/accounts/${account.id}/show`}
        />
      </TUserMenu>
    );
  }
  return <div />;
};

export default CustomUserMenu;
