/* eslint-disable no-underscore-dangle */
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import LoggerFactory from '../misc/log';
import SessionManagerFactory from '../misc/session';
import { sha512 } from 'js-sha512';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';
import { restApi, webApi } from '../misc/api';

const apiUrl = restApi.host.url;
const logger = LoggerFactory.create({
  name: 'DataProvider',
  nameStyle: 'color:blue;font-weight:bold;',
});
const SessionManager = SessionManagerFactory();
const entitiesWithServiceCreators = {
  users: {
    create: () => '/service/users/create',
    delete: id => `/service/users/${id}`,
  },
  accounts: {
    create: () => '/service/accounts/create',
    delete: id => `/service/accounts/${id}`,
  },
  reportingPipelines: {
    create: () => `/service/reporting/${SessionManager.getAccount().id}/pipeline`,
    delete: id => `/service/reporting/${SessionManager.getAccount().id}/pipeline/${id}`,
  },
};

const entitiyReferenceNames = {
  accounts: {
    country: 'countries',
    timeZone: 'timeZones',
    opportunityDefaultCadence: 'cadences',
  },
  users: {
    account: 'account',
    userPermissions: 'userPermissions',
    userRoles: 'userRoles',
    employeeRole: 'employeeRoles',
    taskTypes: 'taskTypes',
    phoneNumbers: 'phoneNumbers',
  },
  taskTypes: {},
  discardedReasons: {
    taskType: 'taskTypes',
  },
  useCases: {},
  targetMarkets: {
    bobjectPicklistFieldValues: 'bobjectPicklistFieldValues',
    cadence: 'cadences',
  },
  bobjectViews: {
    user: 'users',
    bobjectField: 'bobjectFields',
    tags: 'tags',
  },
  bobjectViewFilters: {
    bobjectView: 'bobjectViews',
    bobjectField: 'bobjectFields',
    bobjectPicklistFieldValue: 'bobjectPicklistFieldValues',
  },
  bobjectViewFilterValues: {
    bobjectViewFilter: 'bobjectViewFilters',
    bobjectPicklistFieldValue: 'bobjectPicklistFieldValues',
  },
  bobjectViewColumns: {
    bobjectView: 'bobjectViews',
    bobjectField: 'bobjectFields',
  },
  phoneNumbers: {},
  bobjectGlobalPicklists: {},
  tags: {},
  customFields: {
    fieldType: 'fieldTypes',
    parentBobjectField: 'bobjectFields',
    bobjectType: 'bobjectTypes',
    referencedBobjectType: 'bobjectTypes',
    bobjectFieldGroup: 'bobjectFieldGroups',
    bobjectGlobalPicklist: 'bobjectGlobalPicklists',
    targetMarkets: 'targetMarkets',
    idealCustomerProfiles: 'idealCustomerProfiles',
    useCases: 'useCases',
    defaultBobjectPicklistFieldValue: 'bobjectPicklistFieldValues',
  },
  systemFields: {
    fieldType: 'fieldTypes',
    parentBobjectField: 'bobjectFields',
    bobjectType: 'bobjectTypes',
    referencedBobjectType: 'bobjectTypes',
    bobjectFieldGroup: 'bobjectFieldGroups',
    bobjectGlobalPicklist: 'bobjectGlobalPicklists',
    targetMarkets: 'targetMarkets',
    idealCustomerProfiles: 'idealCustomerProfiles',
    useCases: 'useCases',
    defaultBobjectPicklistFieldValue: 'bobjectPicklistFieldValues',
  },
  bobjectFields: {
    fieldType: 'fieldTypes',
    parentBobjectField: 'bobjectFields',
    bobjectType: 'bobjectTypes',
    referencedBobjectType: 'bobjectTypes',
    bobjectFieldGroup: 'bobjectFieldGroups',
    bobjectGlobalPicklist: 'bobjectGlobalPicklists',
    targetMarkets: 'targetMarkets',
    idealCustomerProfiles: 'idealCustomerProfiles',
    useCases: 'useCases',
    defaultBobjectPicklistFieldValue: 'bobjectPicklistFieldValues',
  },

  bobjectConditionalFields: {
    bobjectField: 'bobjectFields',
    requiredType: 'bobjectPicklistFieldValues',
  },
  bobjectConditionalFieldValues: {
    bobjectField: 'bobjectFields',
    requiredBobjectField: 'bobjectFields',
    bobjectPicklistFieldValue: 'bobjectPicklistFieldValues',
    requiredBobjectPicklistFieldValue: 'bobjectPicklistFieldValues',
  },
  cadences: {
    firstAttemptActionTypes: 'cadenceActionType',
  },
  cadenceActionType: {},
  cadenceDays: {
    cadence: 'cadences',
    cadenceActionTypes: 'cadenceActionType',
  },
  accountSettings: {},
  bobjectPicklistFieldValues: {
    bobjectField: 'bobjectFields',
    parentBobjectPicklistFieldValue: 'bobjectPicklistFieldValues',
    bobjectGlobalPicklist: 'bobjectGlobalPicklists',
  },
  idealCustomerProfiles: {
    bobjectPicklistFieldValues: 'bobjectPicklistFieldValues',
  },
  messagingTemplates: {
    user: 'users',
    targetMarkets: 'targetMarkets',
    idealCustomerProfiles: 'idealCustomerProfiles',
    useCases: 'useCases',
  },
  bobjectTypes: {},
  fieldTypes: {},
  bobjectFieldGroups: {},
  bobjectTriggers: {
    bobjectType: 'bobjectTypes',
  },
  nylasUserAccounts: {
    account: 'accounts',
    user: 'users',
  },
  nylasUserAccountAliases: {
    account: 'accounts',
    nylasUserAccount: 'nylasUserAccounts',
  },
  integrations: {
    account: 'accounts',
  },
  integrationSalesforces: {
    account: 'accounts',
  },
  integrationHubspots: {
    account: 'accounts',
  },
  restHooks: {
    bobjectType: 'bobjectTypes',
    user: 'users',
  },
  triggerMappings: {
    account: 'accounts',
  },
  systemMappings: {
    account: 'accounts',
    triggerMapping: 'triggerMappings',
  },
  customMappings: {
    triggerMapping: 'triggerMappings',
    bobjectField: 'bobjectFields',
  },
  standardTriggers: {
    bobjectTypeModel: 'bobjectTypeModels',
  },
  accountBobjectTriggers: {
    standardTrigger: 'standardTriggers',
  },
  hubspotUsers: {
    account: 'accounts',
    user: 'users',
  },
  bobjectTypeModels: {},
  salesforceSubscriptions: {
    account: 'accounts',
    bobjectType: 'bobjectTypes',
    triggerMapping: 'triggerMappings',
  },
  integrationTriggers: {},
  accountIntegrationTriggers: {
    account: 'accounts',
    integrationTrigger: 'integrationTriggers',
  },
  hubspotSubscriptions: {
    account: 'accounts',
    bobjectType: 'bobjectTypes',
    triggerMapping: 'triggerMappings',
  },
};
const resourcesOwnedByAccount = {
  reportingPipelines: 'id',
  users: 'id',
  phoneNumbers: 'uri',
  targetMarkets: 'uri',
  idealCustomerProfiles: 'uri',
  useCases: 'uri',
  bobjectTypes: 'uri',
  bobjectViews: 'uri',
  bobjectViewFilters: 'uri',
  bobjectViewColumns: 'uri',
  tags: 'uri',
  bobjectFields: 'uri',
  systemFields: 'uri',
  customFields: 'uri',
  bobjectFieldGroups: 'uri',
  bobjectPicklistFieldValues: 'uri',
  bobjectGlobalPicklists: 'uri',
  employeeRoles: 'uri',
  messagingTemplates: 'uri',
  bobjectTriggers: 'uri',
  taskTypes: 'uri',
  discardedReasons: 'uri',
  bobjectConditionalFields: 'uri',
  cadences: 'uri',
  cadenceDays: 'uri',
  accountSettings: 'uri',
  bobjectConditionalFieldValues: 'uri',
  bobjectViewFilterValues: 'uri',
  fieldTypes: 'uri',
  integrations: 'uri',
  integrationSalesforces: 'uri',
  restHooks: 'uri',
  triggerMappings: 'uri',
  customMappings: 'uri',
  systemMappings: 'uri',
  salesforceMappingInstalleds: 'uri',
  accountBobjectTriggers: 'uri',
  salesforceUsers: 'uri',
  integrationHubspots: 'uri',
  hubspotMappingInstalleds: 'uri',
  hubspotUsers: 'uri',
  nylasUserAccounts: 'uri',
  nylasUserAccountAliases: 'uri',
  salesforceSubscriptionFieldMappings: 'uri',
  hubspotSubscriptionFieldMappings: 'uri',
  salesforceSubscriptions: 'uri,',
  integrationTriggers: 'uri',
  accountIntegrationTriggers: 'uri',
  hubspotSubscriptions: 'uri',
  integrationSubscriptions: 'uri',
};

const isResourceOwnedByAccount = resourceName =>
  resourcesOwnedByAccount[resourceName] !== undefined;

const flattenObject = ob => {
  const toReturn = {};

  Object.keys(ob).forEach(i => {
    if (typeof ob[i] === 'object') {
      const flatObject = flattenObject(ob[i]);
      Object.keys(flatObject).forEach(x => {
        toReturn[`${i}.${x}`] = flatObject[x];
      });
    } else {
      toReturn[i] = ob[i];
    }
  });
  return toReturn;
};

// SUPPORT FUNCTIONS

const renumberCadenceDays = cadenceDays => {
  return cadenceDays.map(e => {
    e.dayNumber += 1;
    return e;
  });
};

const rewriteUri = (resourceName, entity) => {
  if (entitiyReferenceNames[resourceName] !== undefined) {
    const attributes = entitiyReferenceNames[resourceName];
    Object.keys(attributes).forEach(attribute => {
      const refResourceName = attributes[attribute];
      if (entity[attribute] !== undefined) {
        if (Array.isArray(entity[attribute])) {
          entity[attribute] = entity[attribute].map(id => {
            if (id !== undefined && id != null && !id.startsWith(`/${refResourceName}`)) {
              return `/${refResourceName}/${id}`;
            }
            return id;
          });
        } else if (
          entity[attribute] !== undefined &&
          entity[attribute] !== null &&
          !entity[attribute].startsWith(`/${refResourceName}`)
        ) {
          entity[attribute] = `/${refResourceName}/${entity[attribute]}`;
        }
      }
    });
  }
};

// DISPATCHER FUNCTIONS
export const getOne = (resourceName, id) => {
  return restApi.service(resourceName).get(id);
};

export const getMany = (resourceName, ids) => {
  return restApi
    .service(resourceName)
    .findByIdIn(ids)
    .then(response => {
      const entities = response._embedded[Object.keys(response._embedded)[0]];
      return {
        data: entities,
      };
    });
};

export const getList = (resourceName, sort, pagination, filters) => {
  const params = {};
  params.query = flattenObject(filters || {});
  params.page = pagination.page - 1;
  params.size = pagination.perPage;
  if (
    entitiyReferenceNames[resourceName] &&
    entitiyReferenceNames[resourceName][sort.field] !== undefined
  ) {
    params.sort = [{ direction: sort.order, field: `${sort.field}.id` }];
  } else {
    params.sort = [{ direction: sort.order, field: sort.field }];
  }
  if (isResourceOwnedByAccount(resourceName)) {
    params.query['account.id'] = SessionManager.getAccount().id;
  }
  // search all entities filtered by some query
  // /entity?account.id=asdfa&page=1
  return restApi
    .service(resourceName)
    .search(params)
    .then(response => {
      const entities = response._embedded[Object.keys(response._embedded)[0]];
      return {
        data: entities,
        total: response.page.totalElements,
      };
    });
};

const deleteOne = (resourceName, id) => {
  if (entitiesWithServiceCreators[resourceName]) {
    webApi
      .request({
        url: entitiesWithServiceCreators[resourceName].delete(id),
        method: Commons.HttpMethod.DELETE,
      })
      .then(r => {
        logger.info(`Response ${r.status} of url ${r.url}`);
        return r;
      })
      .then(() => id);
  } else {
    restApi.service(resourceName).delete(id);
  }
};

const deleteMany = (resourceName, ids) =>
  Promise.all(ids.map(id => deleteOne(resourceName, id))).then(i => {
    return { id: i };
  });

const create = (resourceName, entity) => {
  if (isResourceOwnedByAccount(resourceName)) {
    if (resourcesOwnedByAccount[resourceName] === 'uri') {
      entity.account = `${apiUrl}/accounts/${SessionManager.getAccount().id}`;
    } else {
      entity.account = SessionManager.getAccount().id;
    }
  }
  if (resourceName === 'bobjectConditionalFields') {
    entity.requiredBobjectField = `/bobjectFields/${entity.requiredBobjectField}`;
  }
  if (entitiesWithServiceCreators[resourceName]) {
    return webApi
      .request({
        url: entitiesWithServiceCreators[resourceName].create(),
        method: Commons.HttpMethod.POST,
        body: entity,
      })
      .then(d => {
        return getOne(resourceName, d.id);
      })
      .then(d => {
        if (resourceName === 'accounts') {
          SessionManager.setAccount(d.id, d.name);
        }
        return d;
      });
  }
  rewriteUri(resourceName, entity);
  return restApi.service(resourceName).create(entity);
};

const update = (resourceName, pastEntity, newEntity) => {
  rewriteUri(resourceName, newEntity);
  if (isResourceOwnedByAccount(resourceName)) {
    delete newEntity.account;
  }
  if (resourceName === 'users') {
    if (Array.isArray(newEntity.dialerType)) {
      newEntity.dialerType = newEntity.dialerType.map(type => `/dialerTypes/${type}`);
    } else {
      newEntity.dialerType = `/dialerTypes/${newEntity.dialerType}`;
    }
    delete newEntity.userShownHelpers;
  }
  if (resourceName === 'accounts') {
    if (Array.isArray(newEntity.dialerTypes)) {
      newEntity.dialerTypes = newEntity.dialerTypes.map(type => `/dialerTypes/${type}`);
    } else {
      newEntity.dialerTypes = `/dialerTypes/${newEntity.dialerType}`;
    }
  }
  if (resourceName === 'accounts') {
    if (newEntity.churnedDate && newEntity.churnedDate !== '') {
      newEntity.churnedDate = new Date(newEntity.churnedDate).toISOString();
    }
  }
  delete newEntity.id;
  delete newEntity.creationDatetime;
  delete newEntity.updateDatetime;
  delete newEntity.createdBy;
  delete newEntity.updatedBy;
  return restApi.service(resourceName).partialUpdate(pastEntity.id, newEntity);
};

const rewriteToId = uri => {
  if (uri !== undefined && uri !== null && uri.startsWith('/')) {
    return uri.substring(uri.lastIndexOf('/') + 1);
  }
  return uri;
};

export default async (type, resource, params) => {
  const opId = LoggerFactory.newTrace('op');
  logger.info(
    `Requesting resource '${resource}' for operation ${type} and params ${JSON.stringify(params)}`,
    { traceIds: [opId] },
  );
  let response;
  const filters = {};
  if (params.target !== undefined) {
    filters[params.target] = params.id;
  }
  if (params.filters !== undefined) {
    Object.keys(params.filters).forEach(f => {
      filters[f] = params.filters[f];
    });
  }
  if (params.filter !== undefined) {
    Object.keys(params.filter).forEach(f => {
      filters[f] = params.filter[f];
    });
  }
  if (resource === 'systemFields') {
    resource = 'bobjectFields';
    filters.managedBySystem = 1;
    if (type === CREATE) {
      params.data.managedBySystem = 1;
    }
  } else if (resource === 'customFields') {
    resource = 'bobjectFields';
    filters.managedBySystem = 0;
  }
  switch (type) {
    case GET_LIST:
      if (
        resource === 'bobjectFields' &&
        filters.bobjectType === undefined &&
        !SessionManager.getRoleManager().isGlobalAdmin()
      ) {
        filters.bobjectType = { name: 'Company,Lead,Opportunity' };
      }
      response = await getList(resource, params.sort, params.pagination, filters);
      if (resource === 'cadenceDays') {
        response.data = renumberCadenceDays(response.data);
      }
      if (resource === 'userPermissions' && !SessionManager.getRoleManager().isGlobalAdmin()) {
        response.data = response.data.filter(permission => permission.enumName !== 'VIEW_INBOX');
      }
      break;
    case GET_ONE:
      response = { data: await getOne(resource, params.id) };
      if (resource === 'cadenceDays') {
        response.data.dayNumber += 1;
      }
      break;
    case GET_MANY:
      /* if the requested ids is an empty array there is no actual
       need to request it to the backend */
      if (params.ids.length === 0) {
        response = { data: [] };
      } else {
        response = await getMany(resource, params.ids.map(rewriteToId));
      }
      if (resource === 'cadenceDays') {
        response.data = renumberCadenceDays(response.data);
      }
      if (resource === 'userPermissions' && !SessionManager.getRoleManager().isGlobalAdmin()) {
        response.data = response.data.filter(permission => permission.enumName !== 'VIEW_INBOX');
      }
      break;
    case GET_MANY_REFERENCE:
      response = await getList(resource, params.sort, params.pagination, filters);
      if (resource === 'cadenceDays') {
        response.data = renumberCadenceDays(response.data);
      }
      break;
    case CREATE:
      if (resource === 'accounts') {
        params.data.userPassword = sha512(params.data.userPassword);
      }
      if (resource === 'users') {
        params.data.password = sha512(params.data.password);
      }
      if (resource === 'cadenceDays') {
        params.data.dayNumber -= 1;
      }
      if (resource === 'bobjectFields') {
        params.data.reportingName = params.data.name;
      }
      if (resource === 'bobjectViewFilterValues' && params.data.text === undefined) {
        params.data = { ...params.data, text: null };
      }
      response = { data: await create(resource, params.data) };
      break;
    case UPDATE:
      if (resource === 'cadenceDays') {
        params.data.dayNumber -= 1;
      }
      response = { data: await update(resource, params.previousData, params.data) };
      break;
    case DELETE:
      response = { data: { id: await deleteOne(resource, params.id) } };
      break;
    case DELETE_MANY:
      response = { data: await deleteMany(resource, params.ids) };
      break;
    default:
      response = null;
      break;
  }
  if (response === null) {
    throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  logger.info('Response satisfied', { traceIds: [opId] });
  return response;
};
