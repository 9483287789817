import { useEffect, useState } from 'react';
import { getList, getMany } from '../providers/DataProvider';

const SelectedFilterValues = props => {
  const [selectedValues, setSelectedValues] = useState('');
  useEffect(() => {
    const filters = { bobjectViewFilter: props.record.id };
    getList('bobjectViewFilterValues', {}, { perPage: 500 }, filters).then(response => {
      const ids = response.data.map(e => e.bobjectPicklistFieldValue);
      getMany('bobjectPicklistFieldValues', ids).then(r => {
        let text = r.data.map(element => element.value).join(' - ');
        if (text === '') {
          text = 'EMPTY_VALUE';
        }
        setSelectedValues(text);
      });
    });
  }, []);

  return selectedValues;
};

export default SelectedFilterValues;
