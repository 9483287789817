import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  Resource,
  RichTextField,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import RichTextInput from 'ra-input-rich-text';
import Icon from '@material-ui/icons/AssignmentInd';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'employeeRoles';
const resourceLabel = 'Employee Role';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="shortname" />
    </Datagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="shortname" />
        <RichTextField source="description" />
        <TextField source="creationDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <TextInput source="shortname" required />
      <RichTextInput source="description" toolbar={toolbarConfig} />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <TextInput source="shortname" required />
      <RichTextInput source="description" toolbar={toolbarConfig} />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: 'Employee Role', menuGroup: 'Sales Team' }}
    list={list}
    create={create}
    icon={Icon}
    edit={edit}
    show={show}
  />
);

export default resource;
