import React, { useEffect, useState } from 'react';
import {
  Create,
  Resource,
  SimpleForm,
  SelectInput,
  TextField,
  TextInput,
  ReferenceField,
  CreateController,
} from 'react-admin';
import Title from '../../components/Title';
import { parse } from 'query-string';
import { getList, getOne } from '../../providers/DataProvider';

const resourceName = 'bobjectViewFilterValues';
const resourceLabel = 'Filter Values';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

export const CreateBobjectViewFilterValue = props => {
  const [picklistChoices, setPicklistChoices] = useState([]);
  const [fieldTypes, setFieldTypes] = useState({});

  const { bobjectViewFilter } = parse(props.location.search);
  useEffect(() => {
    getOne('bobjectViewFilters', bobjectViewFilter).then(record => {
      getOne('bobjectViews', record.bobjectView).then(view => {
        if (view.type === 'SAL' || view.type === 'MQL') {
          view.type = 'company';
        } else if (view.type === 'LEAD_WITHOUT_QC') {
          view.type = 'lead';
        }
        getList('fieldTypes', {}, { perPage: 500 }, {}).then(r => {
          const types = {};
          r.data
            .filter(
              f =>
                f.enumName === 'PICKLIST' ||
                f.enumName === 'GLOBAL_PICKLIST' ||
                f.enumName === 'TEXT' ||
                f.enumName === 'DATE' ||
                f.enumName === 'DATETIME',
            )
            .forEach(e => {
              types[e.id] = e.enumName;
            });
          setFieldTypes(types);
        });
      });
    });
  }, []);

  useEffect(() => {
    getOne('bobjectViewFilters', bobjectViewFilter).then(element => {
      getOne('bobjectFields', element.bobjectField).then(bobjectField => {
        const fieldtype = fieldTypes[bobjectField.fieldType];
        let filters = {};
        switch (fieldtype) {
          case 'GLOBAL_PICKLIST':
            filters = { bobjectGlobalPicklist: bobjectField.bobjectGlobalPicklist, enabled: true };
            break;
          case 'PICKLIST':
            filters = { bobjectField: bobjectField.id, enabled: true };
            break;
          default:
            filters = {};
        }
        if (filters.enabled) {
          getList('bobjectPicklistFieldValues', {}, { perPage: 500 }, filters).then(response => {
            const choices = response.data.map(e => {
              return { id: e.id, name: e.value };
            });
            choices.push({ id: '__MATCH_EMPTY_ROWS__', name: 'Empty' });
            setPicklistChoices(choices);
          });
        }
      });
    });
  }, [fieldTypes]);

  const redirect = bobjectViewFilter
    ? `/bobjectViewFilters/${bobjectViewFilter}/show/bobjectViewFilterValues`
    : 'show';
  return (
    <CreateController {...props}>
      {controllerProps => {
        return (
          <Create title={resourceTitle} {...props} {...controllerProps}>
            <SimpleForm defaultValue={{ bobjectViewFilter }} redirect={redirect}>
              <ReferenceField source="bobjectViewFilter" reference="bobjectViewFilters" link="show">
                <TextField source="id" />
              </ReferenceField>
              {picklistChoices.length > 0 ? (
                <SelectInput
                  source="bobjectPicklistFieldValue"
                  choices={picklistChoices}
                  optionText="name"
                  optionValue="id"
                  translateChoice={false}
                />
              ) : (
                <TextInput source="text" />
              )}
            </SimpleForm>
          </Create>
        );
      }}
    </CreateController>
  );
};

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Customisation',
      hiddenInMenu: true,
    }}
    create={CreateBobjectViewFilterValue}
  />
);

export default resource;
