import React, { Component } from 'react';
import { connect } from 'react-redux';
import SessionManagerFactory from '../../misc/session';
import MenuItem from '@material-ui/core/MenuItem';

const SessionManager = SessionManagerFactory();

class RestoreUserButton extends Component {
  handleClick = event => {
    event.stopPropagation();
    SessionManager.restoreToRootToken();
    window.location = `#/accounts/${SessionManager.getAccount().id}/show`;
    window.location.reload();
  };

  render() {
    return (
      <MenuItem label="Restore" onClick={this.handleClick} {...this.props}>
        Exit user
      </MenuItem>
    );
  }
}

RestoreUserButton.propTypes = {};

export default connect(
  undefined,
  {},
)(RestoreUserButton);
