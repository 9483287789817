import React from 'react';
import '../../css/App.css';
import { Admin } from 'react-admin';
import DataProvider from './providers/DataProvider';
import authProvider from './providers/AuthProvider';
import LoginPage from './components/LoginPage';
import LogoutButton from './components/LogoutButton';
import AppLayout from './components/AppLayout';
import ProximaNovaSoftBoldOtf from '../../assets/font/proxima-nova-soft-bold.otf';
import ProximaNovaSoftBoldEot from '../../assets/font/proxima-nova-soft-bold-webfont.eot';
import ProximaNovaSoftBoldSvg from '../../assets/font/proxima-nova-soft-bold-webfont.svg';
import ProximaNovaSoftBoldTtf from '../../assets/font/proxima-nova-soft-bold-webfont.ttf';
import ProximaNovaSoftBoldWoff from '../../assets/font/proxima-nova-soft-bold-webfont.woff';
import ProximaNovaSoftMediumOtf from '../../assets/font/proxima-nova-soft-medium.otf';
import ProximaNovaSoftMediumEot from '../../assets/font/proxima-nova-soft-medium-webfont.eot';
import ProximaNovaSoftMediumSvg from '../../assets/font/proxima-nova-soft-medium-webfont.svg';
import ProximaNovaSoftMediumTtf from '../../assets/font/proxima-nova-soft-medium-webfont.ttf';
import ProximaNovaSoftMediumWoff from '../../assets/font/proxima-nova-soft-medium-webfont.woff';
import ProximaNovaSoftRegularOtf from '../../assets/font/proxima-nova-soft-regular.otf';
import ProximaNovaSoftRegularEot from '../../assets/font/proxima-nova-soft-regular-webfont.eot';
import ProximaNovaSoftRegularSvg from '../../assets/font/proxima-nova-soft-regular-webfont.svg';
import ProximaNovaSoftRegularTtf from '../../assets/font/proxima-nova-soft-regular-webfont.ttf';
import ProximaNovaSoftRegularWoff from '../../assets/font/proxima-nova-soft-regular-webfont.woff';
import ProximaNovaSoftSemiboldOtf from '../../assets/font/proxima-nova-soft-semibold.otf';
import ProximaNovaSoftSemiboldEot from '../../assets/font/proxima-nova-soft-semibold-webfont.eot';
import ProximaNovaSoftSemiboldSvg from '../../assets/font/proxima-nova-soft-semibold-webfont.svg';
import ProximaNovaSoftSemiboldTtf from '../../assets/font/proxima-nova-soft-semibold-webfont.ttf';
import ProximaNovaSoftSemiboldWoff from '../../assets/font/proxima-nova-soft-semibold-webfont.woff';
import { withStyles, createMuiTheme } from '@material-ui/core';
import {
  AccountResource,
  SystemFieldResource,
  CustomFieldResource,
  BobjectPicklistFieldValueResource,
  EmployeeRoleResource,
  BobjectFieldTypeResource,
  IdealCustomerProfileResource,
  TargetMarketResource,
  BobjectTypeResource,
  TaskTypeResource,
  UseCaseResource,
  UserResource,
  UserRoleResource,
  UserPermissionResource,
  TagResource,
  BobjectTriggerResource,
  CountryResource,
  MessagingTemplateResource,
  CadenceResource,
  BobjectConditionalFieldResource,
  TimeZoneResource,
  CadenceDayResource,
  BobjectFieldGroupResource,
  CadenceActionTypeResource,
  DiscardedReasonResource,
  PhoneNumberResource,
  BobjectViewResource,
  BobjectGlobalPicklistResource,
  BobjectViewColumnResource,
  AccountSettingResource,
  BobjectViewFilterResource,
  EmailTemplateResource,
  BobjectConditionalFieldValueResource,
  BobjectViewFilterValueResource,
  BobjectFieldResource,
  IntegrationResource,
  SalesforceIntegrationResource,
  BobjectRestHookResource,
  TriggerMappingResource,
  TriggerMappingCustomFieldMapResource,
  TriggerMappingSystemVarNameResource,
  SalesforceUser,
  StandardTrigger,
  AccountTrigger,
  BobjectTypeModelResource,
  NylasUserAccountResource,
  NylasUserAccountAliasResource,
  HubspotIntegration,
  HubspotUser,
  SalesforceStream,
  IntegrationTrigger,
  AccountIntegrationTrigger,
  HubspotSubscription,
  ReportingPipelineResource,
} from './resources';

const mainTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "'ProximaNovaSoft',Roboto",
  },
  overrides: {
    MuiDrawer: {
      docked: {
        height: 'calc(100vh - 50px)',
        overflowY: 'auto',
      },
    },
  },
});

const style = {
  '@global': {
    '@font-face': [
      {
        fontFamily: "'ProximaNovaSoft'",
        src:
          `url('${ProximaNovaSoftBoldEot}') format('embedded-opentype'),` +
          `url('${ProximaNovaSoftBoldOtf}') format('opentype'),` +
          `url('${ProximaNovaSoftBoldWoff}') format('woff'),` +
          `url('${ProximaNovaSoftBoldTtf}')  format('truetype'),` +
          `url('${ProximaNovaSoftBoldSvg}') format('svg')`,
        fontWeight: 'bold',
      },
      {
        fontFamily: "'ProximaNovaSoft'",
        src:
          `url('${ProximaNovaSoftMediumEot}') format('embedded-opentype'),` +
          `url('${ProximaNovaSoftMediumOtf}') format('opentype'),` +
          `url('${ProximaNovaSoftMediumWoff}') format('woff'),` +
          `url('${ProximaNovaSoftMediumTtf}')  format('truetype'),` +
          `url('${ProximaNovaSoftMediumSvg}') format('svg')`,
        fontWeight: '500',
      },
      {
        fontFamily: "'ProximaNovaSoft'",
        src:
          `url('${ProximaNovaSoftRegularEot}') format('embedded-opentype'),` +
          `url('${ProximaNovaSoftRegularOtf}') format('opentype'),` +
          `url('${ProximaNovaSoftRegularWoff}') format('woff'),` +
          `url('${ProximaNovaSoftRegularTtf}')  format('truetype'),` +
          `url('${ProximaNovaSoftRegularSvg}') format('svg')`,
        fontWeight: 'regular',
      },
      {
        fontFamily: "'ProximaNovaSoft'",
        src:
          `url('${ProximaNovaSoftSemiboldEot}') format('embedded-opentype'),` +
          `url('${ProximaNovaSoftSemiboldOtf}') format('opentype'),` +
          `url('${ProximaNovaSoftSemiboldWoff}') format('woff'),` +
          `url('${ProximaNovaSoftSemiboldTtf}')  format('truetype'),` +
          `url('${ProximaNovaSoftSemiboldSvg}') format('svg')`,
        fontWeight: '600',
      },
    ],
  },
  App: {
    fontFamily: "'ProximaNovaSoft' !important",
  },
};

const App = ({ classes }) => {
  return (
    <div className={classes.App}>
      <Admin
        theme={mainTheme}
        dataProvider={DataProvider}
        authProvider={authProvider}
        authClient={authProvider}
        appLayout={AppLayout}
        loginPage={LoginPage}
        logoutButton={LogoutButton}
      >
        {permissions =>
          [
            /* account resources */

            TargetMarketResource(permissions),
            IdealCustomerProfileResource,
            UseCaseResource,
            MessagingTemplateResource,

            CadenceResource,
            CadenceDayResource,

            SystemFieldResource(permissions),
            CustomFieldResource,
            BobjectPicklistFieldValueResource,
            BobjectGlobalPicklistResource(permissions),
            BobjectFieldGroupResource,
            permissions.isGlobalAdmin() ? BobjectTriggerResource : null,
            UserResource(permissions),
            EmployeeRoleResource,
            DiscardedReasonResource,
            TaskTypeResource,
            permissions.isGlobalAdmin()
              ? [
                  PhoneNumberResource,
                  BobjectViewResource,
                  TagResource,
                  ReportingPipelineResource,
                  BobjectViewColumnResource,
                  BobjectViewFilterResource,
                  NylasUserAccountResource,
                  NylasUserAccountAliasResource,
                  AccountSettingResource,
                ]
              : null,

            /* administrative resources */
            permissions.isGlobalAdmin() ? [EmailTemplateResource] : null,
            AccountResource(permissions),
            /* misc hidden resources */
            UserRoleResource,
            UserPermissionResource,
            BobjectFieldTypeResource,
            CadenceActionTypeResource,
            BobjectConditionalFieldResource,
            BobjectTypeResource,
            CountryResource,
            BobjectConditionalFieldValueResource,
            TimeZoneResource,
            BobjectViewFilterValueResource,
            BobjectFieldResource,
            IntegrationResource,
            SalesforceIntegrationResource,
            HubspotIntegration,
            TriggerMappingResource(permissions),
            TriggerMappingCustomFieldMapResource(permissions),
            TriggerMappingSystemVarNameResource(permissions),
            SalesforceUser,
            HubspotUser,
            StandardTrigger,
            AccountTrigger,
            BobjectTypeModelResource,
            SalesforceStream,
            IntegrationTrigger,
            AccountIntegrationTrigger,
            HubspotSubscription,
            permissions.isAccountDev() ? [BobjectRestHookResource] : null,
          ].flat()
        }
      </Admin>
    </div>
  );
};

export default withStyles(style)(App);
