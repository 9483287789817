import React from 'react';
import {
  Edit,
  Resource,
  Show,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Email';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import CodeTextField from '../../components/CodeTextField';
import CodeTextInputWithField from '../../components/CodeTextInput';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'emailTemplates';
const resourceLabel = 'Email Template';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
      <Tab label="content">
        <TextField source="subject" />
        <CodeTextField source="textContent" mode="text" />
        <CodeTextField source="htmlContent" mode="html" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleForm>
      <TextInput source="subject" />
      <CodeTextInputWithField source="textContent" language="text" />
      <CodeTextInputWithField source="htmlContent" language="html" />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Administrative' }}
    icon={Icon}
    list={list}
    create={null}
    edit={edit}
    show={show}
  />
);

export default resource;
