import React from 'react';
import {
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  List,
  Create,
  SelectInput,
  NumberInput,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Build';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';
import { ReloadDataReportingButton } from '../../components/buttons';

const resourceName = 'reportingPipelines';
const resourceLabel = 'Reporting';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="type" />
      <TextField source="dbHostname" />
      <TextField source="dbPort" />
      <TextField source="dbUser" />
      <TextField source="dbDatabase" />
      <TextField source="dbPassword" />
      <TextField source="dbDirectHostname" />
      <TextField source="dbDirectPort" />
      <TextField source="status" />
      <TextField source="schema" />
      <TextField source="user" />
      <TextField source="password" />
      <TextField source="uri" />
      <TextField source="maxStringCharacters" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions hasDelete resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="type" />
      <TextField source="dbHostname" />
      <TextField source="dbPort" />
      <TextField source="dbUser" />
      <TextField source="dbDatabase" />
      <TextField source="dbPassword" />
      <TextField source="dbDirectHostname" />
      <TextField source="dbDirectPort" />
      <TextField source="status" />
      <TextField source="schema" />
      <TextField source="user" />
      <TextField source="password" />
      <TextField source="uri" />
      <TextField source="maxStringCharacters" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
      <ReloadDataReportingButton />
    </SimpleShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <SelectInput
        source="type"
        choices={[
          { id: 'NORMALIZED', name: 'Normalized' },
          { id: 'DENORMALIZED', name: 'Denormalized' },
        ]}
        allowEmpty={false}
      />
      <TextInput source="dbHostname" />
      <TextInput source="dbPort" />
      <TextInput source="dbUser" />
      <TextInput source="dbDatabase" />
      <TextInput source="dbPassword" />
      <TextInput source="dbDirectHostname" />
      <TextInput source="dbDirectPort" />
      <NumberInput source="maxStringCharacters" defaultValue={255} />
    </SimpleForm>
  </Create>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Others' }}
    icon={Icon}
    list={list}
    create={create}
    show={show}
  />
);

export default resource;
