import React from 'react';
import {
  Create,
  Edit,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  Resource,
  RichTextField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import RichTextInput from 'ra-input-rich-text';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';

const resourceName = 'discardedReasons';
const resourceLabel = 'Discarded Reason';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const redirect = (basePath, id, data) => `/taskTypes/${data.taskType}/show/discardedReasons`;

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <ReferenceField label="Task Type" source="taskType" lin="show" reference="taskTypes">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <RichTextField source="description" />
      <NumberField source="ordering" />
      <TextField source="creationDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput label="Task Type" source="taskType" linkType="show" reference="taskTypes">
        <SelectInput source="name" />
      </ReferenceInput>
      <TextInput source="name" required />
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <NumberInput source="ordering" defaultValue={0} step={1} />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect={redirect}>
      <ReferenceField label="Task Type" source="taskType" linkType="show" reference="taskTypes">
        <TextField source="name" />
      </ReferenceField>
      <TextInput source="name" required />
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <NumberInput source="ordering" defaultValue={0} step={1} />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Sales Team',
      hiddenInMenu: true,
    }}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
