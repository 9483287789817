import React from 'react';
import {
  Create,
  Edit,
  Resource,
  Show,
  SimpleForm,
  SimpleShowLayout,
  Filter,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/TouchApp';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'tags';
const resourceLabel = 'Tag';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Value" source="value" alwaysOn />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} filters={<ListFilters />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="value" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="value" required />
    </SimpleForm>
  </Create>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="value" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="value" required />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Business Assets' }}
    list={list}
    icon={Icon}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
