import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';

const AddNewFilterValueButton = props => {
  if (props.record !== undefined) {
    return (
      <Button
        style={{ marginTop: '1em' }}
        to={{
          pathname: '/bobjectViewFilterValues/create',
          state: { record: { bobjectViewFilter: props.record.id } },
        }}
        component={Link}
        variant="raised"
        label="Add a new value"
      >
        <PlaylistAdd />
      </Button>
    );
  }
  return '';
};

export default AddNewFilterValueButton;
