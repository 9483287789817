import React from 'react';
import Button from '@material-ui/core/Button';
import { webApi } from '../../misc/api';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/core/styles';

export const RefreshHubspotUsersButton = () => {
  const fetchHubspotUsers = () => {
    webApi
      .service()
      .request({
        url: '/service/hubspot/users/update',
        method: Commons.HttpMethod.POST,
      })
      .then(res => {
        if (res.code === 200) {
          return res.json();
        }
        return res;
      })
      .then(() => {
        // eslint-disable-next-line no-restricted-globals
        setTimeout(() => location.reload(), 1000);
      });
  };
  const useStyles = makeStyles({
    root: {
      paddingRight: '5px',
      paddingLeft: '10px',
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Button color="primary" size="small" onClick={fetchHubspotUsers}>
        <RefreshIcon className={classes.root} fontSize="small" /> Refresh
      </Button>
    </div>
  );
};
