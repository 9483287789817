import Title from '../../components/Title';
import {
  Create,
  Edit,
  Filter,
  ReferenceField,
  ReferenceInput,
  Resource,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import CallSplit from '@material-ui/icons/CallSplit';
import PostShowActions from '../../components/PostShowActions';
import React from 'react';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'systemMappings';
const resourceLabel = 'System Mapping';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <ReferenceInput
      label="Trigger Mapping"
      source="triggerMapping"
      reference="triggerMappings"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      label="Sync Rule"
      source="syncRule"
      alwaysOn
      choices={[
        { id: 'BLOOBIRDS', name: 'BLOOBIRDS' },
        { id: 'CRM', name: 'CRM' },
        { id: 'BOTH', name: 'BOTH' },
        { id: 'NO_SYNC', name: 'NO_SYNC' },
      ]}
    />
  </Filter>
);

const list = props => {
  const EmptyPage = Empty(resourceLabel);
  return (
    <List title={resourceTitle} filters={<ListFilters />} empty={<EmptyPage />} {...props}>
      <CustomizableDatagrid rowClick="show">
        <TextField source="name" label="Name" />
        <ReferenceField source="triggerMapping" reference="triggerMappings" linkType="show">
          <TextField source="name" label="Trigger Mapping" />
        </ReferenceField>
        <TextField source="replaceWith" />
        <TextField source="syncRule" label="Sync Rule" />

        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </CustomizableDatagrid>
    </List>
  );
};

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required label="name" />
      <ReferenceInput
        label="Trigger Mapping"
        source="triggerMapping"
        reference="triggerMappings"
        required
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="replaceWith" required />
      <SelectInput
        label="Sync Rule"
        source="syncRule"
        alwaysOn
        choices={[
          { id: 'BLOOBIRDS', name: 'BLOOBIRDS' },
          { id: 'CRM', name: 'CRM' },
          { id: 'BOTH', name: 'BOTH' },
          { id: 'NO_SYNC', name: 'NO_SYNC' },
        ]}
      />
    </SimpleForm>
  </Create>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <ReferenceField source="triggerMapping" reference="triggerMappings" linkType="show">
          <TextField source="name" label="Trigger Mapping" />
        </ReferenceField>
        <TextField source="replaceWith" />
        <TextField source="syncRule" label="Sync Rule" />
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        <TextField source="createdBy" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required label="name" />
      <ReferenceInput
        label="Trigger Mapping"
        source="triggerMapping"
        reference="triggerMappings"
        required
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="replaceWith" required />
      <SelectInput
        label="Sync Rule"
        source="syncRule"
        alwaysOn
        choices={[
          { id: 'BLOOBIRDS', name: 'BLOOBIRDS' },
          { id: 'CRM', name: 'CRM' },
          { id: 'BOTH', name: 'BOTH' },
          { id: 'NO_SYNC', name: 'NO_SYNC' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

const resource = () => (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Integrations' }}
    list={list}
    icon={CallSplit}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
