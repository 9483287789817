import React from 'react';
import {
  SelectArrayInput,
  ChipField,
  Create,
  Edit,
  ReferenceInput,
  SimpleShowLayout,
  ReferenceArrayInput,
  ReferenceField,
  NumberInput,
  SelectInput,
  NumberField,
  SingleFieldList,
  Resource,
  RichTextField,
  ReferenceArrayField,
  Show,
  SimpleForm,
  TextField,
  minValue,
  List,
  required,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import RichTextInput from 'ra-input-rich-text';
import Icon from '@material-ui/icons/GpsFixed';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'cadenceDays';
const resourceLabel = 'Cadence Days';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const redirect = (basePath, id, data) => `/cadences/${data.cadence}/show`;

const validateDayNumber = [minValue(1)];
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <NumberField source="dayNumber" />
      <TextField source="automation" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <ReferenceField source="cadence" lin="show" reference="cadences">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="dayNumber" />
      <RichTextField source="description" />
      <TextField source="automation" />
      <ReferenceArrayField
        label="Actions"
        source="cadenceActionTypes"
        reference="cadenceActionTypes"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="creationDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput source="cadence" reference="cadences" allowEmpty={false}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="dayNumber" defaultValue={1} validate={validateDayNumber} step={1} />
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <SelectInput
        source="automation"
        choices={[
          { id: 'DEFAULT', name: 'DEFAULT' },
          { id: 'ENABLED', name: 'ENABLED' },
          { id: 'DISABLED', name: 'DISABLED' },
        ]}
        allowEmpty={false}
      />
      <ReferenceArrayInput
        perPage={100}
        label="Actions"
        source="cadenceActionTypes"
        reference="cadenceActionTypes"
        validate={[required()]}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect={redirect}>
      <ReferenceInput source="cadence" reference="cadences" allowEmpty={false}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="dayNumber" defaultValue={1} validate={validateDayNumber} step={1} />
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <SelectInput
        source="automation"
        choices={[
          { id: 'DEFAULT', name: 'DEFAULT' },
          { id: 'ENABLED', name: 'ENABLED' },
          { id: 'DISABLED', name: 'DISABLED' },
        ]}
        allowEmpty={false}
      />
      <ReferenceArrayInput
        perPage={100}
        label="Actions"
        source="cadenceActionTypes"
        reference="cadenceActionTypes"
        validate={[required()]}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, hiddenInMenu: true }}
    list={list}
    create={create}
    icon={Icon}
    edit={edit}
    show={show}
  />
);

export default resource;
