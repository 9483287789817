import React from 'react';
import {
  Create,
  Datagrid,
  EditController,
  EditView,
  Resource,
  RichTextField,
  ShowController,
  BooleanField,
  SimpleForm,
  ReferenceManyField,
  ShowView,
  FormDataConsumer,
  BooleanInput,
  TabbedShowLayout,
  NumberField,
  Tab,
  FormTab,
  Link,
  TabbedForm,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import RichTextInput from 'ra-input-rich-text';
import Icon from '@material-ui/icons/Assignment';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'taskTypes';
const resourceLabel = 'Task';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const CreateDiscardedReasonButton = ({ record }) => {
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/discardedReasons/create',
        state: { record: { taskType: record.id } },
      }}
    >
      <AddIcon /> Add Value
    </Button>
  );
};

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <BooleanField source="scheduled" />
    </Datagrid>
  </List>
);

const show = props => (
  <ShowController {...props}>
    {controllerProps => (
      <ShowView
        {...props}
        {...controllerProps}
        title={resourceTitle}
        actions={<PostShowActions resourceLabel={resourceLabel} />}
      >
        <TabbedShowLayout>
          <Tab label="summary">
            <TextField source="name" />
            <RichTextField source="description" />
            <TextField source="creationDatetime" />
            {props.permissions != null && props.permissions.isGlobalAdmin() && (
              <TextField source="id" />
            )}
          </Tab>
          <Tab label="customisation">
            <TextField source="defaultIcon" />
            {controllerProps.record && controllerProps.record.logicRole && (
              <TextField source="logicRole" />
            )}
            <BooleanField source="scheduled" />
          </Tab>
          {controllerProps.record && controllerProps.record.scheduled && (
            <Tab label="Scheduling">
              <BooleanField source="scheduleMonday" />
              <BooleanField source="scheduleTuesday" />
              <BooleanField source="scheduleWednesday" />
              <BooleanField source="scheduleThursday" />
              <BooleanField source="scheduleFriday" />
              <BooleanField source="scheduleSunday" />
              <BooleanField source="scheduleSaturday" />
            </Tab>
          )}
          <Tab label="Discarded Reasons" path="discardedReasons">
            <CreateDiscardedReasonButton />
            <ReferenceManyField
              perPage={500}
              reference="discardedReasons"
              target="taskType.id"
              sort={{ field: 'ordering', order: 'DESC' }}
              addLabel={false}
            >
              <Datagrid rowClick="edit">
                <TextField source="name" />
                <NumberField source="ordering" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <TextInput source="defaultIcon" required />
      <RichTextInput source="description" toolbar={toolbarConfig} />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <EditController {...props}>
    {controllerProps => (
      <EditView
        {...props}
        {...controllerProps}
        title={resourceTitle}
        actions={<PostShowActions resourceLabel={resourceLabel} />}
      >
        <TabbedForm redirect="show">
          <FormTab label="summary">
            <TextInput source="name" required />
            <TextInput source="defaultIcon" required />
            <RichTextInput source="description" toolbar={toolbarConfig} />
          </FormTab>
          <FormTab label="Scheduling">
            <BooleanInput source="scheduled" />

            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.scheduled && [
                  <BooleanInput source="scheduleMonday" {...rest} />,
                  <BooleanInput source="scheduleTuesday" {...rest} />,
                  <BooleanInput source="scheduleWednesday" {...rest} />,
                  <BooleanInput source="scheduleThursday" {...rest} />,
                  <BooleanInput source="scheduleFriday" {...rest} />,
                  <BooleanInput source="scheduleSunday" {...rest} />,
                  <BooleanInput source="scheduleSaturday" {...rest} />,
                ]
              }
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </EditView>
    )}
  </EditController>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: 'Task', menuGroup: 'Sales Team' }}
    list={list}
    create={create}
    icon={Icon}
    edit={edit}
    show={show}
  />
);

export default resource;
