import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import AceEditor from 'react-ace';

import 'brace/mode/javascript';
import 'brace/theme/github';

const CodeTextField = ({ source, mode = 'javascript', record = {} }) => {
  const value = get(record, source);

  return (
    <AceEditor
      mode={mode}
      theme="github"
      value={value}
      readOnly
      width={900}
      name="UNIQUE_ID_OF_DIV"
      editorProps={{ $blockScrolling: true }}
    />
  );
};

CodeTextField.propTypes = {
  mode: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const PureCodeTextField = pure(CodeTextField);

PureCodeTextField.defaultProps = {
  addLabel: true,
  stripTags: false,
};

export default PureCodeTextField;
