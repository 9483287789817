import React, { useState } from 'react';
import { Box, Typography, Snackbar, SnackbarContent } from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { CreateButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import { webApi } from '../misc/api';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ErrorIcon from '@material-ui/icons/Error';

export const Empty = resourceTitle => ({ basePath, salesforceUsers, hubspotUsers }) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackType, setSnackType] = useState('success');
  const fetchSalesforceUsers = () => {
    webApi
      .service()
      .request({
        url: '/service/salesforceUsers/getUsers',
        method: Commons.HttpMethod.POST,
      })
      .then(res => {
        if (res.message) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        if (res.message) {
          setSnackMessage(res.message);
          setSnackType('error');
          setOpenSnack(true);
        } else {
          setSnackMessage('Salesforce Users created!');
          setSnackType('success');
          setOpenSnack(true);
          // eslint-disable-next-line no-restricted-globals
          setTimeout(() => location.reload(), 2000);
        }
      });
  };

  const fetchHubspotUsers = () => {
    webApi
      .service()
      .request({
        url: '/service/hubspot/users',
        method: Commons.HttpMethod.POST,
      })
      .then(res => {
        if (res.message) {
          return res.json();
        }
        return res;
      })
      .then(res => {
        if (res.message) {
          setSnackMessage(res.message);
          setSnackType('error');
          setOpenSnack(true);
        } else {
          setSnackMessage('Hubspot Users created!');
          setSnackType('success');
          setOpenSnack(true);
          // eslint-disable-next-line no-restricted-globals
          setTimeout(() => location.reload(), 2000);
        }
      });
  };

  const handleCloseSnack = () => setOpenSnack(false);

  const snackColor = () => {
    switch (snackType) {
      case 'success':
        return '#4caf50';
      case 'error':
        return '#f44336';
      default:
        return '#2196f3';
    }
  };

  const theme = createMuiTheme({
    palette: {
      primary: { 500: '#93a3b8' },
    },
  });

  const CustomSnackbarContent = props => {
    const { message, ...other } = props;
    const Icon = ErrorIcon;
    return (
      <SnackbarContent
        style={{ backgroundColor: snackColor(), alignItems: 'center' }}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" style={{ display: 'flex', alignItems: 'center' }}>
            {snackType === 'error' && (
              <Icon style={{ opacity: '0.9', fontSize: '20', marginRight: '4px' }} />
            )}
            {message}
          </span>
        }
        {...other}
      />
    );
  };

  return (
    <Box textAlign="center" m={10}>
      <MuiThemeProvider theme={theme}>
        <Snackbar
          open={openSnack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={10000}
          onClose={handleCloseSnack}
        >
          <CustomSnackbarContent message={snackMessage} />
        </Snackbar>
      </MuiThemeProvider>
      <Typography variant="h4" paragraph>
        No {resourceTitle}s available
      </Typography>
      {!salesforceUsers && !hubspotUsers && <CreateButton basePath={basePath} />}
      {salesforceUsers !== undefined && (
        <Button variant="contained" onClick={fetchSalesforceUsers}>
          Get users from Salesforce
        </Button>
      )}
      {hubspotUsers !== undefined && (
        <Button variant="contained" onClick={fetchHubspotUsers}>
          Get users from Hubspot
        </Button>
      )}
    </Box>
  );
};
