import React from 'react';

const Title = ({ isEdit = false, resourceLabel, record, style }) => {
  let content = '';
  if (record && record.id !== undefined) {
    if (isEdit) {
      content += 'Edit ';
    }
    content += `${resourceLabel}: `;
    let name = '';
    if (record && record.email !== undefined) {
      name = record.email;
    } else if (record && record.name !== undefined) {
      name = record.name;
    } else if (record && record.value !== undefined) {
      name = record.value;
    } else if (record && record.id !== undefined) {
      name = `#${record.id}`;
    }
    content += name;
  } else {
    content = `${resourceLabel}s`;
  }
  return <div style={style}>{content}</div>;
};

export default Title;
