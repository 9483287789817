import React from 'react';
import {
  Create,
  Edit,
  ReferenceField,
  Show,
  Resource,
  TabbedForm,
  SimpleForm,
  FormTab,
  Tab,
  SelectInput,
  Filter,
  ReferenceInput,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Code';
import CodeTextInput from '../../components/CodeTextInput';
import CodeTextField from '../../components/CodeTextField';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'accountIntegrationTriggers';
const resourceLabel = 'Inbound Integration Triggers';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} {...props} empty={<EmptyPage />} filters={<ListFilters />}>
    <CustomizableDatagrid rowClick="show">
      <ReferenceField
        label="Trigger"
        source="integrationTrigger"
        link={false}
        reference="integrationTriggers"
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="active" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <ReferenceField
          label="Trigger"
          source="integrationTrigger"
          link={false}
          reference="integrationTriggers"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="active" />
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        <TextField source="creationDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
      <Tab label="Config">
        <CodeTextField source="jsonConfig" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        label="Trigger"
        source="integrationTrigger"
        reference="integrationTriggers"
        allowEmpty={false}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" default />
      <CodeTextInput source="jsonConfig" />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedForm redirect="show">
      <FormTab label="summary">
        <ReferenceInput
          label="Trigger"
          source="integrationTrigger"
          reference="integrationTriggers"
          allowEmpty={false}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="active" />
      </FormTab>
      <FormTab label="Config">
        <CodeTextInput source="jsonConfig" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Triggers',
      isOnlyVisibleFor: ['GLOBAL_DEV', 'ACCOUNT_DEV'],
    }}
    icon={Icon}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
