import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles, Snackbar, SnackbarContent } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { push as pushAction } from 'react-router-redux';
import { connect } from 'react-redux';
import Image from '../../../assets/logo@3x.png';
import { MuiThemeProvider } from '@material-ui/core/styles';
import authProvider from '../providers/AuthProvider';

const styles = () => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '460px',
    height: '554px',
    boxShadow:
      // eslint-disable-next-line max-len
      '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#fafafa',
  },
  submit: {},
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
  },
  Logo: {
    height: '50px',
    margin: '80px 120px 80px 120px ',
  },
  Signing: {
    width: '300px',
    height: '24px',
    fontFamily: 'ProximaNovaSoft',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#94a5b4',
    marginBottom: '48px',
  },
  outlinedField: {
    width: '300px',
    marginLeft: '80px',
    margiRight: '80px',
    marginBottom: '33px',
    backgroundColor: '#edf1f5',
    fontFamily: 'ProximaNovaSoft',
  },
  button: {
    width: '300px',
    height: '40px',
    borderRadius: '6px',
    backgroundColor: '#1991ff',
    marginTop: '6px',
    marginLeft: '80px',
    margiRight: '80px',
  },
});

const ErrorSnackbarContent = props => {
  const { message, ...other } = props;
  const Icon = ErrorIcon;
  return (
    <SnackbarContent
      style={{ background: 'red', alignItems: 'center' }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" style={{ display: 'flex', alignItems: 'center' }}>
          <Icon style={{ opacity: '0.9', fontSize: '20', marginRight: '4px' }} />
          {message}
        </span>
      }
      {...other}
    />
  );
};

const LoginForm = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(true);
  const { classes, push, location } = props;
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <MuiThemeProvider theme={props.theme}>
      {error && (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <ErrorSnackbarContent
            message={'You do not have enough permissions to access the admin'}
          />
        </Snackbar>
      )}
      <div className={classes.container}>
        <div className={classes.paper}>
          <img src={Image} className={classes.Logo} alt="" />
          <Typography className={classes.Signing}>
            {' '}
            Signing into Bloobirds admin environment
          </Typography>
          <form
            onSubmit={e => {
              e.preventDefault();
              const credentials = { username, password };
              authProvider
                .login(credentials)
                .then(() => push(location.state ? location.state.nextPathname : '/'))
                .catch(e => {
                  console.error(e);
                  setError(true);
                  setOpen(true);
                });
            }}
          >
            <TextField
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              variant="outlined"
              onChange={e => setUsername(e.target.value)}
              className={classes.outlinedField}
            />
            <TextField
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              variant="outlined"
              onChange={e => setPassword(e.target.value)}
              className={classes.outlinedField}
            />
            <Button type="submit" variant="contained" color="primary" className={classes.button}>
              Sign In
            </Button>
          </form>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default connect(
  undefined,
  { push: pushAction },
)(withStyles(styles)(LoginForm));
