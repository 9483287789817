import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  Resource,
  ReferenceManyField,
  Show,
  SimpleForm,
  BooleanField,
  BooleanInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SimpleShowLayout,
  NumberField,
  Link,
  TextField,
  TextInput,
  List,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  ReferenceField,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Timeline';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'cadences';
const resourceLabel = 'Cadence';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const CreateDayButton = ({ record }) => {
  const params = {
    cadence: record.id,
  };
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/cadenceDays/create',
        state: { record: params },
      }}
    >
      <AddIcon /> Add Day
    </Button>
  );
};
const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <BooleanField source="defaultCadence" />
      <ReferenceField label="Object" source="bobjectType" reference="bobjectTypes" link={false}>
        <TextField source="name" label="BobjectType" />
      </ReferenceField>
      <TextField source="creationDatetime" />
      <TextField source="lastEntityUpdate" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const create = props => (
  <Create
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect="show">
      <TextInput source="name" required />
    </SimpleForm>
  </Create>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="name" required />
      <ReferenceManyField
        perPage={500}
        reference="cadenceDays"
        target="cadence.id"
        sort={{ field: 'dayNumber', order: 'ASC' }}
        label="Cadence Days"
      >
        <Datagrid rowClick="edit">
          <NumberField source="dayNumber" />
          <ReferenceArrayField
            label="Actions"
            source="cadenceActionTypes"
            reference="cadenceActionTypes"
          >
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField source="automation" />
        </Datagrid>
      </ReferenceManyField>
      <CreateDayButton />
      <TextField source="firstDayDescription" label="First Attempt description" />
      <ReferenceArrayField
        label="First Attempt Touch Type"
        source="firstAttemptActionTypes"
        reference="cadenceActionTypes"
      >
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField label="Object" source="bobjectType" reference="bobjectTypes" link={false}>
        <TextField source="name" label="BobjectType" />
      </ReferenceField>
      <BooleanField source="defaultCadence" />
      <BooleanField source="automated" />
      <BooleanField source="includesMonday" />
      <BooleanField source="includesTuesday" />
      <BooleanField source="includesWednesday" />
      <BooleanField source="includesThursday" />
      <BooleanField source="includesFriday" />
      <BooleanField source="includesSaturday" />
      <BooleanField source="includesSunday" />
      <TextField source="creationDatetime" />
      <TextField source="lastEntityUpdate" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <BooleanInput source="defaultCadence" />
      <TextInput source="firstDayDescription" label="First Attempt description" />
      <ReferenceArrayInput
        perPage={100}
        label="First Attempt Touch Type"
        source="firstAttemptActionTypes"
        reference="cadenceActionTypes"
        validate={[required()]}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <BooleanInput source="automated" />
      <BooleanInput source="includesMonday" />
      <BooleanInput source="includesTuesday" />
      <BooleanInput source="includesWednesday" />
      <BooleanInput source="includesThursday" />
      <BooleanInput source="includesFriday" />
      <BooleanInput source="includesSaturday" />
      <BooleanInput source="includesSunday" />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Business Assets',
      hiddenInMenu: false,
    }}
    icon={Icon}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
