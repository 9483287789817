import Title from '../../components/Title';
import {
  AutocompleteInput,
  Create,
  Edit,
  Filter,
  ReferenceField,
  ReferenceInput,
  Resource,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import CompareArrows from '@material-ui/icons/CompareArrows';
import PostShowActions from '../../components/PostShowActions';
import React, { useEffect, useState } from 'react';
import { getList } from '../../providers/DataProvider';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'customMappings';
const resourceLabel = 'Custom Mapping';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <ReferenceInput
      label="Trigger Mapping"
      source="triggerMapping"
      reference="triggerMappings"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      label="Sync Rule"
      source="syncRule"
      alwaysOn
      choices={[
        { id: 'BLOOBIRDS', name: 'BLOOBIRDS' },
        { id: 'CRM', name: 'CRM' },
        { id: 'BOTH', name: 'BOTH' },
        { id: 'NO_SYNC', name: 'NO_SYNC' },
      ]}
    />
  </Filter>
);

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} filters={<ListFilters />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="keyName" label="Key Name" />
      <ReferenceField source="triggerMapping" reference="triggerMappings" lin="show">
        <TextField source="name" label="Trigger Mapping" />
      </ReferenceField>
      <ReferenceField
        source="bobjectField"
        reference="customFields"
        label="Custom Field"
        lin="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="syncRule" label="Sync Rule" />

      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const create = props => {
  const [bobjecTypes, setBobjectTypes] = useState({});

  useEffect(() => {
    getList('bobjectTypes', {}, { page: 0, perPage: 25 }).then(res => {
      const types = {};
      res.data.forEach(type => {
        types[type.id] = type.name;
      });
      setBobjectTypes(types);
    });
  }, []);
  const optionRenderer = choice => `${bobjecTypes[choice.bobjectType]}: ${choice.name}`;
  return (
    <Create title={resourceTitle} {...props}>
      <SimpleForm redirect="show">
        <TextInput source="keyName" required label="Key Name" />
        <ReferenceInput
          label="Trigger Mapping"
          source="triggerMapping"
          reference="triggerMappings"
          required
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={500}
          sort={{ field: 'bobjectType', order: 'DESC' }}
          label="Custom Field"
          source="bobjectField"
          reference="bobjectFields"
        >
          <AutocompleteInput
            translateChoice={false}
            optionText={optionRenderer}
            optionValue="id"
            source="bobjectField"
            reference="bobjectFields"
          />
        </ReferenceInput>
        <SelectInput
          label="Sync Rule"
          source="syncRule"
          alwaysOn
          choices={[
            { id: 'BLOOBIRDS', name: 'BLOOBIRDS' },
            { id: 'CRM', name: 'CRM' },
            { id: 'BOTH', name: 'BOTH' },
            { id: 'NO_SYNC', name: 'NO_SYNC' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};
const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="keyName" />
        <ReferenceField source="triggerMapping" reference="triggerMappings" lin="show">
          <TextField source="name" label="Trigger Mapping" />
        </ReferenceField>
        <ReferenceField source="bobjectField" reference="bobjectFields" lin="show">
          <TextField source="name" label="Bobject Field" />
        </ReferenceField>
        <TextField source="syncRule" label="Sync Rule" />
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        <TextField source="createdBy" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => {
  const [bobjecTypes, setBobjectTypes] = useState({});

  useEffect(() => {
    getList('bobjectTypes', {}, { page: 0, perPage: 25 }).then(res => {
      const types = {};
      res.data.forEach(type => {
        types[type.id] = type.name;
      });
      setBobjectTypes(types);
    });
  }, []);
  const optionRenderer = choice => `${bobjecTypes[choice.bobjectType]}: ${choice.name}`;
  return (
    <Edit title={resourceTitle} {...props}>
      <SimpleForm redirect="show">
        <TextInput source="keyName" required label="Key Name" />
        <ReferenceInput
          label="Trigger Mapping"
          source="triggerMapping"
          reference="triggerMappings"
          required
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          perPage={1000}
          defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
          label="Custom Field"
          filter={{
            'bobjectField.managedBySystem': false,
          }}
          source="bobjectField"
          reference="bobjectFields"
        >
          <AutocompleteInput
            translateChoice={false}
            source="bobjectField"
            resource="bobjectFields"
            optionValue="id"
            optionText={optionRenderer}
            filter={{
              'bobjectField.managedBySystem': false,
            }}
          />
        </ReferenceInput>
        <SelectInput
          label="Sync Rule"
          source="syncRule"
          alwaysOn
          choices={[
            { id: 'BLOOBIRDS', name: 'BLOOBIRDS' },
            { id: 'CRM', name: 'CRM' },
            { id: 'BOTH', name: 'BOTH' },
            { id: 'NO_SYNC', name: 'NO_SYNC' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

const resource = () => (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Integrations' }}
    list={list}
    icon={CompareArrows}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
