import React from 'react';
import {
  Create,
  Edit,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  Resource,
  SelectInput,
  ShowController,
  ShowView,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'bobjectViewColumns';
const resourceLabel = 'View Column';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const redirect = (basePath, id, data) => `/bobjectViews/${data.bobjectView}/show/columns`;
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <ReferenceField label="Field" source="bobjectField" link="show" reference="bobjectFields">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="View" source="bobjectView" link="show" reference="bobjectViews">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="ordering" />

      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <ShowController {...props}>
    {controllerProps => (
      <ShowView
        {...props}
        {...controllerProps}
        title={resourceTitle}
        actions={<PostShowActions resourceLabel={resourceLabel} />}
      >
        <SimpleShowLayout>
          <ReferenceField label="Field" source="bobjectField" link="show" reference="bobjectFields">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField label="View" source="bobjectView" link="show" reference="bobjectViews">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="ordering" />
          <TextField source="creationDatetime" />
          {props.permissions != null && props.permissions.isGlobalAdmin() && (
            <TextField source="id" />
          )}
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput
        label="View"
        source="bobjectView"
        link="show"
        reference="bobjectViews"
        allowEmpty={false}
      >
        <SelectInput source="name" />
      </ReferenceInput>
      <TextInput source="bobjectField" allowEmpty={false} />
      <NumberInput source="ordering" allowEmpty={false} />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect={redirect}>
      <ReferenceInput label="View" source="bobjectView" link="show" reference="bobjectViews">
        <SelectInput source="name" />
      </ReferenceInput>
      <TextInput source="bobjectField" />
      <NumberInput source="ordering" />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Customisation',
      hiddenInMenu: true,
    }}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
