import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  Resource,
  Show,
  SimpleShowLayout,
  SelectInput,
  Create,
  FormDataConsumer,
  SimpleForm,
  ReferenceInput,
  TextField,
  ReferenceField,
  List,
  AutocompleteInput,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import { Empty } from '../../components/EmptyPage';
import { getList, getOne } from '../../providers/DataProvider';

const resourceName = 'bobjectConditionalFieldValues';
const resourceLabel = 'Conditional Field Value';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <Datagrid rowClick="show">
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </Datagrid>
  </List>
);

const create = props => {
  const [bobjectTypes, setBobjectTypes] = useState({});

  useEffect(() => {
    getList('bobjectTypes', {}, { page: 0, perPage: 25 }).then(res => {
      const types = {};
      res.data.forEach(type => {
        types[type.id] = type.name;
      });
      setBobjectTypes(types);
    });
  }, []);
  const optionRenderer = choice => `${bobjectTypes[choice.bobjectType]}: ${choice.name}`;
  return (
    <Create
      title={resourceTitle}
      actions={<PostShowActions resourceLabel={resourceLabel} />}
      {...props}
    >
      <SimpleForm redirect="show">
        <ReferenceField
          label="Field"
          source="bobjectField"
          linkType="show"
          reference="bobjectFields"
        >
          <TextField source="name" />
        </ReferenceField>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.bobjectField === undefined) {
              return null;
            }
            const filter = {};
            if (formData.bobjectGlobalPicklist) {
              filter['bobjectGlobalPicklist.id'] = formData.bobjectGlobalPicklist;
            } else {
              filter['bobjectField.id'] = formData.bobjectField;
            }
            return (
              <ReferenceInput
                label="Field Value"
                source="bobjectPicklistFieldValue"
                reference="bobjectPicklistFieldValues"
                filter={filter}
                perPage={500}
                sort={{ field: 'value' }}
              >
                <SelectInput optionText="value" />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {() => (
            <ReferenceInput
              label="Required Field"
              source="requiredBobjectField"
              reference="bobjectFields"
              filter={{
                'fieldType.name': 'Picklist',
              }}
              perPage={500}
              sort={{ field: 'name' }}
            >
              <AutocompleteInput optionText={optionRenderer} />
            </ReferenceInput>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => {
            if (formData.requiredBobjectField === undefined) {
              return null;
            }
            const filter = {};
            const [requiredBobjectGlobalPicklist, setRequiredBobjectGlobalPicklist] = useState(
              undefined,
            );
            useEffect(() => {
              if (formData.requiredBobjectField) {
                getOne('bobjectFields', formData.requiredBobjectField).then(res => {
                  setRequiredBobjectGlobalPicklist(res.bobjectGlobalPicklist);
                });
              }
            });
            if (requiredBobjectGlobalPicklist) {
              filter['bobjectGlobalPicklist.id'] = requiredBobjectGlobalPicklist;
            } else {
              filter['bobjectField.id'] = formData.requiredBobjectField;
            }
            return (
              <ReferenceInput
                label="Required Field Value"
                source="requiredBobjectPicklistFieldValue"
                reference="bobjectPicklistFieldValues"
                filter={filter}
                perPage={500}
                sort={{ field: 'value' }}
              >
                <SelectInput optionText="value" />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const show = props => (
  <Show title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Field" source="bobjectField" linkType="show" reference="bobjectFields">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Field Value"
        source="bobjectPicklistFieldValue"
        linkType="show"
        reference="bobjectPicklistFieldValues"
      >
        <TextField source="value" />
      </ReferenceField>
      <ReferenceField
        label="Required Field"
        source="requiredBobjectField"
        linkType="show"
        reference="bobjectFields"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Required Field Value"
        source="requiredBobjectPicklistFieldValue"
        linkType="show"
        reference="bobjectPicklistFieldValues"
      >
        <TextField source="valueWithField" />
      </ReferenceField>

      <TextField source="defaultValue" />
      <TextField source="creationDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const resource = (
  <Resource
    name={resourceName}
    create={create}
    list={list}
    show={show}
    options={{ label: resourceLabel, hiddenInMenu: true }}
  />
);

export default resource;
