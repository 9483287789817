/* eslint max-len: 0 */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const HubspotIcon = props => {
  return (
    <SvgIcon
      clip-rule="evenodd"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="1.41421"
      viewBox="0 0 560 400"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fontSize: '60px' }}
      {...props}
    >
      <g fillRule="nonzero" transform="matrix(3.39623 0 0 3.39623 100 148.965)">
        <path
          d="m11.48 4.974v8.475h-7.786v-8.475h-3.694v20.231h3.695v-8.2h7.784v8.2h3.696v-20.231zm15.522 13.692c0 1.677-1.38 3.058-3.057 3.058-1.678 0-3.058-1.381-3.058-3.058v-8.666h-3.505v8.666c0 3.597 2.959 6.556 6.556 6.556s6.556-2.959 6.556-6.556v-8.666h-3.492zm25.971-7.773c0-1.777 1.175-2.34 2.462-2.34 1.038 0 2.408.79 3.305 1.748l2.295-2.706c-1.146-1.55-3.47-2.621-5.373-2.621-3.803 0-6.553 2.227-6.553 5.919 0 6.85 8.372 4.676 8.372 8.51 0 1.182-1.148 2.226-2.462 2.226-2.073 0-2.745-1.013-3.697-2.085l-2.548 2.649c1.63 2 3.64 3.016 6.047 3.016 3.613 0 6.519-2.254 6.519-5.778 0-7.604-8.373-5.241-8.373-8.538m51.8 11.08c-2.071 0-2.66-.896-2.66-2.268v-6.075h3.22v-3.078h-3.22v-4.061l-3.554 1.595v12.374c0 3.165 2.184 4.76 5.178 4.76.47.008.94-.03 1.402-.112l.867-3.192c-.391.027-.84.054-1.233.054m-64.423-11.869c-1.736 0-2.948.504-4.12 1.653v-6.659h-3.51v12.343c0 4.62 3.34 7.786 7.094 7.786 4.164 0 7.827-3.222 7.827-7.56 0-4.284-3.371-7.56-7.291-7.56m-.022 11.587c-2.185 0-3.984-1.798-3.984-3.983 0-2.186 1.799-3.984 3.984-3.984s3.983 1.798 3.983 3.984c0 2.185-1.798 3.983-3.983 3.983m38.142-4.211c0-4.346-3.655-7.56-7.827-7.56-3.753 0-7.094 3.165-7.094 7.786v12.35h3.51v-6.668c1.17 1.147 2.384 1.653 4.118 1.653 3.92 0 7.292-3.276 7.292-7.561m-3.323-.044c0 2.185-1.798 3.984-3.983 3.984-2.186 0-3.984-1.799-3.984-3.984s1.798-3.983 3.984-3.983c2.185 0 3.983 1.798 3.983 3.983"
          fill="#33475b"
        />
        <path
          d="m89.806 9.75v-3.527c.95-.444 1.561-1.4 1.566-2.448v-.081c0-1.5-1.216-2.716-2.716-2.716h-.081c-1.49 0-2.716 1.226-2.716 2.716v.081c.005 1.048.616 2.004 1.566 2.448v3.527c-1.339.205-2.601.761-3.657 1.61l-9.673-7.534c.069-.249.105-.505.109-.762 0-.002 0-.004 0-.006 0-1.679-1.381-3.06-3.06-3.06s-3.06 1.381-3.06 3.06c0 1.677 1.379 3.058 3.056 3.06.53-.002 1.049-.144 1.507-.41l9.525 7.412c-1.747 2.64-1.7 6.099.118 8.69l-2.897 2.898c-.234-.075-.478-.115-.724-.118-1.378.001-2.511 1.135-2.511 2.513 0 1.379 1.134 2.513 2.513 2.513 1.378 0 2.512-1.133 2.513-2.511-.003-.246-.043-.49-.118-.724l2.866-2.867c1.351 1.04 3.009 1.604 4.714 1.604 4.24 0 7.728-3.488 7.728-7.728 0-3.803-2.808-7.069-6.568-7.64m-1.188 11.6c-.036.001-.073.002-.109.002-2.175 0-3.965-1.79-3.965-3.965 0-2.176 1.79-3.965 3.965-3.965.036 0 .073 0 .109.001 2.121.074 3.826 1.84 3.826 3.963 0 2.121-1.702 3.886-3.822 3.962"
          fill="#ff7a59"
        />
      </g>
    </SvgIcon>
  );
};

export default HubspotIcon;
