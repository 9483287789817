import Title from '../../components/Title';
import {
  Create,
  Edit,
  Filter,
  Resource,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
  CreateButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import PeopleIcon from '@material-ui/icons/People';
import PostShowActions from '../../components/PostShowActions';
import React from 'react';
import { Empty } from '../../components/EmptyPage';
import { RefreshHubspotUsersButton } from '../../components/buttons/RefreshHubspotUsersButton';
import { makeStyles } from '@material-ui/core/styles';

const resourceName = 'hubspotUsers';
const resourceLabel = 'Hubspot User';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginTop: '20px',
  },
});
const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Hubspot Email" source="email" alwaysOn />
  </Filter>
);

const ListActions = ({ basePath }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CreateButton basePath={basePath} />
      <RefreshHubspotUsersButton />
    </div>
  );
};

const list = props => {
  return (
    <List
      title={resourceTitle}
      empty={<EmptyPage hubspotUsers />}
      filters={<ListFilters />}
      actions={<ListActions />}
      {...props}
    >
      <CustomizableDatagrid rowClick="show">
        <TextField label="Hubspot Email" source="email" />
        <TextField label="First Name" source="firstName" />
        <TextField label="Last Name" source="lastName" />
        <TextField label="Hubspot Id" source="hubspotId" />
        <TextField label="Hubspot User Id" source="hubspotUserId" />
        <ReferenceField label="Bloobirds User" source="user" reference="users" link={false}>
          <TextField source="name" />
        </ReferenceField>
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </CustomizableDatagrid>
    </List>
  );
};

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput label="Hubspot Email" source="email" />
      <TextInput label="First Name" source="firstName" />
      <TextInput label="Last Name" source="lastName" />
      <TextInput label="Hubspot Id" source="hubspotId" />
      <TextInput label="Hubspot User Id" source="hubspotUserId" />
      <ReferenceInput
        label="Bloobirds User"
        source="user"
        reference="users"
        link={false}
        allowEmpty
      >
        <SelectInput optionText="name" allowEmpty />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField label="Hubspot Email" source="email" />
        <TextField label="First Name" source="firstName" />
        <TextField label="Last Name" source="lastName" />
        <TextField label="Hubspot Id" source="hubspotId" />
        <TextField label="Hubspot User Id" source="hubspotUserId" />
        <ReferenceField label="Bloobirds User" source="user" reference="users" link={false}>
          <TextField source="name" />
        </ReferenceField>
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => (
  <Edit title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput label="Hubspot Email" source="email" />
      <TextInput label="First Name" source="firstName" />
      <TextInput label="Last Name" source="lastName" />
      <TextInput label="Hubspot Id" source="hubspotId" />
      <TextInput label="Hubspot User Id" source="hubspotUserId" />
      <ReferenceInput label="Bloobirds User" source="user" reference="users" allowEmpty>
        <SelectInput optionText="name" allowEmpty />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Hubspot' }}
    list={list}
    icon={PeopleIcon}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
