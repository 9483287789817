import { connect } from 'react-redux';
import React from 'react';
import { showNotification } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Button, Toolbar } from '@material-ui/core/';
import SessionManagerFactory from '../misc/session/index';
import UpdateIcon from '@material-ui/icons/Update';
import { webApi } from '../misc/api/index';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';
import { sha512 } from 'js-sha512';

const styles = {
  form: {
    display: 'flex',
    alignItems: 'center',
    width: '400px',
    justifyContent: 'space-between',
  },
  button: {
    backgroundColor: 'red',
    color: 'white',
  },
};

const SessionManager = SessionManagerFactory();

const onSubmitNewPassword = (e, _password, showNotification) => {
  e.preventDefault();
  const id = SessionManager.getUser().id;

  webApi
    .service()
    .request({
      method: Commons.HttpMethod.PATCH,
      url: `/service/users/${id}/password`,
      body: { password: sha512(_password) },
    })
    .then(() => {
      showNotification('Password Updated');
    });
};

const ChangePassword = ({ classes, showNotification }) => {
  let inputValue = React.createRef();
  return (
    <Toolbar>
      <form
        className={classes.form}
        onSubmit={e => {
          onSubmitNewPassword(e, inputValue.value, showNotification);
        }}
      >
        <input
          type="password"
          name="password"
          ref={value => {
            inputValue = value;
          }}
          required
        />
        <Button variant="contained" className={classes.button} type={'submit'}>
          <UpdateIcon />
          Update Password
        </Button>
      </form>
    </Toolbar>
  );
};

export default connect(
  null,
  { showNotification },
)(withStyles(styles)(ChangePassword));
