import React, { useEffect, useState } from 'react';
import {
  Create,
  CreateController,
  Datagrid,
  DeleteButton,
  Edit,
  EditController,
  FunctionField,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  Resource,
  SelectInput,
  ShowController,
  ShowView,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { getList, getOne } from '../../providers/DataProvider';
import AddNewFilterValueButton from '../../components/buttons/AddNewFilterValueButton';
import { parse } from 'query-string';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'bobjectViewFilters';
const resourceLabel = 'Filter Field';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const redirect = (basePath, id, data) => `/bobjectViews/${data.bobjectView}/show/filters`;
const redirect2 = (basePath, id) => `/bobjectViewFilters/${id}/show`;

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <ReferenceField label="Field" source="bobjectField" linkType="show" reference="bobjectFields">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="View" source="bobjectView" linkType="show" reference="bobjectViews">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="ordering" />

      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const ShowPicklistValue = props => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (props.record !== undefined) {
      getOne('bobjectPicklistFieldValues', props.record.bobjectPicklistFieldValue).then(r => {
        if (r.value === undefined) {
          setName('EMPTY_VALUE');
        } else {
          setName(r.value);
        }
      });
    }
  }, [props.record]);
  return name;
};

const ShowDateFieldValue = props => {
  const dates = props.record.text.split(',');
  return `${dates[0]} - ${dates[dates.length - 1]}`;
};

const ShowTextFieldValue = props => {
  if (props.record.text === null) {
    return 'EMPTY_VALUE';
  }
  return props.record.text;
};

const show = props => {
  const [isPicklist, setIsPicklist] = useState(false);
  const [isDateField, setIsDateField] = useState(false);
  const [isTextField, setIsTextField] = useState(false);
  const [fieldTypes, setFieldTypes] = useState({});
  useEffect(() => {
    getList('fieldTypes', {}, { perPage: 500 }, {}).then(r => {
      const types = {};
      r.data
        .filter(
          f =>
            f.enumName === 'PICKLIST' ||
            f.enumName === 'GLOBAL_PICKLIST' ||
            f.enumName === 'TEXT' ||
            f.enumName === 'DATE' ||
            f.enumName === 'REFERENCE' ||
            f.enumName === 'DATETIME',
        )
        .forEach(e => {
          types[e.id] = e.enumName;
        });
      setFieldTypes(types);
    });
  }, []);

  useEffect(() => {
    getOne('bobjectViewFilters', props.id).then(r => {
      getOne('bobjectFields', r.bobjectField).then(answer => {
        if (Object.keys(fieldTypes).length > 0) {
          setIsPicklist(
            fieldTypes[answer.fieldType] === 'PICKLIST' ||
              fieldTypes[answer.fieldType] === 'GLOBAL_PICKLIST',
          );
          setIsDateField(
            fieldTypes[answer.fieldType] === 'DATE' || fieldTypes[answer.fieldType] === 'DATETIME',
          );
          setIsTextField(
            fieldTypes[answer.fieldType] === 'TEXT' || fieldTypes[answer.fieldType] === 'REFERENCE',
          );
        }
      });
    });
  }, [fieldTypes]);

  const redirectAfterDelete = `/bobjectViewFilters/${props.id}/show/bobjectViewFilterValues`;
  return (
    <ShowController {...props}>
      {controllerProps => (
        <ShowView
          {...props}
          {...controllerProps}
          title={resourceTitle}
          actions={<PostShowActions resourceLabel={resourceLabel} />}
        >
          <TabbedShowLayout>
            <Tab label="summary">
              <ReferenceField
                label="Field"
                source="bobjectField"
                linkType="show"
                reference="bobjectFields"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="View"
                source="bobjectView"
                linkType="show"
                reference="bobjectViews"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="bobjectFieldValue" />
              <NumberField source="ordering" />
              <TextField source="creationDatetime" />
              {props.permissions != null && props.permissions.isGlobalAdmin() && (
                <TextField source="id" />
              )}
            </Tab>
            <Tab label="Filter Values" path="bobjectViewFilterValues">
              <ReferenceManyField
                perPage={20}
                reference="bobjectViewFilterValues"
                target="bobjectViewFilter"
              >
                <Datagrid>
                  <TextField source="id" />
                  {isPicklist && (
                    <FunctionField
                      label="Bobject picklist field value"
                      render={record => <ShowPicklistValue record={record} />}
                    />
                  )}
                  {isDateField && (
                    <FunctionField
                      label="Bobject picklist field value"
                      render={record => <ShowDateFieldValue record={record} />}
                    />
                  )}
                  {!isPicklist && !isDateField && isTextField && (
                    <FunctionField
                      label="Bobject picklist field value"
                      render={record => <ShowTextFieldValue record={record} />}
                    />
                  )}
                  <DeleteButton redirect={redirectAfterDelete} undoable={false} />
                </Datagrid>
              </ReferenceManyField>
              <AddNewFilterValueButton />
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

const create = props => {
  const [fieldChoices, setFieldChoices] = useState([]);
  let fieldTypes = {};
  const fieldTypesIds = [];
  let bobjectType = '';
  const { bobjectView } = parse(props.location.search);
  useEffect(() => {
    getOne('bobjectViews', bobjectView).then(view => {
      if (view.type === 'SAL' || view.type === 'MQL' || view.type === 'LEAD_WITHOUT_QC') {
        view.type = 'lead';
      }
      bobjectType = view.type.toLowerCase().replace(/^\w/, chr => {
        return chr.toUpperCase();
      });
      getList('fieldTypes', {}, { perPage: 500 }, {}).then(r => {
        const types = {};
        r.data
          .filter(
            f =>
              f.enumName === 'PICKLIST' ||
              f.enumName === 'GLOBAL_PICKLIST' ||
              f.enumName === 'TEXT' ||
              f.enumName === 'DATE' ||
              f.enumName === 'DATETIME' ||
              f.enumName === 'REFERENCE',
          )
          .forEach(e => {
            types[e.id] = e.enumName;
            fieldTypesIds.push(e.id);
          });
        fieldTypes = types;

        getList('bobjectTypes', {}, { perPage: 500 }, { name: bobjectType }).then(answ => {
          const filters = { enabled: true };
          if (answ.data.length) {
            filters.bobjectType = answ.data[0].id;
          }
          getList('bobjectFields', {}, { perPage: 500 }, filters).then(response => {
            const choices = response.data
              .filter(e => fieldTypesIds.includes(e.fieldType))
              .map(e => {
                return { id: e.id, name: e.name, type: fieldTypes[e.fieldType] };
              });
            setFieldChoices(choices);
          });
        });
      });
    });
  }, []);

  const OptionRenderer = props => {
    const choice = props.record;
    return choice !== undefined ? (
      <span>
        <span style={{ fontWeight: '500' }}>{choice.name}</span>
        <span style={{ fontWeight: 'light', fontSize: '12px', marginLeft: '16px' }}>
          {choice.type}
        </span>
      </span>
    ) : (
      <span />
    );
  };
  return (
    <CreateController {...props}>
      {controllerProps => (
        <Create
          title={resourceTitle}
          actions={<PostShowActions resourceLabel={resourceLabel} />}
          {...props}
          {...controllerProps}
        >
          <SimpleForm redirect={redirect}>
            <ReferenceInput
              label="View"
              source="bobjectView"
              linkType="show"
              reference="bobjectViews"
            >
              <SelectInput source="name" />
            </ReferenceInput>
            <SelectInput
              source="bobjectField"
              choices={fieldChoices}
              optionText={<OptionRenderer />}
              optionValue="id"
              translateChoice={false}
            />
            <NumberInput source="ordering" />
          </SimpleForm>
        </Create>
      )}
    </CreateController>
  );
};

const edit = props => {
  const [fieldChoices, setFieldChoices] = useState([]);
  let fieldTypes = {};
  const fieldTypesIds = [];
  let bobjectType = '';

  useEffect(() => {
    getOne('bobjectViewFilters', props.id).then(record => {
      getOne('bobjectViews', record.bobjectView).then(view => {
        if (view.type === 'SAL' || view.type === 'MQL' || view.type === 'LEAD_WITHOUT_QC') {
          view.type = 'lead';
        }
        bobjectType = view.type.toLowerCase().replace(/^\w/, chr => {
          return chr.toUpperCase();
        });
        getList('fieldTypes', {}, { perPage: 500 }, {}).then(r => {
          const types = {};
          r.data
            .filter(
              f =>
                f.enumName === 'PICKLIST' ||
                f.enumName === 'GLOBAL_PICKLIST' ||
                f.enumName === 'TEXT' ||
                f.enumName === 'DATE' ||
                f.enumName === 'DATETIME',
            )
            .forEach(e => {
              types[e.id] = e.enumName;
              fieldTypesIds.push(e.id);
            });
          fieldTypes = types;

          getList('bobjectTypes', {}, { perPage: 500 }, { name: bobjectType }).then(answ => {
            getList(
              'bobjectFields',
              {},
              { perPage: 500 },
              { bobjectType: answ.data[0].id, enabled: true },
            ).then(response => {
              const choices = response.data
                .filter(e => fieldTypesIds.includes(e.fieldType))
                .map(e => {
                  return { id: e.id, name: e.name, type: fieldTypes[e.fieldType] };
                });
              setFieldChoices(choices);
            });
          });
        });
      });
    });
  }, []);

  const OptionRenderer = props => {
    const choice = props.record;
    return choice !== undefined ? (
      <span>
        <span style={{ fontWeight: '500' }}>{choice.name}</span>
        <span style={{ fontWeight: 'light', fontSize: '12px', marginLeft: '16px' }}>
          {choice.type}
        </span>
      </span>
    ) : (
      <span />
    );
  };
  return (
    <EditController {...props}>
      {controllerProps => (
        <Edit
          title={resourceTitle}
          actions={<PostShowActions resourceLabel={resourceLabel} />}
          {...props}
          {...controllerProps}
        >
          <SimpleForm redirect={redirect2}>
            <ReferenceInput
              label="View"
              source="bobjectView"
              linkType="show"
              reference="bobjectViews"
            >
              <SelectInput source="name" disabled />
            </ReferenceInput>
            <SelectInput
              source="bobjectField"
              choices={fieldChoices}
              optionText={<OptionRenderer />}
              optionValue="id"
              translateChoice={false}
              disabled
            />
            <NumberInput source="ordering" />
          </SimpleForm>
        </Edit>
      )}
    </EditController>
  );
};

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Customisation',
      hiddenInMenu: true,
    }}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
