import React from 'react';
import {
  Edit,
  NumberField,
  Show,
  Resource,
  NumberInput,
  TabbedForm,
  FormTab,
  Tab,
  SelectInput,
  Filter,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Code';
import CodeTextInput from '../../components/CodeTextInput';
import CodeTextField from '../../components/CodeTextField';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'integrationTriggers';
const resourceLabel = 'Integration Trigger';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <SelectInput
      label="Integration Type"
      source="integrationType"
      choices={[{ íd: 'SALESFORCE', name: 'Salesforce' }, { id: 'HUBSPOT', name: 'Hubspot' }]}
    />
    <TextInput label="Name" source="name" />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} {...props} empty={<EmptyPage />} filters={<ListFilters />}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <TextField source="integrationType" />
      <NumberField source="version" />
      <NumberField source="ordering" />
      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="integrationType" />
        <NumberField source="version" />
        <NumberField source="ordering" />
        <TextField source="creationDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
      <Tab label="Code">
        <CodeTextField source="content" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedForm redirect="show">
      <FormTab label="summary">
        <TextInput source="name" required />
        <SelectInput
          label="Integration Type"
          source="integrationType"
          choices={[{ íd: 'SALESFORCE', name: 'Salesforce' }, { id: 'HUBSPOT', name: 'Hubspot' }]}
        />
        <NumberInput source="version" />
        <NumberInput source="ordering" />
      </FormTab>
      <FormTab label="code">
        <CodeTextInput source="content" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Triggers',
      hiddenInMenu: true,
      isOnlyVisibleFor: ['GLOBAL_DEV', 'GLOBAL_ADMIN'],
    }}
    icon={Icon}
    list={list}
    edit={edit}
    show={show}
  />
);

export default resource;
