import React from 'react';
import { withStyles } from '@material-ui/core';
import { AppBar } from 'react-admin';
import UserMenu from './UserMenu';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';
import { env } from '../misc/api';

const localColor = '#ff8433';
const stagingColor = '#AA19FF';
const productionColor = '#1991ff';

let actualColor = localColor;
if (env === Commons.Environment.STAGING) {
  actualColor = stagingColor;
}
if (env === Commons.Environment.PRODUCTION) {
  actualColor = productionColor;
}

const style = {
  appBar: {
    backgroundColor: actualColor,
  },
};

const CustomAppBar = props => (
  <AppBar {...props} className={props.classes.appBar} userMenu={<UserMenu />} />
);

export default withStyles(style)(CustomAppBar);
