import React from 'react';
import {
  SimpleShowLayout,
  Create,
  Edit,
  ReferenceField,
  Show,
  Resource,
  SimpleForm,
  SelectInput,
  Filter,
  ReferenceInput,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Code';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'nylasUserAccountAliases';
const resourceLabel = 'Nylas alias';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const ListFilters = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Object Type"
      source="bobjectType"
      reference="bobjectTypes"
      allowEmpty={false}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Event" source="event" />
  </Filter>
);
const list = props => (
  <List title={resourceTitle} {...props} empty={<EmptyPage />} filters={<ListFilters />}>
    <CustomizableDatagrid rowClick="show">
      <ReferenceField
        label="Nylas account"
        source="nylasUserAccount"
        link={false}
        reference="nylasUserAccounts"
      >
        <TextField source="emailAddress" />
      </ReferenceField>
      <TextField source="emailAlias" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <ReferenceField
        label="Nylas account"
        source="nylasUserAccount"
        link={false}
        reference="nylasUserAccounts"
      >
        <TextField source="emailAddress" />
      </ReferenceField>
      <TextField source="emailAlias" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        label="Nylas Account"
        source="nylasUserAccount"
        reference="nylasUserAccounts"
        allowEmpty={false}
      >
        <SelectInput optionText="emailAddress" />
      </ReferenceInput>
      <TextInput source="emailAlias" required />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect="show">
      <ReferenceInput
        label="Nylas Account"
        source="nylasUserAccount"
        reference="nylasUserAccounts"
        allowEmpty={false}
      >
        <SelectInput optionText="emailAddress" />
      </ReferenceInput>
      <TextInput source="emailAlias" required />
    </SimpleForm>
  </Edit>
);
const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Customisation',
      isOnlyVisibleFor: ['GLOBAL_ADMIN'],
    }}
    icon={Icon}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
