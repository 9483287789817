import React from 'react';
import { Datagrid, Resource, Show, SimpleShowLayout, TextField, List } from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'userPermissions';
const resourceLabel = 'User Permission';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List empty={<EmptyPage />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
    </Datagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="creationDatetime" />
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);

const resource = (
  <Resource
    name={resourceName}
    list={list}
    show={show}
    options={{ label: 'resourceName', hiddenInMenu: true }}
  />
);

export default resource;
