import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  Resource,
  Show,
  SimpleShowLayout,
  SelectInput,
  FormDataConsumer,
  Create,
  SimpleForm,
  ReferenceInput,
  TextField,
  ReferenceField,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import { Empty } from '../../components/EmptyPage';
import { getList, getOne } from '../../providers/DataProvider';

const resourceName = 'bobjectConditionalFields';
const resourceLabel = 'Conditional Field';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <Datagrid rowClick="show">
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </Datagrid>
  </List>
);

const create = props => {
  const [bobjectTypes, setBobjectTypes] = useState({});

  useEffect(() => {
    getList('bobjectTypes', {}, { page: 0, perPage: 25 }).then(res => {
      const types = {};
      res.data.forEach(type => {
        types[type.id] = type.name;
      });
      setBobjectTypes(types);
    });
  }, []);
  const optionRenderer = choice => `${bobjectTypes[choice.bobjectType]}: ${choice.name}`;

  return (
    <Create
      title={resourceTitle}
      actions={<PostShowActions resourceLabel={resourceLabel} />}
      {...props}
    >
      <SimpleForm redirect="show">
        <ReferenceField
          label="Target field"
          source="bobjectField"
          link="show"
          reference="bobjectFields"
        >
          <TextField source="name" />
        </ReferenceField>
        <FormDataConsumer>
          {() => (
            <ReferenceInput
              label="Required field"
              source="requiredBobjectField"
              reference="bobjectFields"
              allowEmpty={false}
              filter={{
                typeField: true,
              }}
            >
              <SelectInput optionText={optionRenderer} />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.requiredBobjectField === undefined) {
              return null;
            }
            const filter = {};
            const [requiredBobjectGlobalPicklist, setRequiredBobjectGlobalPicklist] = useState(
              undefined,
            );
            useEffect(() => {
              if (formData.requiredBobjectField) {
                getOne('bobjectFields', formData.requiredBobjectField).then(res => {
                  setRequiredBobjectGlobalPicklist(res.bobjectGlobalPicklist);
                });
              }
            });
            if (requiredBobjectGlobalPicklist) {
              filter['bobjectGlobalPicklist.id'] = requiredBobjectGlobalPicklist;
            } else {
              filter['bobjectField.id'] = formData.requiredBobjectField;
            }
            return (
              <ReferenceInput
                label="Required value"
                source="requiredType"
                reference="bobjectPicklistFieldValues"
                allowEmpty={false}
                filter={filter}
              >
                <SelectInput optionText="value" />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const show = props => (
  <Show title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField
        label="Target field"
        source="bobjectField"
        link="show"
        reference="bobjectFields"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Required type"
        source="requiredType"
        link="show"
        reference="bobjectPicklistFieldValues"
      >
        <TextField source="valueWithField" />
      </ReferenceField>

      <TextField source="defaultValue" />
      <TextField source="creationDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const resource = (
  <Resource
    name={resourceName}
    create={create}
    list={list}
    show={show}
    options={{ label: resourceLabel, hiddenInMenu: true }}
  />
);

export default resource;
