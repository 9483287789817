import React, { useEffect, useState } from 'react';
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  FormDataConsumer,
  Resource,
  RichTextField,
  BooleanField,
  BooleanInput,
  SelectInput,
  ShowController,
  ShowView,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  SaveButton,
  Toolbar,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import RichTextInput from 'ra-input-rich-text';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import PicklistValueTitle from '../../components/PicklistValueTitle';
import { getOne } from '../../providers/DataProvider';
import { Empty } from '../../components/EmptyPage';
import { ColorField, ColorInput } from '../../components/colorInput';

const resourceName = 'bobjectPicklistFieldValues';
const resourceLabel = 'Custom Field Value';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const picklistValueTitle = <PicklistValueTitle resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Field" source="bobjectField.name" />
  </Filter>
);
const redirectTo = type => (basePath, id, data) => `/${type}/${data.bobjectField}/show/values`;
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} {...props} empty={<EmptyPage />} filters={<ListFilters />}>
    <Datagrid rowClick="show">
      <ReferenceField label="Field" source="bobjectField" link="show" reference="bobjectFields">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="value" />
      <NumberField source="score" />
    </Datagrid>
  </List>
);

const show = props => {
  const [typeField, setTypeField] = useState('bobjectFields');
  useEffect(() => {
    getOne('bobjectPicklistFieldValues', props.id)
      .then(e => {
        return getOne('bobjectFields', e.bobjectField);
      })
      .then(e => {
        if (e.logicRole) {
          setTypeField('systemFields');
        } else {
          setTypeField('customFields');
        }
      });
  }, []);

  const hideDelete = () => {
    return (
      props.permissions !== null &&
      props.permissions !== undefined &&
      !props.permissions.isGlobalAdmin() &&
      typeField === 'systemFields'
    );
  };

  return (
    <ShowController {...props}>
      {controllerProps => (
        <ShowView
          {...props}
          {...controllerProps}
          title={picklistValueTitle}
          actions={
            <PostShowActions
              resourceLabel={resourceLabel}
              replaceValues
              hideDelete={hideDelete()}
            />
          }
        >
          <SimpleShowLayout>
            {controllerProps.record && controllerProps.record.templateBobjectField && (
              <ReferenceField
                label="Field"
                allowEmpty
                source="bobjectField"
                link="show"
                reference="bobjectFields"
              >
                <TextField source="name" />
              </ReferenceField>
            )}
            {controllerProps.record && controllerProps.record.templateBobjectGlobalPicklist && (
              <ReferenceField
                label="Global Picklist"
                allowEmpty
                source="bobjectGlobalPicklist"
                link="show"
                reference="bobjectGlobalPicklists"
              >
                <TextField source="name" />
              </ReferenceField>
            )}
            <TextField source="value" />
            <TextField source="logicRole" />
            {controllerProps.record && controllerProps.record.parentBobjectPicklistFieldValue && (
              <ReferenceField
                label="Controlling Value"
                source="parentBobjectPicklistFieldValue"
                link="show"
                allowEmpty
                reference="bobjectPicklistFieldValues"
              >
                <TextField source="valueWithField" />
              </ReferenceField>
            )}
            <BooleanField source="enabled" />
            <RichTextField source="description" />
            <NumberField source="score" />
            <NumberField source="ordering" />
            <ColorField source="textColor" />
            <ColorField source="backgroundColor" />
            <ColorField source="outlineColor" />
            <TextField source="creationDatetime" />
            {props.permissions != null && props.permissions.isGlobalAdmin() && (
              <TextField source="id" />
            )}
          </SimpleShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

const create = props => {
  const [typeField, setTypeField] = useState('bobjectFields');
  useEffect(() => {
    if (props.location.state && props.location.state.record) {
      const id = props.location.state.record.bobjectField;
      getOne('bobjectFields', id).then(e => {
        if (e.logicRole) {
          setTypeField('systemFields');
        } else {
          setTypeField('customFields');
        }
      });
    }
  }, [props.location.search]);
  return (
    <Create title={picklistValueTitle} {...props}>
      <SimpleForm redirect={redirectTo(typeField)}>
        <ReferenceInput label="Field" source="bobjectField" reference="bobjectFields" allowEmpty>
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Global Picklist"
          source="bobjectGlobalPicklist"
          reference="bobjectGlobalPicklists"
          allowEmpty
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput source="value" required />

        <FormDataConsumer>
          {({ formData }) =>
            formData.parentCompanyField && (
              <ReferenceInput
                label="Controlling value"
                filter={{ 'bobjectField.id': formData.parentBobjectField }}
                sort={{ field: 'value', order: 'ASC' }}
                source="parentBobjectPicklistFieldValue"
                reference="bobjectPicklistFieldValues"
                perPage={300}
                allowEmpty
              >
                <SelectInput optionText="valueWithField" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <BooleanInput source="enabled" />
        <RichTextInput source="description" toolbar={toolbarConfig} />
        <NumberInput source="score" required defaultValue={0} step={1} />
        <NumberInput source="ordering" required defaultValue={0} step={1} />
      </SimpleForm>
    </Create>
  );
};

const CustomToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const edit = props => {
  const [record, setRecord] = useState(undefined);
  const [typeField, setTypeField] = useState('bobjectFields');
  useEffect(() => {
    getOne('bobjectPicklistFieldValues', props.id).then(e => setRecord(e));
  }, []);

  useEffect(() => {
    if (record) {
      getOne('bobjectFields', record.bobjectField).then(e => {
        if (e.logicRole) {
          setTypeField('systemFields');
        } else {
          setTypeField('customFields');
        }
      });
    }
  }, [record]);

  return (
    <Edit
      title={picklistValueTitle}
      actions={<PostShowActions resourceLabel={resourceLabel} />}
      {...props}
    >
      <SimpleForm
        redirect={redirectTo(typeField)}
        toolbar={typeField !== 'bobjectFields' && <CustomToolbar />}
      >
        <ReferenceField
          label="Field"
          allowEmpty
          source="bobjectField"
          link="show"
          reference="bobjectFields"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Global Picklist"
          source="bobjectGlobalPicklist"
          link="show"
          reference="bobjectGlobalPicklists"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextInput source="value" required />
        <TextInput source="logicRole" />
        <FormDataConsumer>
          {({ formData }) =>
            formData.parentBobjectField && (
              <ReferenceInput
                label="Controlling value"
                filter={{ 'bobjectField.id': formData.parentBobjectField }}
                sort={{ field: 'value', order: 'ASC' }}
                source="parentBobjectPicklistFieldValue"
                reference="bobjectPicklistFieldValues"
                perPage={300}
                allowEmpty
              >
                <SelectInput optionText="valueWithField" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <BooleanInput source="enabled" />
        <ColorInput source="textColor" />
        <ColorInput source="backgroundColor" />
        <ColorInput source="outlineColor" />
        <RichTextInput source="description" toolbar={toolbarConfig} />
        <NumberInput source="score" required defaultValue={0} step={1} />
        <NumberInput source="ordering" required defaultValue={0} step={1} />
      </SimpleForm>
    </Edit>
  );
};

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Customisation',
      hiddenInMenu: true,
    }}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
