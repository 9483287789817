import React, { useEffect, useState } from 'react';
import { getResources, MenuItemLink, Responsive } from 'react-admin';
import { connect } from 'react-redux';
import { withStyles, List, Collapse } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import DefaultIcon from '@material-ui/icons/ViewList';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SessionManagerFactory from '../misc/session';
import { getList } from '../providers/DataProvider';
import SalesforceIcon from '../misc/icons/SalesforceIcon';
import HubspotIcon from '../misc/icons/HubspotIcon';

const SessionManager = SessionManagerFactory();
const styles = {
  root: {}, // Style applied to the MenuItem from material-ui
  active: { fontWeight: 'bold' }, // Style applied when the menu item is the active one
  icon: {}, // Style applied to the icon
  companyName: {
    fontWeight: 600,
    fontSize: '20px',
    color: 'black',
  },
};

const groupBy = (xs, group) => {
  return xs.reduce((rv, x) => {
    (rv[group(x)] = rv[group(x)] || []).push(x);
    return rv;
  }, {});
};

const menuGroups = [
  'Business Assets',
  'Customisation',
  'Sales Team',
  'Integrations',
  'Salesforce',
  'Hubspot',
  'Triggers',
  'Others',
  'Administrative',
];

const shouldBeVisibleInMenu = resource => {
  return (
    resource.hasList &&
    !resource.options.hiddenInMenu &&
    (resource.options.isOnlyVisibleFor === undefined ||
      SessionManager.getRoleManager().hasAnyRole(resource.options.isOnlyVisibleFor))
  );
};

class NestedList extends React.Component {
  state = { open: false };

  nestedStyle = {
    paddingLeft: '4px',
    fontStyle: 'italic',
  };

  handleClick = open => () => {
    this.setState({ open });
  };

  render() {
    const { title } = this.props;

    return (
      <div style={this.props.nested ? this.nestedStyle : undefined}>
        <List>
          <ListItem button onClick={this.handleClick(!this.state.open)}>
            {this.props.icon && <ListItemIcon>{this.props.icon}</ListItemIcon>}
            <ListItemText primary={title} />
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              style={this.props.nested ? this.nestedStyle : undefined}
            >
              {this.props.children}
            </List>
          </Collapse>
        </List>
      </div>
    );
  }
}
const CustomMenu = ({ resources, onMenuClick, logout, dense, classes }) => {
  const [canViewIntegrationsHubspot, setCanViewIntegrationsHubspot] = useState(false);
  const [canViewIntegrationsSalesforce, setCanViewIntegrationsSalesforce] = useState(false);
  const account = SessionManager.getAccount();
  if (account) {
    useEffect(() => {
      getList('integrationSalesforces', {}, { perPage: 500 }).then(res => {
        if (res.data.length >= 1) {
          setCanViewIntegrationsSalesforce(true);
        }
      });
      getList('integrationHubspots', {}, { perPage: 500 }).then(res => {
        if (res.data.length >= 1) {
          setCanViewIntegrationsHubspot(true);
        }
      });
    }, []);
    resources = resources.filter(shouldBeVisibleInMenu);
    const groupResources = groupBy(resources, resource => resource.options.menuGroup || 'Others');
    const menuItems = menuGroups
      .map(menuGroup => {
        if (menuGroup === 'Administrative') {
          return [
            <Divider key="divider" />,
            <NestedList title={menuGroup}>
              {groupResources[menuGroup] &&
                groupResources[menuGroup].map(resource => (
                  <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={`${resource.options.label}s`}
                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                    onClick={onMenuClick}
                    dense={dense}
                  />
                ))}
            </NestedList>,
          ];
        }
        if (
          menuGroup === 'Integrations' &&
          (canViewIntegrationsHubspot || canViewIntegrationsSalesforce) &&
          SessionManager.getRoleManager().isAccountAdmin()
        ) {
          return (
            <NestedList title={menuGroup}>
              {groupResources[menuGroup] &&
                groupResources[menuGroup].map(resource => (
                  <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={`${resource.options.label}s`}
                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                    onClick={onMenuClick}
                    dense={dense}
                  />
                ))}
              {canViewIntegrationsSalesforce && groupResources.Salesforce && (
                <NestedList title={'Salesforce'} nested icon={<SalesforceIcon />}>
                  {groupResources.Salesforce.map(resource => (
                    <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={`${resource.options.label}s`}
                      leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                      onClick={onMenuClick}
                      dense={dense}
                    />
                  ))}
                </NestedList>
              )}
              {canViewIntegrationsHubspot && groupResources.Hubspot && (
                <NestedList title={'Hubspot'} nested icon={<HubspotIcon />}>
                  {groupResources.Hubspot.map(resource => (
                    <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={`${resource.options.label}s`}
                      leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                      onClick={onMenuClick}
                      dense={dense}
                    />
                  ))}
                </NestedList>
              )}
            </NestedList>
          );
        }
        if (!['Integrations', 'Salesforce', 'Hubspot'].includes(menuGroup)) {
          return (
            <NestedList title={menuGroup}>
              {groupResources[menuGroup] &&
                groupResources[menuGroup].map(resource => (
                  <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={`${resource.options.label}s`}
                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                    onClick={onMenuClick}
                    dense={dense}
                  />
                ))}
            </NestedList>
          );
        }
        return null;
      })
      .flat();
    return (
      <div>
        <MenuItemLink
          primaryText={<span className={classes.companyName}>{account.name}</span>}
          to={`/accounts/${account.id}/show`}
        />
        {menuItems}
        <Responsive
          small={logout}
          medium={null} // Pass null to render nothing on larger devices
        />
      </div>
    );
  }

  return <div />;
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(CustomMenu)));
