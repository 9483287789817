import React from 'react';
import {
  Create,
  BooleanField,
  Edit,
  NumberField,
  ShowController,
  ShowView,
  Resource,
  SimpleForm,
  Tab,
  Link,
  Filter,
  ReferenceManyField,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/List';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { CustomDownloadButton } from '../../components/buttons';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'bobjectGlobalPicklists';
const resourceLabel = 'Global Picklist';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const CreateValueButton = ({ record }) => {
  const params = {
    bobjectGlobalPicklist: record.id,
  };
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectPicklistFieldValues/create',
        state: { record: params },
      }}
    >
      <AddIcon /> Add Value
    </Button>
  );
};
const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput source="logicRole" />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List title={resourceTitle} {...props} empty={<EmptyPage />} filters={<ListFilters />}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <TextField source="logicRole" />

      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <ShowController {...props}>
    {controllerProps => {
      return (
        <ShowView
          {...props}
          {...controllerProps}
          title={resourceTitle}
          actions={<PostShowActions resourceLabel={resourceLabel} />}
        >
          <TabbedShowLayout>
            <Tab label="summary">
              <TextField source="name" />
              <TextField source="logicRole" />
              <TextField source="creationDatetime" />
              {props.permissions != null && props.permissions.isGlobalAdmin() && (
                <TextField source="id" />
              )}
            </Tab>
            <Tab label="values" path="values">
              <CreateValueButton />
              {controllerProps.record && (
                <CustomDownloadButton
                  maxResults={500}
                  filter={{ 'bobjectGlobalPicklist.id': controllerProps.record.id }}
                  overrideResource="bobjectPicklistFieldValues"
                  sort={{ field: 'ordering', order: 'DESC' }}
                />
              )}
              <ReferenceManyField
                perPage={500}
                reference="bobjectPicklistFieldValues"
                target="bobjectGlobalPicklist.id"
                sort={{ field: 'ordering', order: 'DESC' }}
                addLabel={false}
              >
                <CustomizableDatagrid rowClick="show">
                  <TextField source="value" />
                  <BooleanField source="enabled" />
                  <NumberField source="score" />
                  <NumberField source="ordering" />
                  <TextField source="logicRole" />
                  <TextField source="textColor" />
                  <TextField source="backgroundColor" />
                  <TextField source="outlineColor" />
                </CustomizableDatagrid>
              </ReferenceManyField>
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      );
    }}
  </ShowController>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect="show">
      <TextInput source="name" required />
    </SimpleForm>
  </Edit>
);

const resource = permissions => (
  <Resource
    name={resourceName}
    options={
      permissions.isGlobalAdmin()
        ? {
            label: resourceLabel,
            menuGroup: 'Customisation',
          }
        : undefined
    }
    icon={Icon}
    list={permissions.isGlobalAdmin() ? list : undefined}
    create={permissions.isGlobalAdmin() ? create : undefined}
    edit={permissions.isGlobalAdmin() ? edit : undefined}
    show={permissions.isGlobalAdmin() ? show : undefined}
  />
);

export default resource;
