import debounce from 'lodash/debounce';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField } from 'ra-core';
import FormControl from '@material-ui/core/FormControl';
import AceEditor from 'react-ace';

import 'brace/mode/javascript';
import 'brace/theme/github';

class CodeTextInputComponent extends Component {
  static propTypes = {
    blockingScroll: PropTypes.bool,
    input: PropTypes.object,
    language: PropTypes.string,
    theme: PropTypes.string,
  };

  static defaultProps = {
    blockingScroll: true,
    language: 'javascript',
    theme: 'github',
  };

  componentDidMount() {}

  componentWillUnmount() {}

  onTextChange = newValue => {
    this.props.input.onChange(newValue);
  };

  render() {
    const { input } = this.props;
    return (
      <FormControl fullWidth={this.props.fullWidth} className="ra-code-text-input">
        <AceEditor
          mode={this.props.language}
          theme={this.props.theme}
          value={input.value}
          width={900}
          onChange={debounce(this.onTextChange, 500)}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: this.props.blockingScroll }}
        />
      </FormControl>
    );
  }
}

const CodeTextInputWithField = addField(CodeTextInputComponent);

CodeTextInputWithField.defaultProps = {
  blockingScroll: true,
  language: 'javascript',
  theme: 'github',
};
export default CodeTextInputWithField;
