import React, { Component } from 'react';
import { connect } from 'react-redux';
import { refreshView, showNotification } from 'react-admin';
import { webApi } from '../../misc/api';
import SessionManagerFactory from '../../misc/session';
import { ButtonWithConfirmation } from './ButtonWithConfirmation';

const SessionManager = SessionManagerFactory();
class CloneDashboardsButton extends Component {
  handleClick = record => {
    const url = `${webApi.host.url}/service/spreadsheet/${
      record.id
    }/dataModel?_jwt=${SessionManager.getToken()}`;
    window.open(url);
  };

  render() {
    return (
      <ButtonWithConfirmation
        buttonText="Download Data Model"
        dialogBody="You are about to download the data model as a xlsx file."
        dialogButton="Download"
        dialogTitle="Download Data Model"
        onAction={this.handleClick}
        {...this.props}
      />
    );
  }
}

CloneDashboardsButton.propTypes = {};

export default connect(
  undefined,
  {
    refreshView,
    showNotification,
  },
)(CloneDashboardsButton);
