import React from 'react';
import {
  Edit,
  Resource,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceField,
  Create,
  BooleanField,
  BooleanInput,
  ShowController,
  ShowView,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Build';

const resourceName = 'integrationHubspots';
const resourceLabel = 'Hubspot Integration';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const redirect = (basePath, id, data) => `/accounts/${data.account}/show/integration_hubspot`;

const show = props => (
  <ShowController {...props}>
    {controllerProps => (
      <ShowView
        {...props}
        {...controllerProps}
        title={resourceTitle}
        actions={<PostShowActions resourceLabel={resourceLabel} />}
        {...props}
      >
        <SimpleShowLayout>
          <TextField source="accessToken" />
          <TextField source="refreshToken" />
          <TextField source="legacyToken" />
          <TextField source="portalId" />
          <ReferenceField source="account" reference="accounts" link="show">
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="active" />
          <TextField source="lastPollingTimestamp" />
          <TextField source="lastPollingContacts" />
          <TextField source="lastPollingForms" />
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

const create = props => (
  <Create title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="accessToken" />
      <TextInput source="refreshToken" />
      <TextInput source="legacyToken" />
      <TextInput source="portalId" />
      <BooleanInput source="active" default />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="accessToken" />
      <TextInput source="refreshToken" />
      <TextInput source="legacyToken" />
      <TextInput source="portalId" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, hiddenInMenu: true }}
    icon={Icon}
    list={undefined}
    edit={edit}
    show={show}
    create={create}
  />
);

export default resource;
