import React from 'react';
import { TopToolbar, CreateButton, EditButton, ShowButton, ExportButton } from 'react-admin';
import Title from './Title';
import {
  SwitchAccountButton,
  SwitchUserButton,
  DeleteWithConfirmation,
  SignAsButton,
  DeleteWithConfirmationAndReplacement,
  CloneViewButton,
  EditRedirect,
} from './buttons';

const titleStyle = {
  margin: 0,
  marginRight: 'auto',
  fontSize: '29px',
};

const PostShowActions = ({
  children,
  resourceLabel,
  basePath,
  data,
  resource,
  hasEdit,
  hasDelete,
  hasCreate,
  replaceValues,
  hasShow,
  hideDelete,
  hasExport,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  permissions,
  filterValues,
  showFilter,
  total,
  editRedirect,
}) => {
  return (
    <TopToolbar>
      {resourceLabel != null && (
        <Title style={titleStyle} resourceLabel={resourceLabel} record={data} />
      )}
      {!hasShow && resource === 'accounts' && <SwitchAccountButton record={data} />}
      {!hasShow && resource === 'users' && <SwitchUserButton record={data} />}
      {!hasShow && resource === 'users' && <SignAsButton record={data} />}
      {!hasShow && resource === 'bobjectViews' && permissions && permissions.isGlobalAdmin() && (
        <CloneViewButton record={data} />
      )}
      {hasEdit && !hasExport && !editRedirect && <EditButton basePath={basePath} record={data} />}
      {hasShow && !hasExport && <ShowButton basePath={basePath} record={data} />}
      {(hasDelete || hasEdit) && !replaceValues && !hideDelete && !hasExport && (
        <DeleteWithConfirmation basePath={basePath} record={data} resource={resource} />
      )}
      {replaceValues && !hideDelete && (
        <DeleteWithConfirmationAndReplacement
          basePath={basePath}
          record={data}
          resource={resource}
        />
      )}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasExport && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
        />
      )}
      {hasCreate && <CreateButton basePath={basePath} resource={resource} />}
      {editRedirect && hasEdit && <EditRedirect redirect={editRedirect} />}
      {children}
    </TopToolbar>
  );
};

export default PostShowActions;
