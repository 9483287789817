import React from 'react';
import {
  Resource,
  ShowView,
  RichTextField,
  ShowController,
  ReferenceField,
  SingleFieldList,
  SimpleShowLayout,
  NumberField,
  ReferenceArrayField,
  Filter,
  TextField,
  ChipField,
  TextInput,
  BooleanField,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Message';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'messagingTemplates';
const resourceLabel = 'Messaging Template';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} filters={<ListFilters />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="visibility" />
      <BooleanField source="enabled" />
      <ReferenceField label="Author" allowEmpty source="user" linkType={false} reference="users">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="ordering" />

      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <ShowController {...props}>
    {controllerProps => (
      <ShowView
        {...props}
        {...controllerProps}
        title={resourceTitle}
        actions={<PostShowActions resourceLabel={resourceLabel} />}
      >
        <SimpleShowLayout>
          <TextField source="name" required />
          <BooleanField source="enabled" />
          <TextField source="type" />
          <TextField source="visibility" />

          {controllerProps.record && controllerProps.record.type === 'EMAIL' && (
            <React.Fragment>
              <p>Subject</p>
              <RichTextField label="Subject" source="subject" toolbar={toolbarConfig} />
            </React.Fragment>
          )}
          <RichTextField source="content" toolbar={toolbarConfig} />
          <ReferenceField label="Author" source="user" reference="users" allowEmpty={false}>
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="ordering" />

          <ReferenceArrayField source="targetMarkets" reference="targetMarkets">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField source="idealCustomerProfiles" reference="idealCustomerProfiles">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField source="useCases" reference="useCases">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <TextField source="creationDatetime" />
          {props.permissions != null && props.permissions.isGlobalAdmin() && (
            <TextField source="id" />
          )}
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      hiddenInMenu: false,
      menuGroup: 'Business Assets',
    }}
    icon={Icon}
    list={list}
    show={show}
  />
);

export default resource;
