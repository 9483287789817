import React, { useEffect, useState } from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  Link,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Resource,
  RichTextField,
  NumberInput,
  ShowView,
  TabbedForm,
  ShowController,
  FormTab,
  ChipField,
  SingleFieldList,
  Tab,
  SelectInput,
  Filter,
  ReferenceArrayField,
  ReferenceInput,
  TabbedShowLayout,
  TextField,
  TextInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  Create,
  Toolbar,
  SaveButton,
  List,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import RichTextInput from 'ra-input-rich-text';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Build';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { LongPagePagination } from '../../components/LongPagePagination';
import DeleteButtonWithConfirmation from '../../components/buttons/DeleteWithConfirmation';
import { CustomDownloadButton } from '../../components/buttons';
import { getList, getOne } from '../../providers/DataProvider';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'systemFields';
const resourceLabel = 'System Field';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const CreateValueButton = ({ record }) => {
  const params = {
    bobjectField: record.id,
  };
  if (record.parentBobjectField) {
    params.parentBobjectField = record.parentBobjectField;
  }
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectPicklistFieldValues/create',
        state: { record: params },
      }}
    >
      <AddIcon /> Add Value
    </Button>
  );
};
const CreateConditionButton = ({ record }) => {
  const params = {
    bobjectField: record.id,
    bobjectType: record.bobjectType,
  };
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectConditionalFields/create',
        state: { record: params },
      }}
    >
      <AddIcon /> Create condition
    </Button>
  );
};

const CreateValueConditionButton = ({ record }) => {
  const params = {
    bobjectField: record.id,
    bobjectType: record.bobjectType,
    bobjectGlobalPicklist: record.bobjectGlobalPicklist,
  };
  return (
    <Button
      component={Link}
      color="primary"
      to={{
        pathname: '/bobjectConditionalFieldValues/create',
        state: { record: params },
      }}
    >
      <AddIcon /> Create condition
    </Button>
  );
};

const permitedLogicRoles = [
  'COMPANY__INDUSTRY',
  'COMPANY__SIZE',
  'COMPANY__COUNTRY',
  'COMPANY__NURTURING_REASONS',
  'COMPANY__DISCARDED_REASONS',
  'LEAD__BUYING_ROLE',
  'LEAD__MANAGEMENT_LEVEL',
  'LEAD__NURTURING_REASONS',
  'LEAD__DISCARDED_REASONS',
  'ACTIVITY__ACCOUNT_EXECUTIVE',
];

const isPermitedField = logicRole => {
  return permitedLogicRoles.includes(logicRole);
};

const ListFilters = props => {
  const [bobjectTypes, setBobjectTypes] = useState([]);
  useEffect(() => {
    getList('bobjectTypes', {}, { perPage: 500 }, {}).then(r => {
      if (props.permissions != null && props.permissions.isGlobalAdmin()) {
        setBobjectTypes(r.data);
      } else {
        const choices = r.data.filter(e => e.name === 'Company' || e.name === 'Lead' || 'Activity');
        setBobjectTypes(choices);
      }
    });
  }, [props.permissions]);
  return (
    <Filter {...props}>
      <SelectInput source="bobjectType" optionText="name" choices={bobjectTypes} alwaysOn />
      <ReferenceInput label="Field Type" source="fieldType" reference="fieldTypes" alwaysOn>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Group"
        source="bobjectFieldGroup"
        reference="bobjectFieldGroups"
        alwaysOn
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Global Picklist"
        source="bobjectGlobalPicklist"
        reference="bobjectGlobalPicklists"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Name" source="name" alwaysOn />
      <TextInput source="logicRole" />
      <BooleanInput source="enabled" />
      <BooleanInput source="required" />
      <BooleanInput source="duplicateValidation" />
      <BooleanInput source="layoutReadOnly" />
    </Filter>
  );
};
const EmptyPage = Empty(resourceLabel);

const list = props => {
  const showCreateButton = props.permissions != null && props.permissions.isGlobalAdmin();
  return (
    <List
      title={resourceTitle}
      {...props}
      empty={<EmptyPage />}
      filters={<ListFilters permissions={props.permissions} />}
      pagination={<LongPagePagination />}
      actions={<PostShowActions hasExport {...props} hasCreate={showCreateButton} />}
      perPage={25}
    >
      <CustomizableDatagrid rowClick="show">
        <TextField source="name" />
        <ReferenceField label="Object" source="bobjectType" link={false} reference="bobjectTypes">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Field Type" source="fieldType" link={false} reference="fieldTypes">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Group"
          source="bobjectFieldGroup"
          link={false}
          reference="bobjectFieldGroups"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Global Picklist"
          source="bobjectGlobalPicklist"
          link={false}
          reference="bobjectGlobalPicklists"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="upstreamMirror" />
        <BooleanField source="typeField" />
        <BooleanField source="duplicateValidation" />
        <BooleanField source="enabled" />
        <BooleanField source="segmentation" />
        <BooleanField source="prioritisation" />
        <BooleanField source="characterisation" />
        <BooleanField source="required" />
        <BooleanField source="layoutReadOnly" />
        <BooleanField source="inboundField" />
        <BooleanField source="qualifyingQuestion" />
        <NumberField source="ordering" />
        <TextField source="logicRole" />
        <TextField source="defaultValue" />
        <BooleanField source="templateVariable" />
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </CustomizableDatagrid>
    </List>
  );
};

const show = props => {
  const [record, setRecord] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  useEffect(() => {
    getOne('bobjectFields', props.id).then(e => setRecord(e));
  }, []);

  useEffect(() => {
    setCanEdit(
      props.permissions != null &&
        ((props.permissions.isAccountAdmin() && isPermitedField(record.logicRole)) ||
          props.permissions.isGlobalAdmin()),
    );
  }, [record, props.permissions]);

  const editRedirect = () => {
    if (
      props.history &&
      props.permissions != null &&
      ((props.permissions.isAccountAdmin() && isPermitedField(record.logicRole)) ||
        props.permissions.isGlobalAdmin())
    ) {
      return () => props.history.push(`/systemFields/${props.id}/1`);
    }
    return undefined;
  };
  return (
    <ShowController {...props}>
      {controllerProps => {
        const isPicklist =
          controllerProps.record && controllerProps.record.fieldType === '8ad73c4db01b0886';
        const isGlobalPicklist =
          controllerProps.record && controllerProps.record.fieldType === '729e9be738deee98';
        const isText =
          controllerProps.record && controllerProps.record.fieldType === 'd9ab78650c2d0531';
        const isReference =
          controllerProps.record && controllerProps.record.fieldType === '38e941159889a150';
        const isQualifyingQuestion =
          controllerProps.record && controllerProps.record.qualifyingQuestion;
        return (
          <ShowView
            {...props}
            {...controllerProps}
            title={resourceTitle}
            actions={
              <PostShowActions
                resourceLabel={resourceLabel}
                hideDelete={props.permissions == null || !props.permissions.isGlobalAdmin()}
                hasEdit={
                  props.permissions !== null &&
                  props.permissions !== undefined &&
                  ((record &&
                    props.permissions.isAccountAdmin() &&
                    isPermitedField(record.logicRole)) ||
                    props.permissions.isGlobalAdmin())
                }
                editRedirect={editRedirect}
              />
            }
          >
            <TabbedShowLayout>
              <Tab label="summary">
                <ReferenceField
                  label="Object"
                  source="bobjectType"
                  link={false}
                  reference="bobjectTypes"
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="name" />
                <ReferenceField
                  label="FieldType"
                  source="fieldType"
                  link={false}
                  reference="fieldTypes"
                >
                  <TextField source="name" />
                </ReferenceField>
                {isReference && (
                  <ReferenceField
                    label="Referenced Bobject Type"
                    source="referencedBobjectType"
                    link={false}
                    reference="bobjectTypes"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                )}
                {isGlobalPicklist && (
                  <ReferenceField
                    label="Global Picklist"
                    source="bobjectGlobalPicklist"
                    reference="bobjectGlobalPicklists"
                    link={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                )}
                <RichTextField source="description" />
                <BooleanField source="enabled" />
                <BooleanField source="segmentation" />
                <BooleanField source="prioritisation" />
                <BooleanField source="characterisation" />
                <BooleanField source="qualifyingQuestion" />
                <BooleanField source="required" />
                <BooleanField source="typeField" />
                <BooleanField source="duplicateValidation" />
                <BooleanField source="layoutReadOnly" />
                <BooleanField source="upstreamMirror" />
                <TextField source="logicRole" />
                <TextField source="searchType" />
                {isPicklist || isGlobalPicklist ? (
                  <ReferenceField
                    label="Default Picklist Value"
                    source="defaultBobjectPicklistFieldValue"
                    reference="bobjectPicklistFieldValues"
                    allowEmpty
                    link="show"
                  >
                    <TextField source="value" />
                  </ReferenceField>
                ) : (
                  <TextField source="defaultValue" />
                )}
                {isQualifyingQuestion && (
                  <ReferenceArrayField source="targetMarkets" reference="targetMarkets">
                    <SingleFieldList link="show">
                      <ChipField source="name" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                )}
                {isQualifyingQuestion && (
                  <ReferenceArrayField
                    source="idealCustomerProfiles"
                    reference="idealCustomerProfiles"
                  >
                    <SingleFieldList linkType="show">
                      <ChipField source="name" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                )}
                {isQualifyingQuestion && (
                  <ReferenceArrayField source="useCases" reference="useCases">
                    <SingleFieldList linkType="show">
                      <ChipField source="name" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                )}
                <BooleanField source="templateVariable" />
                <BooleanField source="reportingEnabled" />
                <BooleanField source="indexed" />
                <TextField source="creationDatetime" />
                {props.permissions != null && props.permissions.isGlobalAdmin() && (
                  <TextField source="id" />
                )}
              </Tab>
              {isPicklist && (
                <Tab label="values" path="values">
                  <div style={{ display: 'flex' }}>
                    {canEdit && <CreateValueButton record={record} />}
                    {controllerProps.record && (
                      <CustomDownloadButton
                        maxResults={500}
                        filter={{ 'bobjectField.id': controllerProps.record.id }}
                        overrideResource="bobjectPicklistFieldValues"
                        sort={{ field: 'ordering', order: 'DESC' }}
                      />
                    )}
                  </div>
                  <ReferenceManyField
                    perPage={500}
                    reference="bobjectPicklistFieldValues"
                    target="bobjectField.id"
                    sort={{ field: 'ordering', order: 'DESC' }}
                    addLabel={false}
                  >
                    <CustomizableDatagrid rowClick="show">
                      <TextField source="value" />
                      <BooleanField source="enabled" />
                      <NumberField source="score" />
                      <NumberField source="ordering" />
                      <TextField source="logicRole" />
                      <TextField source="textColor" />
                      <TextField source="backgroundColor" />
                      <TextField source="outlineColor" />
                    </CustomizableDatagrid>
                  </ReferenceManyField>
                </Tab>
              )}
              <Tab label="Field Conditions" path="conditions">
                <Typography style={{ marginTop: 16 }}>
                  These conditions limit the visibility of the field in forms. Whenever one of these
                  picklist values are selected in a form, then this field shall be displayed. If
                  there are no conditions, then the field is always displayed.
                </Typography>
                {canEdit && <CreateConditionButton />}
                <ReferenceManyField
                  perPage={500}
                  reference="bobjectConditionalFields"
                  target="bobjectField.id"
                  addLabel={false}
                >
                  <Datagrid rowClick={false}>
                    <ReferenceField
                      label="Required type"
                      source="requiredType"
                      lin={false}
                      reference="bobjectPicklistFieldValues"
                    >
                      <TextField source="valueWithField" />
                    </ReferenceField>
                    <DeleteButtonWithConfirmation redirect={false} />
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
              {(isPicklist || isGlobalPicklist) && (
                <Tab label="Field Value Conditions" path="valueConditions">
                  <Typography>
                    <h1>IS CONDITIONED BY</h1>
                    These conditions limit the visibility of the values of this field in forms.
                  </Typography>
                  {canEdit && <CreateValueConditionButton />}
                  <ReferenceManyField
                    perPage={500}
                    reference="bobjectConditionalFieldValues"
                    target="bobjectField.id"
                    addLabel={false}
                  >
                    <Datagrid rowClick={false}>
                      <ReferenceField
                        label="Field Value"
                        source="bobjectPicklistFieldValue"
                        lin={false}
                        reference="bobjectPicklistFieldValues"
                      >
                        <TextField source="value" />
                      </ReferenceField>
                      <ReferenceField
                        label="Required Field"
                        source="requiredBobjectField"
                        lin={false}
                        reference="bobjectFields"
                      >
                        <TextField source="name" />
                      </ReferenceField>
                      <ReferenceField
                        label="Required Field Value"
                        source="requiredBobjectPicklistFieldValue"
                        lin={false}
                        reference="bobjectPicklistFieldValues"
                      >
                        <TextField source="value" />
                      </ReferenceField>
                      <DeleteButtonWithConfirmation redirect={false} />
                    </Datagrid>
                  </ReferenceManyField>
                  <Typography style={{ marginTop: '32px' }}>
                    <h1>FIELDS CONDITIONED</h1>
                    This list shows all fields that are conditioned by{' '}
                    <b>{controllerProps.record.name}</b>.
                  </Typography>
                  <ReferenceManyField
                    perPage={500}
                    reference="bobjectConditionalFieldValues"
                    target="requiredBobjectField.id"
                    addLabel={false}
                  >
                    <Datagrid rowClick={false}>
                      <ReferenceField
                        label="Field"
                        source="bobjectField"
                        lin={false}
                        reference="bobjectFields"
                      >
                        <TextField source="name" />
                      </ReferenceField>
                      <ReferenceField
                        label="Field Value"
                        source="bobjectPicklistFieldValue"
                        lin={false}
                        reference="bobjectPicklistFieldValues"
                      >
                        <TextField source="value" />
                      </ReferenceField>
                      <ReferenceField
                        label="Required Field"
                        source="requiredBobjectField"
                        lin={false}
                        reference="bobjectFields"
                      >
                        <TextField source="name" />
                      </ReferenceField>
                      <ReferenceField
                        label="Required Field Value"
                        source="requiredBobjectPicklistFieldValue"
                        lin={false}
                        reference="bobjectPicklistFieldValues"
                      >
                        <TextField source="value" />
                      </ReferenceField>
                    </Datagrid>
                  </ReferenceManyField>
                </Tab>
              )}
              <Tab label="Style">
                <NumberField source="ordering" />
                <ReferenceField
                  label="Field Group"
                  source="bobjectFieldGroup"
                  lin={false}
                  reference="bobjectFieldGroups"
                  allowEmpty
                >
                  <TextField source="name" />
                </ReferenceField>
                {isPicklist && <TextField source="layoutFormPicklistType" allowEmpty />}
                <BooleanField source="tableLayoutDefault" />
                <BooleanField source="inboundField" />
                <TextField source="layoutFormColumns" allowEmpty />
                <TextField source="layoutFormWidth" allowEmpty />
                <TextField source="layoutIcon" allowEmpty />
                {isText && <BooleanField source="layoutMultiline" />}
                {isText && <NumberField source="layoutMultilineLines" />}
                {isText && <NumberField source="layoutMultilineMaxLines" />}
                <TextField source="layoutDateFormatType" allowEmpty />
                <TextField source="layoutDateFormatAbsolute" allowEmpty />
                <TextField source="layoutNumberFormat" allowEmpty />
                <TextField source="layoutNumberPrefix" allowEmpty />
                <TextField source="layoutNumberSuffix" allowEmpty />
              </Tab>
            </TabbedShowLayout>
          </ShowView>
        );
      }}
    </ShowController>
  );
};

const createValidate = values => {
  const errors = {};
  if (!values.bobjectType) {
    errors.bobjectType = ['It is required to provide an object type'];
  }
  if (!values.name) {
    errors.name = ['Name can not be empty'];
  }
  if (!values.fieldType) {
    errors.fieldType = ['It is required to provide a field type'];
  }
  return errors;
};

const create = props => {
  const [bobjectTypes, setBobjectTypes] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  useEffect(() => {
    getList('bobjectTypes', {}, { perPage: 500 }, {}).then(r => {
      if (
        props.permissions != null &&
        props.permissions !== undefined &&
        props.permissions.isGlobalAdmin()
      ) {
        setBobjectTypes(r.data);
      } else {
        const choices = r.data.filter(e => e.name === 'Company' || e.name === 'Lead');
        setBobjectTypes(choices);
      }
    });
  }, [props.permissions]);

  useEffect(() => {
    getList('fieldTypes', {}, { perPage: 500 }, {}).then(r => {
      const choices = r.data.filter(
        e =>
          (props.permissions !== null &&
            props.permissions !== undefined &&
            props.permissions.isGlobalAdmin()) ||
          (e.enumName !== 'GLOBAL_PICKLIST' && e.enumName !== 'REFERENCE'),
      );
      setFieldTypes(choices);
    });
  }, [props.permissions]);

  return (
    <Create title={resourceTitle} {...props}>
      <SimpleForm redirect="show" validate={createValidate}>
        <SelectInput
          source="bobjectType"
          label="Object Type"
          optionText="name"
          required
          choices={bobjectTypes}
        />
        <TextInput source="name" required />
        <SelectInput
          source="fieldType"
          label="Field Type"
          optionText="name"
          choices={fieldTypes}
          required
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.fieldType === '729e9be738deee98' && (
              <ReferenceInput
                label="Global Picklist"
                source="bobjectGlobalPicklist"
                reference="bobjectGlobalPicklists"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <RichTextInput source="description" toolbar={toolbarConfig} />
        <TextInput source="logicRole" />
        <BooleanInput source="enabled" />
        <BooleanInput source="segmentation" />
        <BooleanInput source="duplicateValidation" />
        <BooleanInput source="required" />
        <BooleanInput source="templateVariable" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <BooleanInput source="typeField" />
        )}
      </SimpleForm>
    </Create>
  );
};

const CustomToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

const edit = props => {
  const [record, setRecord] = useState({});
  useEffect(() => {
    getOne('bobjectFields', props.id).then(e => setRecord(e));
  }, []);
  return (
    <Edit
      title={resourceTitle}
      actions={<PostShowActions resourceLabel={resourceLabel} />}
      {...props}
    >
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        {props.permissions !== null &&
          props.permissions !== undefined &&
          props.permissions.isGlobalAdmin() && (
            <FormTab label="summary">
              <TextInput source="name" required />
              <FormDataConsumer>
                {({ formData }) =>
                  formData.fieldType === '8ad73c4db01b0886' ||
                  formData.fieldType === '729e9be738deee98' ? (
                    <ReferenceInput
                      label="Default Picklist Value"
                      source="defaultBobjectPicklistFieldValue"
                      reference="bobjectPicklistFieldValues"
                      filter={
                        formData.bobjectGlobalPicklist !== null
                          ? { bobjectGlobalPicklist: formData.bobjectGlobalPicklist }
                          : { bobjectField: formData.id }
                      }
                      allowEmpty
                    >
                      <SelectInput optionText="value" />
                    </ReferenceInput>
                  ) : (
                    <TextInput source="defaultValue" />
                  )
                }
              </FormDataConsumer>
              <RichTextInput source="description" toolbar={toolbarConfig} />
              <BooleanInput source="enabled" />
              <BooleanInput source="segmentation" />
              <BooleanInput source="prioritisation" />
              <BooleanInput source="characterisation" />
              <BooleanInput source="duplicateValidation" />
              <BooleanInput source="templateVariable" />
              {props.permissions != null && props.permissions.isGlobalAdmin() && (
                <BooleanInput source="reportingEnabled" />
              )}
              {props.permissions != null && props.permissions.isGlobalAdmin() && (
                <BooleanInput source="indexed" />
              )}
              <BooleanInput source="required" />
              {props.permissions !== null &&
                props.permissions !== undefined &&
                props.permissions.isGlobalAdmin() && <BooleanInput source="typeField" />}
              <BooleanInput source="layoutReadOnly" />
              <TextInput source="logicRole" />
              <SelectInput
                source="searchType"
                choices={[
                  { id: null, name: '' },
                  { id: 'EXACT_WORD', name: 'Exact Words' },
                  { id: 'FUZZY_WORD', name: 'Fuzzy Words' },
                  { id: 'AUTOCOMPLETE_WORD', name: 'Autocomplete Words' },
                ]}
              />
              <FormDataConsumer>
                {({ formData }) =>
                  formData.fieldType === '38e941159889a150' && (
                    <ReferenceInput
                      label="Referenced Object Type"
                      source="referencedBobjectType"
                      reference="bobjectTypes"
                      allowEmpty={false}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData }) =>
                  formData.fieldType === '729e9be738deee98' && (
                    <ReferenceInput
                      perPage={500}
                      label="Global Picklist"
                      source="bobjectGlobalPicklist"
                      reference="bobjectGlobalPicklists"
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  )
                }
              </FormDataConsumer>
              {record.qualifyingQuestion && (
                <ReferenceArrayInput
                  perPage={100}
                  defaultValues={[]}
                  label="Target Markets"
                  source="targetMarkets"
                  reference="targetMarkets"
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
              )}
              {record.qualifyingQuestion && (
                <ReferenceArrayInput
                  perPage={100}
                  defaultValues={[]}
                  label="Ideal Customer Profiles"
                  source="idealCustomerProfiles"
                  reference="idealCustomerProfiles"
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
              )}
              {record.qualifyingQuestion && (
                <ReferenceArrayInput
                  perPage={100}
                  defaultValues={[]}
                  label="Use Cases"
                  source="useCases"
                  reference="useCases"
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
              )}
            </FormTab>
          )}
        {props.permissions !== null &&
          props.permissions !== undefined &&
          ((record && props.permissions.isAccountAdmin() && isPermitedField(record.logicRole)) ||
            props.permissions.isGlobalAdmin()) && (
            <FormTab label="style">
              <NumberInput source="ordering" defaultValue={0} step={1} />
              <ReferenceInput
                label="Field Group"
                source="bobjectFieldGroup"
                reference="bobjectFieldGroups"
                allowEmpty
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <SelectInput
                source="layoutFormPicklistType"
                choices={[
                  { id: null, name: '' },
                  { id: 'DROPDOWN', name: 'Dropdown' },
                  { id: 'CHIP', name: 'Chip' },
                ]}
              />
              <TextInput source="layoutFormColumns" allowEmpty />
              <TextInput source="layoutFormWidth" allowEmpty />
              <TextInput source="layoutIcon" allowEmpty />
              <TextInput source="layoutNumberPrefix" allowEmpty />
              <TextInput source="layoutNumberSuffix" allowEmpty />
              <TextInput source="layoutNumberFormat" allowEmpty />
              <BooleanInput source="layoutMultiline" />
              <NumberInput source="layoutMultilineLines" />
              <NumberInput source="layoutMultilineMaxLines" />

              <SelectInput
                source="layoutDateFormatType"
                choices={[
                  { id: null, name: '' },
                  { id: 'ABSOLUTE', name: 'Absolute' },
                  { id: 'RELATIVE', name: 'Relative' },
                ]}
              />
              <TextInput source="layoutDateFormatAbsolute" />
            </FormTab>
          )}
      </TabbedForm>
    </Edit>
  );
};

const resource = () => (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Customisation' }}
    icon={Icon}
    list={list}
    edit={edit}
    show={show}
    create={create}
  />
);

export default resource;
