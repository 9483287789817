import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-admin';

const styles = () => ({
  dialogFooter: {
    justifyContent: 'space-between',
  },
});

class ButtonWithConfirmationComponent extends Component {
  state = {
    showDialog: false,
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleAction = event => {
    event.preventDefault();
    this.setState({ showDialog: false });
    this.props.onAction(this.props.record);
  };

  render() {
    const { showDialog } = this.state;
    const { classes = {}, className } = this.props;
    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          className={classnames(className)}
          key="button"
          label={this.props.buttonText}
        />
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Are you sure?"
        >
          <DialogTitle>{this.props.dialogTitle || this.props.buttonText}</DialogTitle>
          <DialogContent>
            <div>{this.props.dialogBody || 'Are you sure?'}</div>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <Button
              onClick={this.handleAction}
              className={classnames(classes.deleteButton, className)}
              key="button"
              label={this.props.dialogButton || this.props.buttonText}
            />
            <Button onClick={this.handleCloseClick} label="Cancel" />
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ButtonWithConfirmationComponent.propTypes = {
  buttonText: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dialogBody: PropTypes.string,
  dialogButton: PropTypes.string,
  dialogTitle: PropTypes.string,
  onAction: PropTypes.func,
  record: PropTypes.object,
};

export const ButtonWithConfirmation = withStyles(styles)(ButtonWithConfirmationComponent);
