import React from 'react';
import { Resource, Show, SimpleShowLayout, TextField, List } from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'fieldTypes';
const resourceLabel = 'Field Type';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);
const list = props => (
  <List title={resourceTitle} empty={<EmptyPage />} {...props}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}

      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show title={resourceTitle} actions={<PostShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="creationDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const resource = (
  <Resource
    name={resourceName}
    list={list}
    show={show}
    options={{ label: resourceLabel, hiddenInMenu: true }}
  />
);

export default resource;
