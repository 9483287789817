import React from 'react';
import {
  SimpleShowLayout,
  ReferenceField,
  Show,
  Resource,
  SelectInput,
  Filter,
  ReferenceInput,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Code';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'nylasUserAccounts';
const resourceLabel = 'Nylas account';
const resourceTitle = <Title resourceLabel={resourceLabel} />;
const EmptyPage = Empty(resourceLabel);

const ListFilters = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Object Type"
      source="bobjectType"
      reference="bobjectTypes"
      allowEmpty={false}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Event" source="event" />
  </Filter>
);
const list = props => (
  <List title={resourceTitle} {...props} empty={<EmptyPage />} filters={<ListFilters />}>
    <CustomizableDatagrid rowClick="show">
      <ReferenceField label="User" source="user" link={false} reference="users">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="emailAddress" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <ReferenceField label="User" source="user" link={false} reference="users">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="emailAddress" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Customisation',
      isOnlyVisibleFor: ['GLOBAL_ADMIN'],
    }}
    icon={Icon}
    list={list}
    create={undefined}
    edit={undefined}
    show={show}
  />
);

export default resource;
