import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification, refreshView } from 'react-admin';
import { webApi } from '../../misc/api';
import { ButtonWithConfirmation } from './ButtonWithConfirmation';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';

class SubhomeRollbackButton extends Component {
  handleClick = record => {
    const { showNotification } = this.props;
    webApi
      .service()
      .request({
        url: `/service/accounts/${record.id}/lastOverdueRollback`,
        method: Commons.HttpMethod.POST,
      })
      .then(() => {
        refreshView();
      });

    showNotification(`Rollback of tasks overdue in account: ${record.name}`);
  };

  render() {
    return (
      <ButtonWithConfirmation
        buttonText="Tasks overdue rollback"
        dialogBody={
          'This action will create a task overdue field for last task overdue for each company '
        }
        onAction={this.handleClick}
        {...this.props}
      />
    );
  }
}

SubhomeRollbackButton.propTypes = {
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default connect(
  undefined,
  {
    showNotification,
  },
)(SubhomeRollbackButton);
