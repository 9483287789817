import React, { Fragment, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-admin';
import { getList, getOne } from '../../providers/DataProvider';
import { webApi } from '../../misc/api';
import { crudDelete } from 'ra-core';
import { connect } from 'react-redux';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  dialogFooter: {
    justifyContent: 'space-between',
  },
  select: {
    display: 'flex',
    margin: '16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const DeleteWithConfirmationAndReplacement = props => {
  const [showDialog, setShowDialog] = useState(false);
  const [choices, setChoices] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [allowDelete, setAllowDelete] = useState(false);
  useEffect(() => {
    if (props.record !== undefined) {
      let filters = { bobjectField: props.record.bobjectField };
      if (props.record.bobjectGlobalPicklist !== null) {
        filters = { bobjectGlobalPicklist: props.record.bobjectGlobalPicklist };
      }
      getList(
        'bobjectPicklistFieldValues',
        { field: 'ordering', order: 'DESC' },
        { perPage: 500 },
        filters,
      ).then(response => {
        const c = response.data
          .filter(e => e.id !== props.record.id)
          .map(element => {
            return { id: element.id, name: element.value };
          });
        setChoices(c);
      });
    }
  }, [props.record]);
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleChange = event => {
    setSelectedValue(event.target.value);
    setAllowDelete(true);
  };

  const handleDelete = event => {
    event.preventDefault();

    const accountId = props.record.account;
    const body = { currentValue: props.record.id, newValue: selectedValue };
    webApi.service().request({
      method: Commons.HttpMethod.POST,
      body,
      url: `/service/picklist/${accountId}/replaceValue`,
    });

    setShowDialog(false);
    const { dispatchCrudDelete, resource, record, basePath } = props;
    let redirect;
    if (record.bobjectField !== undefined && record.bobjectGlobalPicklist === null) {
      getOne('bobjectFields', record.bobjectField).then(e => {
        if (e.logicRole !== null) {
          redirect = `/systemFields/${record.bobjectField}/show/values`;
        } else {
          redirect = `/customFields/${record.bobjectField}/show/values`;
        }
        dispatchCrudDelete(resource, record.id, record, basePath, redirect);
      });
    } else {
      redirect = `/bobjectGlobalPicklists/${record.bobjectGlobalPicklist}/show/values`;
      dispatchCrudDelete(resource, record.id, record, basePath, redirect);
    }
  };

  const { label = 'ra.action.delete', classes = {}, className } = props;
  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label={label}
        className={classnames('ra-delete-button', classes.deleteButton, className)}
        key="button"
      >
        <ActionDelete />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Are you sure?">
        {choices.length > 0 ? (
          <Fragment>
            <DialogTitle>Select a value to replace</DialogTitle>
            <DialogContent>
              <div>
                Select another value from the picklist to replace all appearances in Bobjects of the
                current value you are about to delete.
              </div>
              <div>
                <Select
                  value={selectedValue}
                  onChange={handleChange}
                  className={classnames(classes.select)}
                >
                  {choices !== undefined &&
                    choices.map(element => (
                      <MenuItem key={element.id} value={element.id}>
                        {element.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </DialogContent>
          </Fragment>
        ) : (
          <Fragment>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
              <div>
                There is no other value in the picklist that can be selected to replace all
                occurrences of this value. First create a new value and then press delete again.
              </div>
            </DialogContent>
          </Fragment>
        )}

        <DialogActions className={classes.dialogFooter}>
          <Button
            onClick={handleDelete}
            disabled={!allowDelete}
            label={label}
            className={classnames('ra-delete-button', classes.deleteButton, className)}
            key="button"
          >
            <ActionDelete />
          </Button>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default connect(
  null,
  { dispatchCrudDelete: crudDelete },
)(withStyles(styles)(DeleteWithConfirmationAndReplacement));
