import React, { Fragment, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-admin';
import { TextField } from '@material-ui/core';
import { webApi } from '../../misc/api';
import { Commons } from '@bloobirds-it/bloobirds-platform-js-api-library';

const styles = () => ({
  dialogFooter: {
    justifyContent: 'space-between',
  },
});

const Index = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [status, setStatus] = useState('ready');
  useEffect(() => {
    if (status === 'fired') {
      const data = { cloneToAllAccounts: true };
      if (accountId) {
        data.toAccountId = accountId;
        data.cloneToAllAccounts = false;
      }
      webApi
        .service()
        .request({
          url: `/service/view/bobjectview/${record.id}/clone`,
          method: Commons.HttpMethod.POST,
          body: data,
        })
        .then(() => {
          setStatus('ready');
        });
      setStatus('requested');
      setShowDialog(false);
    }
  }, [status, accountId]);

  return (
    <Fragment>
      <Button onClick={() => setShowDialog(true)} key="button" label="Clone View" />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-label="Are you sure?"
      >
        <DialogTitle>Install Dashboards</DialogTitle>
        <DialogContent>
          <div>
            Add the account id to which you want to clone the view. Leave it empty to clone it to
            all accounts.
          </div>
          <TextField type="text" value={accountId} onChange={t => setAccountId(t.target.value)} />
        </DialogContent>
        <DialogActions>
          {status === 'ready' && (
            <Button onClick={() => setStatus('fired')} key="button" label="Clone" />
          )}
          <Button onClick={() => setShowDialog(false)} label="Cancel" />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(Index);
