import React, { useState, useEffect } from 'react';
import { getOne } from '../providers/DataProvider';

const FieldTitle = ({ isEdit = false, record, style }) => {
  const [bobjectName, setBobjectName] = useState('');
  useEffect(() => {
    getOne('bobjectTypes', record.bobjectType).then(response => {
      setBobjectName(response.name);
    }, []);
  });
  let content = '';

  if (isEdit) {
    content += 'Edit ';
  }
  content += `${bobjectName} > `;

  content += record.name;

  return <p style={style}>{content}</p>;
};

export default FieldTitle;
