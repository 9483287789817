import React from 'react';
import {
  AutocompleteArrayInput,
  ChipField,
  Create,
  Edit,
  ReferenceInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  NumberInput,
  SelectInput,
  NumberField,
  Filter,
  Resource,
  RichTextField,
  Show,
  SimpleForm,
  SingleFieldList,
  SimpleShowLayout,
  TextField,
  TextInput,
  BooleanInput,
  BooleanField,
  List,
} from 'react-admin';
import { ColorField, ColorInput } from '../../components/colorInput';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import RichTextInput from 'ra-input-rich-text';
import Icon from '@material-ui/icons/GpsFixed';
import { toolbarConfig } from '../../misc/richTextToolbarConfig';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'targetMarkets';
const resourceLabel = 'Target Market';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);

const list = ({ permissions, ...props }) => {
  return (
    <List title={resourceTitle} empty={<EmptyPage />} filters={<ListFilters />} {...props}>
      <CustomizableDatagrid rowClick="show">
        <TextField source="name" />
        <TextField source="shortname" />
        <BooleanField source="enabled" />
        <ReferenceArrayField
          label="Segmentation"
          source="bobjectPicklistFieldValues"
          reference="bobjectPicklistFieldValues"
        >
          <SingleFieldList linkType="show">
            <ChipField source="valueWithField" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField source="cadence" lin="show" reference="cadences">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="creationDatetime" />
        <TextField source="updateDatetime" />
        <NumberField source="ordering" />
        {permissions !== null && permissions !== undefined && permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </CustomizableDatagrid>
    </List>
  );
};

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="shortname" />
      <ColorField source="color" />
      <BooleanField source="enabled" />
      <NumberField source="ordering" />
      <RichTextField source="description" />
      <ReferenceField source="cadence" lin="show" reference="cadences">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField
        label="Segmentation"
        source="bobjectPicklistFieldValues"
        reference="bobjectPicklistFieldValues"
      >
        <SingleFieldList linkType="show">
          <ChipField source="valueWithField" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="creationDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </SimpleShowLayout>
  </Show>
);
const validateTmCreation = values => {
  const errors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  if (!values.cadence) {
    errors.cadence = ['The Cadence is required'];
  }
  return errors;
};
const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show" validate={validateTmCreation}>
      <TextInput source="name" required />
      <TextInput source="shortname" required />
      <ColorInput source="color" />
      <BooleanInput source="enabled" default />
      <NumberInput source="ordering" defaultValue={0} step={1} />
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <ReferenceInput source="cadence" reference="cadences" required allowEmpty={false}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput
        perPage={500}
        defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
        label="Segmentation"
        source="bobjectPicklistFieldValues"
        filter={{
          'bobjectField.segmentation': true,
          'bobjectField.bobjectType.name': 'Company',
        }}
        reference="bobjectPicklistFieldValues"
      >
        <AutocompleteArrayInput
          translateChoice={false}
          source="bobjectPicklistFieldValues"
          optionValue="id"
          optionText="valueWithField"
          filter={{
            'bobjectField.segmentation': true,
            'bobjectField.bobjectType.name': 'Company',
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    validate={validateTmCreation}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <TextInput source="shortname" required />
      <BooleanInput source="enabled" />
      <ColorInput source="color" />
      <NumberInput source="ordering" defaultValue={0} step={1} />
      <RichTextInput source="description" toolbar={toolbarConfig} />
      <ReferenceInput source="cadence" reference="cadences" required allowEmpty={false}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput
        perPage={500}
        defaultValue={[]} // https://stackoverflow.com/questions/52989200/autocompletearrayinput-isnt-working-in-2-4-0
        label="Segmentation"
        filter={{
          'bobjectField.segmentation': true,
          'bobjectField.bobjectType.name': 'Company',
        }}
        source="bobjectPicklistFieldValues"
        reference="bobjectPicklistFieldValues"
      >
        <AutocompleteArrayInput
          translateChoice={false}
          source="bobjectPicklistFieldValues"
          resource="bobjectPicklistFieldValues"
          optionValue="id"
          optionText="valueWithField"
          filter={{
            'bobjectField.segmentation': true,
            'bobjectField.bobjectType.name': 'Company',
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

const resource = () => (
  <Resource
    name={resourceName}
    options={{ label: resourceLabel, menuGroup: 'Business Assets' }}
    list={list}
    create={create}
    icon={Icon}
    edit={edit}
    show={show}
  />
);

export default resource;
