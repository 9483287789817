import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Create,
  Edit,
  NumberField,
  ReferenceField,
  Show,
  Resource,
  NumberInput,
  TabbedForm,
  SimpleForm,
  FormTab,
  Tab,
  SelectInput,
  Filter,
  ReferenceInput,
  TabbedShowLayout,
  TextField,
  TextInput,
  List,
} from 'react-admin';
import Title from '../../components/Title';
import PostShowActions from '../../components/PostShowActions';
import Icon from '@material-ui/icons/Code';
import CodeTextInput from '../../components/CodeTextInput';
import CodeTextField from '../../components/CodeTextField';
import CustomizableDatagrid from '../../components/CustomisableDatagrid';
import { Empty } from '../../components/EmptyPage';

const resourceName = 'bobjectTriggers';
const resourceLabel = 'Trigger';
const resourceTitle = <Title resourceLabel={resourceLabel} />;

const ListFilters = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Object Type"
      source="bobjectType"
      reference="bobjectTypes"
      allowEmpty={false}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Name" source="name" />
  </Filter>
);
const EmptyPage = Empty(resourceLabel);

const list = props => (
  <List title={resourceTitle} {...props} empty={<EmptyPage />} filters={<ListFilters />}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField label="Object" source="bobjectType" link={false} reference="bobjectTypes">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="active" />
      <NumberField source="version" />
      <NumberField source="ordering" />
      <BooleanField source="afterEvent" />
      <BooleanField source="beforeEvent" />
      <BooleanField source="onCreate" />
      <BooleanField source="onUpdate" />
      <BooleanField source="onDelete" />

      <TextField source="creationDatetime" />
      <TextField source="updateDatetime" />
      {props.permissions != null && props.permissions.isGlobalAdmin() && <TextField source="id" />}
    </CustomizableDatagrid>
  </List>
);

const show = props => (
  <Show
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <ReferenceField label="Object" source="bobjectType" link={false} reference="bobjectTypes">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="active" />
        <NumberField source="version" />
        <NumberField source="ordering" />
        <BooleanField source="afterEvent" />
        <BooleanField source="beforeEvent" />
        <BooleanField source="onCreate" />
        <BooleanField source="onUpdate" />
        <BooleanField source="onDelete" />
        <TextField source="creationDatetime" />
        {props.permissions != null && props.permissions.isGlobalAdmin() && (
          <TextField source="id" />
        )}
      </Tab>
      <Tab label="Code">
        <CodeTextField source="content" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const create = props => (
  <Create title={resourceTitle} {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" required />
      <ReferenceInput
        label="Object Type"
        source="bobjectType"
        reference="bobjectTypes"
        allowEmpty={false}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
      <NumberInput source="version" />
      <NumberInput source="ordering" />
      <BooleanInput source="afterEvent" />
      <BooleanInput source="beforeEvent" />
      <BooleanInput source="onCreate" />
      <BooleanInput source="onUpdate" />
      <BooleanInput source="onDelete" />
      <CodeTextInput source="content" />
    </SimpleForm>
  </Create>
);

const edit = props => (
  <Edit
    title={resourceTitle}
    actions={<PostShowActions resourceLabel={resourceLabel} />}
    {...props}
  >
    <TabbedForm redirect="show">
      <FormTab label="summary">
        <TextInput source="name" required />
        <ReferenceInput
          label="Object Type"
          source="bobjectType"
          reference="bobjectTypes"
          allowEmpty={false}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="active" />
        <NumberInput source="version" />
        <NumberInput source="ordering" />
        <BooleanInput source="afterEvent" />
        <BooleanInput source="beforeEvent" />
        <BooleanInput source="onCreate" />
        <BooleanInput source="onUpdate" />
        <BooleanInput source="onDelete" />
      </FormTab>
      <FormTab label="code">
        <CodeTextInput source="content" />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const resource = (
  <Resource
    name={resourceName}
    options={{
      label: resourceLabel,
      menuGroup: 'Triggers',
      isOnlyVisibleFor: ['GLOBAL_DEV', 'ACCOUNT_DEV'],
    }}
    icon={Icon}
    list={list}
    create={create}
    edit={edit}
    show={show}
  />
);

export default resource;
